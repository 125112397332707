<app-header></app-header>
<div fxLayout="column" class="blogContainer width100">
    <div fxLayout="row">
        <div class="back-arrow" (click)="backButton()">
            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
        </div>
        <h1 class="blog-header">
            Exploring Automated Hotel: Benefits, Implementation & Risks
        </h1>
    </div>
    <mat-divider class="material-devider"></mat-divider>
    <div fxLayout="row" class="sub-content" fxLayoutAlign="space-evenly">
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">Janhavi Srivastava</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">22 Aug 2024</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <iconify-icon icon="material-symbols-light:share-outline" class="share-icon"></iconify-icon>
            <a class="blog-desc" style="cursor: pointer;" (click)="viewLinkedIn()">LinkedIn</a>
        </div>
    </div>
</div>
<div class="infoContainer width100" fxLayout="column" fxLayoutAlign=" center">
    <div class="Hicsa-prgm-container" *ngIf="!mobile">
        <img src="assets/images/Automated Hotel.jpg" class="prgm-image">
    </div>
    <div class="Hicsa-prgm-container" *ngIf="mobile">
        <img src="assets/mobile-images/Automated Hotel.png">
    </div>
    <div class="sticky-overview" fxFlex="35" [ngClass]="{ 'sticky-overview-active': showStickyOverview }">
        <div class="overview-header">Overview</div>
        <ul class="overview-list">
            <li><span (click)="viewHotelAutomation()" [class.active]="activeSection === 'Hotel-Automation'">What is
                    hotel automation?</span></li>
            <li><span (click)="viewTypesOfHotel()" [class.active]="activeSection === 'Types-of-Hotel-Automation'">Types
                    of Hotel Automation</span></li>
            <!-- <li><span (click)="viewHotelCrmFeatures()" [class.active]="activeSection === 'Marketing-And-Communication'">Marketing and Communication</span></li> -->
            <li><span (click)="viewHotelCrmBenefits()" [class.active]="activeSection === 'Hotel-CRM-Benefits'">Benefits
                    of hotel automation</span></li>
            <li><span (click)="viewFullyAutomatedHotel()"
                    [class.active]="activeSection === 'Fully-Automated-Hotel'">Ways to Achieve Fully Automated
                    Hotel</span>
            </li>
            <li><span (click)="viewCRMSystem()" [class.active]="activeSection === 'CRM-System'">How To Choose a CRM
                    System?</span>
            </li>
            <li><span (click)="viewConclusion()" [class.active]="activeSection === 'Conclusion'">Conclusion</span></li>
            <li><span (click)="viewKeyTakeAways()" [class.active]="activeSection === 'key-takeaways'">Key
                    takeaways</span>
            </li>
        </ul>
        <div fxLayout="column" class="sticky-content" fxLayoutAlign=" center">
            <div>
                <img src="assets/images/Gofryday-logo.png">
            </div>
            <div>
                <p class="sticky-text">Delight your guests <br> with AI-powered <br> experiences</p>
            </div>
            <div>
                <button class="demo-btn" (click)="openCalendlyPopup()">
                    Book a Demo
                </button>
            </div>
        </div>
    </div>
    <div class="right-side-content" fxLayout="column">
        <div fxLayout="column" class="info-content-revolution" fxLayoutGap="2%" fxFlex="65%">
            <div class="info-text">Ever wondered how those fancy hotels seem to know exactly what you want before you
                even ask? It's all thanks to a magical concept called hotel automation. 
                Think of it like having a personal assistant, but instead of a human, it's a clever system of
                technology. From controlling the lights and temperature in the room to managing the entire hotel's
                energy use, hotel automation makes everything run smoother and more efficiently.
            </div>
        </div>
        <div id="Hotel-Automation" class="info-content-crm" fxLayout="column" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">What is hotel automation?</h2>
            <div class="info-text">Hotel automation is the use of computers and machines to simplify and perform daily
                tasks and improve guest experiences while reducing operational costs.  
                Machines have played a key role in the operations of hotels for many years. The Hotel Automation Systems
                on the other hand have now been able to incorporate a diversified range of systems which include
                self-service kiosks, mobile Check-in, voice and video-controlled systems, virtual assistant appliances,
                mobile applications, stationary rooms tablet, electronic door locks and CCTV surveillance equipments
                among others. <br>
                These devices are rapidly becoming more common for or in assisting some tasks moving from distribution
                through revenue management and into guest interactions.
            </div>
            <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                <img src="assets/images/hotel automation.png">
            </div>
            <div *ngIf="mobile" style="display: flex;justify-content: center;">
                <img src="assets/mobile-images/hotel automation.png">
            </div>
        </div>
        <div id="Types-of-Hotel-Automation" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Types of Hotel Automation</h2>
            <div class="info-text">Below are a few examples of how automation can be implemented across various
                departments.
            </div>
            <!-- <div fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%"> -->
            <h3 class="container-head3">Front-of-House Automation</h3>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div class="container-sub-head">1. Self-Service Check-In/Check-Out
                </div>
                <div class="info-text">The essential elements of check-in and check-out have become quick and easy for
                    guests as they can utilize self-service kiosks or mobile devices now. This translates to improved
                    customer satisfaction due to less time spent queuing.
                </div>
                <!-- <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/data-graph.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/data-graph.png">
                </div> -->
                <div class="demosection">
                    <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Delight your guests with AI-powered experiences
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Keyless Entry
                </h3>
                <div class="info-text">It is easy to gain access to guest’s rooms as the gadgets that have been
                    introduced allow the use of phones or iD rather than a physical key for unlocking doors.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Concierge Chatbots
                </h3>
                <div class="info-text">Concierge aims to make everything simple and quicker and that’s where AI
                    technologies come in. AI chatbots are there to interact with the guests at any given time and they
                    are capable of answering questions, making recommendations or even doing basic tasks.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Robotic Delivery
                </h3>
                <div class="info-text">Champagne, sandwiches, and everything in between can be brought right to guests’
                    rooms by a robotic waitress or a delightful drone. This improves convenience and minimizes potential
                    human error.
                </div>
            </div>
            <h3 class="container-head3">Back-of-House Automation</h3>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div class="container-sub-head">1. Property Management Systems (PMS)
                </div>
                <div class="info-text">Property management is usually a manual affair where the visitors are registered,
                    rooms are allocated, and payments collected. A PMS system eliminates manual tasks. In addition, PMS
                    systems automate reservations, check-in, and check-out processes, reducing the need for additional
                    staff
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Inventory Management Systems
                </h3>
                <div class="info-text">Inventory management systems are good to managers because it is like having a
                    very reliable bot who knows what is happening in the storeroom. Rather than focusing on manually
                    checking the supplies into and out of a place and being apprehensive about exhausting certain items,
                    the system can monitor and update stock quantities periodically and send out notifications to the
                    manager for restocking.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Laundry Automation
                </h3>
                <div class="info-text">Laundry automation systems can streamline the laundry operations for a hotel.
                    With regards to sorting, washing, drying and folding of laundry, the staff will channel all their
                    efforts in producing excellent works. This reduces manual handling and maximizes productivity
                    promoting better laundry results.
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/Laundry Automation.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/Laundry Automation.png">
                </div>
            </div>
            <h3 class="container-head3">Marketing and Communication</h3>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div class="container-sub-head">1. Email Marketing Automation
                </div>
                <div class="info-text">Personalized email campaigns can be sent to guests based on their preferences and
                    behavior, increasing engagement and loyalty.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Review Management Software
                </h3>
                <div class="info-text">Implement robust review management software to monitor and respond to online
                    reviews in real time. By promptly addressing guest feedback, you can enhance customer satisfaction
                    and maintain a positive reputation.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Customer Relationship Management (CRM) Systems
                </h3>
                <div class="info-text">Track guest interactions and preferences to provide personalized service and
                    build long-lasting relationships.
                </div>
                <div class="demosection">
                    <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Learn How GoFriday Helps Hotels Deliver Better Service In Real-Time.
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="Hotel-CRM-Benefits" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Benefits of hotel automation</h2>
            <div class="info-text">Currently hotels are rapidly turning to Hotel Automation Systems to simplify
                operations,
                enhance guest experiences, and boost revenue. By integrating technology into various aspects of their
                business, hotels can have significant benefits. Let's learn some of the key advantages:
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Enhanced Guest Experience 
                </h3>
                <div class="info-text">With automation, it is now possible to provide the guests with personalized and
                    hassle-free stay. Guests can check in and out quickly using self-service kiosks or their
                    smartphones.
                    Need something? Just send a request through the hotel’s app, and it’ll be taken care of right away.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Increased Operational Efficiency
                </h3>
                <div class="info-text">A hotel will be more efficient in the execution of its business functions and at
                    the
                    same time save on manual labor through the use of machines for repetitive tasks. This allows the
                    workforce to focus on the delivery of custom services to the guests creating and imparting valve
                    brand
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Cost Savings and Sustainability
                </h3>
                <div class="info-text">Energy efficient Features built into smart technology can ensure buildings
                    operate
                    their heating, cooling and lighting efficiently in order to conserve energy. In addition, smart
                    technologies can also help the hotel reduce its expenditure since there are fewer manual tasks that
                    need
                    to be performed.
                </div>
                <!-- <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                <img src="assets/images/Decision making.png">
            </div>
            <div *ngIf="mobile">
                <img src="assets/mobile-images/Decision making.png">
            </div> -->
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Competitive Advantage
                </h3>
                <div class="info-text">Investing in automation not only prepares hotels for future industry trends and
                    challenges but also enhances their reputation for delivering exceptional guest experiences. This
                    commitment to efficiency and quality can attract more business and foster customer loyalty, paving
                    the
                    way for long-term success.
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/Competitive Advantage.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/Competitive Advantage.png">
                </div>
            </div>
        </div>

        <div id="Fully-Automated-Hotel" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Ways to Achieve Fully Automated Hotel</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div *ngIf="mobile" style="place-content: center;display: flex;">
                    <img src="assets/mobile-images/Fully Automated Hotel.png">
                </div>
                <div id="correlationcontent" fxLayout="row" fxLayoutGap="8%">
                    <div *ngIf="!mobile">
                        <img src="assets/images/Fully Automated Hotel.png">
                    </div>
                    <div class="info-text">
                        Remember the last time you checked into a hotel and were greeted by a friendly chatbot? Or when
                        your room temperature adjusted to your preference without you lifting a finger? These are just a
                        few examples of how automation is reshaping the way we travel.  How can hotels achieve this
                        level of automation? Let's break it down.
                    </div>
                </div>
            </div>
        </div>

        <div id="CRM-System" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">How To Choose a CRM System?</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Smart Room Technology
                </h3>
                <div class="info-text">The heart of a fully automated hotel is its smart rooms. These rooms are equipped
                    with modern technology that can be controlled remotely. From adjusting the temperature and lighting
                    to ordering room service and controlling curtains, guests have complete control over their
                    environment.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Guest Experience Automation
                </h3>
                <div class="info-text">Guest experience can be significantly improved using tools that enhance guest
                    engagement and self-service, such as automated email confirmations, in room dining recommendations,
                    upsell tools, and mobile check-in applications 
                    One such powerful tool is GoFriday's guest app. Our AI-powered solution is a comprehensive solution
                    that empowers hotels to deliver personalized and efficient experiences. <br>
                    Using our Guest Experience Automation app guests check in seamlessly with their mobile devices,
                    receive personalized recommendations for dining and activities, and submit in-room requests with
                    ease
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Guestroom Automation
                </h3>
                <div class="info-text">Although robots haven't fully mastered hotel housekeeping, automation is
                    enhancing
                    the guest experience in other ways. Guestroom automation goes beyond smart rooms. It involves using
                    technology to streamline daily tasks and improve guest satisfaction. For instance, many hotels now
                    offer
                    smart room technology that allows guests to control lighting, temperature, and even curtains with a
                    simple voice command or smartphone app.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Revenue Management Automation
                </h3>
                <div class="info-text">Moreover, automation can also play a crucial role in revenue management. Dynamic
                    pricing, which involves adjusting room rates based on factors like demand, occupancy, and market
                    trends, is essential for maximizing hotel profitability. Revenue management systems (RMS) can
                    automate this process, analysing market data and making real-time adjustments to ensure that the
                    hotel always operates at maximum capacity. Advanced software can help hotels identify pricing
                    opportunities, optimize room allocations, and ultimately increase revenue.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">5. Hospitality Management
                </h3>
                <div class="info-text">Our comprehensive hotel management software – CLIO provides hotels with a
                    centralized platform to manage all aspects of their operations. From request management to complaint
                    management, our software streamlines processes and improves efficiency.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">6. Implementing Self-Service Technologies
                </h3>
                <div class="info-text">Self-service hotel solutions, such as kiosks and mobile apps, play a crucial role
                    in automating hotel operations. By empowering guests to complete tasks independently, hotels can
                    reduce staffing costs and improve efficiency.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">7. Reporting & Data Analytics
                </h3>
                <div class="info-text">Reporting and data analytics are essential tools for optimizing hotel operations.
                    By analyzing data on guest preferences, resource usage, and operational performance, hotels can
                    improve efficiency, enhance guest satisfaction, and increase revenue.
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/Reporting & Data Analytics.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/Reporting & Data Analytics.png">
                </div>
            </div>
        </div>

        <div id="Conclusion" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Conclusion</h2>
            <div class="info-text">Hotel automation offers a wealth of benefits for both hotel owners and guests. By
                streamlining operations, enhancing guest experiences, and improving energy efficiency, hotels can
                achieve significant cost savings and competitive advantages. However, successful implementation requires
                careful planning, consideration of potential challenges, and a commitment to ongoing maintenance and
                updates.
            </div>
        </div>

        <div id="key-takeaways" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Key takeaways</h2>
            <div class="experience-content" fxLayoutAlign=" center">
                <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                    <div class="info-texts">
                        <ul>
                            <li><b>Enhanced guest experience:</b> Smart hotel technology can provide personalized
                                services, improve response times, and create a more seamless and enjoyable stay.</li>
                            <li><b>Increased operational efficiency:</b> Hotel automation can reduce manual tasks,
                                optimize
                                resource allocation, and streamline processes, leading to cost savings and improved
                                productivity.</li>
                            <li><b>Improved energy efficiency:</b> Automation can help identify and address energy
                                inefficiencies, reducing costs and environmental impact.</li>
                            <li><b>Potential challenges:</b> Implementing hotel automation may involve significant
                                upfront
                                costs, security concerns, and the need for ongoing maintenance and training.</li>
                        </ul>
                        <div class="info-contents">
                            By carefully considering these factors and addressing potential challenges, hotels can
                            successfully leverage automation to achieve their goals and stay ahead of the
                            competition.
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="3%" class="social-media">
                <div>
                    <img src="assets/images/whatsapp.png">
                </div>
                <div>
                    <img src="assets/images/linkedin.png">
                </div>
                <div>
                    <img src="assets/images/email.png">
                </div>
            </div>
        </div>
    </div>
</div>

<div #faqSection class="similar-article width100">
    <div style="padding: 0% 10%;">
        <h2 class="container-head">Similar articles</h2>
    </div>
    <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="card" *ngFor="let article of articles" (click)="article.onClick()">
            <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div class="card-image">
                    <img [src]="article.image" alt="{{ article.title }}">
                </div>
                <div class="card-content">
                    <div class="card-header">{{ article.title }}</div>
                    <div class="card-text">{{ article.text }}</div>
                </div>
                <div style="margin: 0 5% 5% 5%;">
                    <span class="readmore-text">Read More</span>&nbsp;&nbsp;
                    <span class="card-text">({{ article.readTime }})</span>
                </div>
            </div>
        </mat-card>
    </div>
</div>


<div class="faq-container" id="faq-section">
    <h2 class="faq-header">Frequently Asked Questions</h2>
    <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
            {{ faq.question }}
            <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
            {{ faq.answer }}
        </div>
    </div>
</div>
<div class="learn-more-contents" fxLayout="column" fxLayoutGap="2%">
    <div class="learn-more-head">
        Not sure how to get started?
    </div>
    <div class="learn-text">
        Join thousands of hoteliers from around the world already leveraging the power of AI, with GoFriday.
    </div>
    <div>
        <button class="book-btn" (click)="openCalendlyPopup()">
            Book a Demo
        </button>
    </div>
</div>
<div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div>
            <img src="assets/images/contact-us.png">
        </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
        <div class="form-heading">
            Contact Us
        </div>
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                            placeholder="Email" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
        Contact Us
    </div>
    <div>
        <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
        <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                            autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<app-footer></app-footer>