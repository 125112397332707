import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../popup/popup.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const Calendly: any;

@Component({
  selector: 'app-blog12',
  templateUrl: './blog12.component.html',
  styleUrls: ['./blog12.component.css']
})
export class Blog12Component {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'Which is better for families or large groups?',
      answer: 'Hotels typically offer larger rooms or suites, along with family-friendly amenities, making them a better choice for families or groups.',
      open: false
    },
    {
      question: 'Are there any security differences?',
      answer: 'Yes. Hotels often feature more advanced security systems, including key card access, cameras, and 24-hour staff. Motels, while offering basic security, may not have these additional measures.',
      open: false
    },
    {
      question: 'Can I find extended stay options at both?',
      answer: 'Extended-stay hotels are more common, offering amenities like kitchens and laundry services. Some motels offer weekly rates, but with fewer conveniences for longer stays.',
      open: false
    }
  ];

  articles = [
    {
      title: '5 core features of a crm system for hotels',
      text: 'Let’s consider a situation where you know your guests’ preferences so well, that every... ',
      image: 'assets/images/5-core-features-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewCoreFeaturesOfACrm()
    },
    {
      title: '9 Core Features of a CRM System for Hotels',
      text: 'The modern hotel industry is driven by data: customer data! In the competitive world...',
      image: 'assets/images/Features-CRM-image.png',
      readTime: '4 Minutes Read',
      onClick: () => this.viewFeaturesOfCRM()
    },
    {
      title: 'Step-by-step guide: how to start a travel agency',
      text: 'Business Opportunities in Travel and Tourism for the Passionate Traveler has...',
      image: 'assets/images/travel-agency-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewTravelAgency()
    }
  ]

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'Difference-Between-Hotel-and-Motel',
      'Pros-Cons-Hotel',
      'Pros-Cons-Motel',
      'Hotel-And-Motel',
      'Conclusion'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewCoreFeaturesOfACrm() {
    this.router.navigateByUrl('blog/benefits-of-crm-in-hotel-industry');
    window.scrollTo(0, 0);
  }

  viewFeaturesOfCRM() {
    this.router.navigateByUrl('blog/features-of-a-crm-system-for-hotels');
    window.scrollTo(0, 0);
  }

  viewTravelAgency() {
    this.router.navigateByUrl('blog/how-to-start-a-travel-agency');
    window.scrollTo(0, 0);
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  viewLinkedIn() {
    const linkedInUrl = environment.LINKEDIN_URL;
    const websiteUrl = `${environment.WEBSITE_URL}/blog/difference-between-hotel-and-motel`;
    const fullUrl = `${linkedInUrl}${websiteUrl}`;
    window.open(fullUrl, "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.isPopupOpen = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (!!data?.object?.ID) {
              const dialogRef = this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
              dialogRef.afterClosed().subscribe(() => {
                this.isPopupOpen = false;
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }


  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewHotelAndMotel() {
    this.scrollToSection('Difference-Between-Hotel-and-Motel');
  }

  viewProsConsHotel() {
    this.scrollToSection('Pros-Cons-Hotel');
  }

  viewProsConsMotel() {
    this.scrollToSection('Pros-Cons-Motel');
  }

  viewChoosingBetweenHotelAndMotel() {
    this.scrollToSection('Hotel-And-Motel');
  }

  viewConclusion() {
    this.scrollToSection('Conclusion');
  }


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}
