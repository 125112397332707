import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { ReactiveFormsModule } from '@angular/forms'; 
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { SectionsComponent } from './sections/sections.component';
import { HeaderComponent } from './sections/header/header.component';
import { FooterComponent } from './sections/footer/footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { FreeTrialComponent } from './pages/free-trial/free-trial.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HttpClientModule } from '@angular/common/http';
import { PopupComponent } from './pages/popup/popup.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { PlansComponent } from './pages/plans/plans.component';
import { FeaturesPageComponent } from './pages/features-page/features-page.component';
import { NgChartsModule } from 'ng2-charts';
import { PartnerProgramComponent } from './pages/partner-program/partner-program.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { ExperienceDetailComponent } from './pages/experience-detail/experience-detail.component';
import { ReserveComponent } from './pages/reserve/reserve.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ScrollTopComponent } from './pages/scroll-top/scroll-top.component';
import { GuestAppComponent } from './pages/guest-app/guest-app.component';
import { ClioComponent } from './pages/clio/clio.component';
import { TaskAppComponent } from './pages/task-app/task-app.component';
import { CalendlyComponent } from './pages/calendly/calendly.component';
import { Blog1Component } from './pages/blog1/blog1.component';
import { Blog2Component } from './pages/blog2/blog2.component';
import { Blog3Component } from './pages/blog3/blog3.component';
import { Blog4Component } from './pages/blog4/blog4.component';
import { Blog5Component } from './pages/blog5/blog5.component';
import { Blog6Component } from './pages/blog6/blog6.component';
import { Blog7Component } from './pages/blog7/blog7.component';
import { Blog8Component } from './pages/blog8/blog8.component';
import { Blog9Component } from './pages/blog9/blog9.component';
import { Blog10Component } from './pages/blog10/blog10.component';
import { Blog11Component } from './pages/blog11/blog11.component';
import { Blog12Component } from './pages/blog12/blog12.component';
import { Blog13Component } from './pages/blog13/blog13.component';
import { Blog14Component } from './pages/blog14/blog14.component';
import { Blog15Component } from './pages/blog15/blog15.component';
import { Blog16Component } from './pages/blog16/blog16.component';
import { Blog17Component } from './pages/blog17/blog17.component';
import { Blog18Component } from './pages/blog18/blog18.component';
import { Products1Component } from './pages/products1/products1.component';
import { InRoomServicesComponent } from './pages/in-room-services/in-room-services.component';
import { FoodOrderingSystemComponent } from './pages/food-ordering-system/food-ordering-system.component';
import { VirtualConciergeServicesComponent } from './pages/virtual-concierge-services/virtual-concierge-services.component';
import { FeedbackSystemComponent } from './pages/feedback-system/feedback-system.component';
import { PropertyManagementSystemComponent } from './pages/property-management-system/property-management-system.component';
import { FeedbackManagementSoftwareComponent } from './pages/feedback-management-software/feedback-management-software.component';
import { TaskManagementSoftwareComponent } from './pages/task-management-software/task-management-software.component';
import { SpaManagementModuleComponent } from './pages/spa-management-module/spa-management-module.component';
import { FoodBeverageManagementComponent } from './pages/food-beverage-management/food-beverage-management.component';
import { EConferenceRoomManagementComponent } from './pages/e-conference-room-management/e-conference-room-management.component';
import { EConciergeManagementComponent } from './pages/e-concierge-management/e-concierge-management.component';
import { HousekeepingManagementComponent } from './pages/housekeeping-management/housekeeping-management.component';
import { CentralizedReservationManagementComponent } from './pages/centralized-reservation-management/centralized-reservation-management.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    PagesComponent,
    HomePageComponent,
    SectionsComponent,
    HeaderComponent,
    FooterComponent,
    FreeTrialComponent,
    AboutUsComponent,
    PopupComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    BlogPageComponent,
    PlansComponent,
    FeaturesPageComponent,
    PartnerProgramComponent,
    ExperienceComponent,
    ExperienceDetailComponent,
    ReserveComponent,
    ContactUsComponent,
    ScrollTopComponent,
    GuestAppComponent,
    ClioComponent,
    TaskAppComponent,
    CalendlyComponent,
    Blog1Component,
    Blog2Component,
    Blog3Component,
    Blog4Component,
    Blog5Component,
    Blog6Component,
    Blog7Component,
    Blog8Component,
    Blog9Component,
    Blog10Component,
    Blog11Component,
    Blog12Component,
    Blog13Component,
    Blog14Component,
    Blog15Component,
    Blog16Component,
    Blog17Component,
    Blog18Component,
    Products1Component,
    InRoomServicesComponent,
    FoodOrderingSystemComponent,
    VirtualConciergeServicesComponent,
    FeedbackSystemComponent,
    PropertyManagementSystemComponent,
    FeedbackManagementSoftwareComponent,
    TaskManagementSoftwareComponent,
    SpaManagementModuleComponent,
    FoodBeverageManagementComponent,
    EConferenceRoomManagementComponent,
    EConciergeManagementComponent,
    HousekeepingManagementComponent,
    CentralizedReservationManagementComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FlexLayoutModule,
    MaterialModule,
    NgChartsModule,
    RecaptchaV3Module,
    ReactiveFormsModule
  ],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_SITE_KEY }],
  bootstrap: [AppComponent]
})
export class AppModule { }