import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const Calendly: any;

@Component({
  selector: 'app-centralized-reservation-management',
  templateUrl: './centralized-reservation-management.component.html',
  styleUrls: ['./centralized-reservation-management.component.css']
})
export class CentralizedReservationManagementComponent {
  mobile: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;

  features = [
    {
      title: 'OTA Connectivity',
      description: 'Integrate with leading online travel agencies (OTAs) effortlessly.',
      image: 'assets/images/OTA Connectivity.png',
      isHovered: false,
    },
    {
      title: 'Global GDS Integration',
      description: 'Reach corporate clients and travel agents with global distribution system support.',
      image: 'assets/images/Global GDS Integration.png',
      isHovered: false,
    },
    {
      title: 'Multi-Channel Sync',
      description: 'Synchronize room availability and rates across all platforms in real time.',
      image: 'assets/images/Multi-Channel Sync.png',
      isHovered: false,
    },
    {
      title: 'Performance Insights',
      description: 'Monitor the performance of each channel to optimize your strategy.',
      image: 'assets/images/Performance Insights1.png',
      isHovered: false,
    },
  ];


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(private router: Router) { }
  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

}
