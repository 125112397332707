import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/shared/color-object';
import { trigger, state, style, animate, transition, stagger, query } from '@angular/animations';
import { Router, NavigationExtras } from '@angular/router';
import { Chart } from 'chart.js';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const Calendly: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  animations: [
    trigger('letterAnimation', [
      state('start', style({ opacity: 1 })),
      transition('void => *', [
        style({ opacity: 0 }),
        animate('50ms ease-in-out', style({ opacity: 1 })),
      ]),
    ]),

    trigger('imageAnimation', [
      state(
        'center',
        style({
          transform: 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
        })
      ),
      state(
        'right',
        style({
          transform: 'translate(0, -50%)',
          top: '50%',
          left: '80%',
        })
      ),
      transition('center => right', [animate('1s ease-in-out')]),
    ]),
    trigger('featureAnimation', [
      transition(':enter', [
        query('.feature-label', [
          style({ opacity: 0, transform: 'translateY(20px)' }),
          stagger(100, [
            animate('0.5s ease', style({ opacity: 1, transform: 'translateY(0)' }))
          ])
        ], { optional: true })
      ])
    ]),
    trigger('mobileAnimation', [
      state('initial', style({
        opacity: 1,
        left: '50%',
        top: '70%',
        transform: 'translate(-50%, -50%) scale(1)'
      })),
      state('right', style({
        opacity: 1,
        left: 'calc(100% - 150px)',
        top: '70%',
        transform: 'translate(-50%, -50%) scale(0.8)'
      })),
      transition('initial => right', animate('1s ease-in-out'))
    ]),
    trigger('backgroundAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s ease-in', style({ opacity: 1 }))
      ])
    ]),
    trigger('textAnimation', [
      transition(':enter', [
        query('span', [
          style({ opacity: 0, transform: 'translateY(20px)' }),
          stagger(50, [
            animate('0.5s ease', style({ opacity: 1, transform: 'translateY(0)' }))
          ])
        ])
      ]),
      transition(':leave', [
        query('span', [
          style({ opacity: 0, transform: 'translateY(20px)' }),
          stagger(50, [
            animate('0.5s ease', style({ opacity: 0, transform: 'translateY(20px)' }))
          ])
        ])
      ])
    ]),


    // trigger('letterAnimation', [
    //   state('start', style({ opacity: 1 })),
    //   transition('void => *', [
    //     style({ opacity: 0 }),
    //     animate('50ms ease-in-out', style({ opacity: 1 })),
    //   ]),
    // ]),
    // trigger('cardIn', [
    //   state('in', style({ transform: 'translateX(0)' })),
    //   state('out', style({ transform: 'translateX(-100%)' })),
    //   transition('out => in', [
    //     style({ transform: 'translateX(-100%)' }),
    //     animate('1000ms ease-out', style({ transform: 'translateX(0)' })),
    //   ]),
    // ]),
    // trigger('videoIn', [
    //   state('in', style({ transform: 'translateX(0)' })),
    //   state('out', style({ transform: 'translateX(120%)' })),
    //   transition('out => in', [
    //     style({ transform: 'translateX(120%)' }),
    //     animate('1000ms ease-out', style({ transform: 'translateX(0)' }))
    //   ])
    // ]),
    // trigger('card1In', [
    //   state('in', style({ transform: 'translate3d(0,0,0)', opacity: 1 })),
    //   state('out', style({ transform: 'translate3d(-500px,-500px,500px)', opacity: 0 })),
    //   transition('out => in', [
    //     style({ transform: 'translate3d(-500px,-500px,500px)', opacity: 0 }),
    //     animate('1000ms ease-out', style({ transform: 'translate3d(0,0,0)', opacity: 1 }))
    //   ])
    // ]),
    // // trigger('card2In', [
    // //   state('in', style({ transform: 'translateY(0)' })),
    // //   state('out', style({ transform: 'translateY(100%)' })),
    // //   transition('out => in', [
    // //     style({transform: 'translateY(100%)'}),
    // //     animate('1000ms ease-out', style({ transform: 'translateY(0)' }))
    // //   ])
    // // ]),
    // trigger('card2In', [
    //   state('in', style({ bottom: '0', opacity: 1 })),
    //   state('out', style({ bottom: '-500px', opacity: 0 })),
    //   transition('out => in', [
    //     style({ bottom: '-500px', opacity: 0 }),
    //     animate('1000ms ease-out', style({ bottom: '0', opacity: 1 }))
    //   ])
    // ]),
    // trigger('card3In', [
    //   state('in', style({ transform: 'translate3d(0,0,0)', opacity: 1 })),
    //   state('out', style({ transform: 'translate3d(500px,-500px,-500px)', opacity: 0 })),
    //   transition('out => in', [
    //     style({ transform: 'translate3d(500px,-500px,-500px)', opacity: 0 }),
    //     animate('1000ms ease-out', style({ transform: 'translate3d(0,0,0)', opacity: 1 }))
    //   ])
    // ]),


    trigger('imageTransition', [
      state('initial', style({
        transform: 'perspective(1000px) rotateX(8.6064deg)'
      })),
      state('scrolled1', style({
        transform: 'perspective(1000px) rotateX({{ scrolledRotation }}deg)'
      }), { params: { scrolledRotation: 8 } }),
      state('scrolled2', style({
        transform: 'perspective(1000px) rotateX({{ scrolledRotation }}deg)'
      }), { params: { scrolledRotation: 5 } }),
      state('scrolled3', style({
        transform: 'perspective(1000px) rotateX({{ scrolledRotation }}deg)'
      }), { params: { scrolledRotation: -1 } }),
      state('scrolled4', style({
        transform: 'perspective(1000px) rotateX({{ scrolledRotation }}deg)'
      }), { params: { scrolledRotation: -5 } }),
      transition('initial <=> scrolled1, scrolled1 <=> scrolled2, scrolled2 <=> scrolled3, scrolled3 <=> scrolled4', [
        animate('0.5s')
      ])
    ])
  ],

})


export class HomePageComponent {
  @ViewChild('videoPlayer') videoPlayer!: ElementRef;
  @ViewChild("mainVideo", { static: false })
  mainVideo: ElementRef;
  @ViewChild("guestVideo", { static: false })
  guestVideo: ElementRef;
  @ViewChild("clioVideo", { static: false })
  clioVideo: ElementRef;
  @ViewChild("taskVideo", { static: false })
  taskVideo: ElementRef;
  @ViewChild("animationArea", { static: false })
  animationArea: ElementRef;
  @ViewChild('video1', { static: false })
  video1: ElementRef;
  @ViewChild('video2', { static: false })
  video2: ElementRef;
  @ViewChild('video3', { static: false })
  video3: ElementRef;
  mobile: boolean = false;
  // headerWord: string = 'Revenue.';
  // animatedWord: string = '';
  animationState: string = 'start';
  cardAnimationState: string = 'out';
  videoAnimationState: string = 'out';
  card1AnimationState: string;
  card2AnimationState: string = 'out';
  card3AnimationState: string;
  imageState: boolean = false;
  cardState: boolean = false;
  isMainVideoIntoView: boolean;
  isGuestVideoIntoView: boolean;
  isClioVideoIntoView: boolean;
  isTaskVideoIntoView: boolean;
  isCardAnimationAreaIntoView: boolean;
  isTestDivScrolledIntoView: boolean;
  isTestDivScrolledIntoView2: boolean;
  isTestDivScrolledIntoView3: boolean;
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  contactPopupSetTimeOut;
  disabled: boolean = false;
  state = 'initial';
  scrolledRotation = 0;
  guestAppLink: string;
  clioAppLink: string;
  taskAppLink: string;
  hotelManagementLink: string
  public calendlyUrl = environment.CALENDLY_URL;
  mobilePosition = { x: '50%', y: '50%', scale: 1 };
  animationState1 = 'initial';
  showBackground = true;
  showText = false;
  showFeatures = true;
  currentMobileImage = 'assets/images/home-mobile-image.png';
  text = 'All In One Platform';
  textColor: string = 'header-text';
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  poster = '../../../assets/images/thumbnail.png';
  poster1 = '../../../assets/images/guest-app.png';
  poster2 = '../../../assets/images/clio-admin-panel.png';
  poster3 = '../../../assets/images/task-app.png';
  isMuted: boolean = true;
  // @HostListener('window:scroll', ['$event'])
  // isScrolledIntoView() {
  //   if (this.infoVideo && window.innerWidth <= 1250) {
  //     this.isMuted = true;
  //     const rect = this.infoVideo.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isInfoVideoIntoView = topShown && bottomShown;
  //     if (this.isInfoVideoIntoView == true) {
  //       this.infoVideo.nativeElement.play();
  //     } else {
  //       this.infoVideo.nativeElement.pause();
  //     }
  //   }
  //   if (this.infoVideo && window.innerWidth > 1250) {
  //     this.isMuted = true;
  //     const rect = this.infoVideo.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isInfoVideoIntoView = topShown && bottomShown;
  //     if (this.isInfoVideoIntoView == true) {
  //       this.cardAnimationState = 'in';
  //       this.videoAnimationState = 'in';
  //       this.infoVideo.nativeElement.play();
  //     } else {
  //       this.infoVideo.nativeElement.pause();
  //     }
  //   }
  //   if (this.animationArea && window.innerWidth > 1250) {
  //     this.isMuted = true;
  //     const rect = this.animationArea.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isCardAnimationAreaIntoView = topShown && bottomShown;
  //     if (this.isCardAnimationAreaIntoView == true) {
  //       this.card1AnimationState = 'in';
  //       this.card2AnimationState = 'in';
  //       this.card3AnimationState = 'in';
  //     }
  //   }
  //   if (this.video1 && window.innerWidth <= 1250) {
  //     const rect = this.video1.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isTestDivScrolledIntoView = topShown && bottomShown;
  //     if (this.isTestDivScrolledIntoView == true) {
  //       this.video1.nativeElement.play();
  //     } else {
  //       this.video1.nativeElement.pause();
  //     }
  //   }
  //   if (this.video2 && window.innerWidth <= 1250) {
  //     const rect = this.video2.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isTestDivScrolledIntoView2 = topShown && bottomShown;
  //     if (this.isTestDivScrolledIntoView2 == true) {
  //       this.video2.nativeElement.play();
  //     } else {
  //       this.video2.nativeElement.pause();
  //     }
  //   }
  //   if (this.video3 && window.innerWidth <= 1250) {
  //     const rect = this.video3.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isTestDivScrolledIntoView3 = topShown && bottomShown;
  //     if (this.isTestDivScrolledIntoView3 == true) {
  //       this.video3.nativeElement.play();
  //     } else {
  //       this.video3.nativeElement.pause();
  //     }
  //   }
  // }
  public barchart: any;
  public piechart: any;

  faqs = [
    {
      question: 'What is GoFriday Hotel Management Software?',
      answer: 'GoFriday is your one-stop shop for transforming your hotel. It streamlines operations, elevates guest experiences, and unlocks new revenue opportunities.',
      open: false
    },
    {
      question: 'Is our hotel system software suitable for small hotels?',
      answer: 'Yes',
      open: false
    },
    {
      question: 'Is GoFriday Hotel Management Software cloud-based?',
      answer: 'Yes',
      open: false
    },
    {
      question: 'Can our hotel management system integrate with other systems?',
      answer: 'Yes, absolutely',
      open: false
    },
    {
      question: 'How easy is it to implement our hospitality management system?',
      answer: 'Super easy. No integrations required. We also offer an open API for easy integration if needed.',
      open: false
    },
    {
      question: 'What kind of customer support do you offer?',
      answer: '24/7 customer support available.',
      open: false
    }
  ];

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const thresholds = [100, 200, 300, 400];
    const rotations = [8, 5, -1, -5];

    for (let i = 0; i < thresholds.length; i++) {
      if (offset > thresholds[i]) {
        this.scrolledRotation = rotations[i];
        this.state = `scrolled${i + 1}`;
      }
    }

    if (this.mainVideo) {
      this.isMuted = true;
      const rect = this.mainVideo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isMainVideoIntoView = topShown && bottomShown;
      if (this.isMainVideoIntoView == true) {
        this.mainVideo.nativeElement.play();
      } else {
        this.mainVideo.nativeElement.pause();
      }
    }

    if (this.guestVideo && window.innerWidth <= 1250) {
      this.isMuted = true;
      const rect = this.guestVideo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isGuestVideoIntoView = topShown && bottomShown;
      if (this.isGuestVideoIntoView == true) {
        this.guestVideo.nativeElement.play();
      } else {
        this.guestVideo.nativeElement.pause();
      }
    }
    if (this.guestVideo && window.innerWidth > 1250) {
      this.isMuted = true;
      const rect = this.guestVideo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isGuestVideoIntoView = topShown && bottomShown;
      if (this.isGuestVideoIntoView == true) {
        this.guestVideo.nativeElement.play();
      } else {
        this.guestVideo.nativeElement.pause();
      }
    }
    if (this.clioVideo && window.innerWidth <= 1250) {
      this.isMuted = true;
      const rect = this.clioVideo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isClioVideoIntoView = topShown && bottomShown;
      if (this.isClioVideoIntoView == true) {
        this.clioVideo.nativeElement.play();
      } else {
        this.clioVideo.nativeElement.pause();
      }
    }
    if (this.clioVideo && window.innerWidth > 1250) {
      this.isMuted = true;
      const rect = this.clioVideo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isClioVideoIntoView = topShown && bottomShown;
      if (this.isClioVideoIntoView == true) {
        this.clioVideo.nativeElement.play();
      } else {
        this.clioVideo.nativeElement.pause();
      }
    }
    if (this.taskVideo && window.innerWidth <= 1250) {
      this.isMuted = true;
      const rect = this.taskVideo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isTaskVideoIntoView = topShown && bottomShown;
      if (this.isTaskVideoIntoView == true) {
        this.taskVideo.nativeElement.play();
      } else {
        this.taskVideo.nativeElement.pause();
      }
    }
    if (this.taskVideo && window.innerWidth > 1250) {
      this.isMuted = true;
      const rect = this.taskVideo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isTaskVideoIntoView = topShown && bottomShown;
      if (this.isTaskVideoIntoView == true) {
        this.taskVideo.nativeElement.play();
      } else {
        this.taskVideo.nativeElement.pause();
      }
    }
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })

  }
  ngOnInit() {
    this.checkScreenWidth();
    this.getMasterData();
    // setTimeout(() => {
    //   this.animateWord();
    // }, 1000);
    // this.createChart();
    // setTimeout(() => {
    //   this.imageState = true;
    // }, 500);
    // setTimeout(() => {
    //   this.cardState = true;
    // }, 1000);
    let popupScreen = window.sessionStorage.getItem('homePopup');
    // window.sessionStorage.setItem('currentPath', this.router.url);
    if (!popupScreen) {
      this.showContactPopup();
    }
    this.guestAppLink = `${environment.WEBSITE_URL}/products/hotel-guest-experience-software`;
    this.clioAppLink = `${environment.WEBSITE_URL}/products/clio-hotel-business-intelligence-software`;
    this.taskAppLink = `${environment.WEBSITE_URL}/products/hotel-task-management-software`;
    this.hotelManagementLink = `${environment.WEBSITE_URL}/blog/what-is-hospitality-management`;
    this.startAnimationSequence();
  }

  startAnimationSequence() {
    this.showText = true;
    this.textColor = 'header-text';
    setTimeout(() => {
      this.currentMobileImage = 'assets/images/home-mobile-image2.png';
      this.animationState1 = 'right';
      this.showFeatures = true;
      this.mobilePosition = { x: 'calc(100% - 350px)', y: '50%', scale: 0.8 };
      this.showText = false;
      this.showBackground = true;
      setTimeout(() => {
        this.text = 'Pre-Check-In to Check-Out';
        this.showText = true;
        this.textColor = 'header-text2';
      }, 500);
    }, 3000);
  }


  get textArray() {
    return this.text.split('').map(char => char === ' ' ? '\u00A0' : char);
  }

  ngOnDestroy(): void {
    clearTimeout(this.contactPopupSetTimeOut);
  }


  showContactPopup() {
    this.contactPopupSetTimeOut = setTimeout(() => {
      let popupScreen = window.sessionStorage.getItem('homePopup');
      if (!popupScreen) {
        window.sessionStorage.setItem('homePopup', 'true')
        const dialogRef = this.dialog.open(ContactUsComponent, {
          width: '80%',
          height: '81vh',
          position: { top: '14vh' },
          disableClose: true,
          autoFocus: false
        });
      }
    }, 6000);
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
      // this.card1AnimationState = 'in';
      // this.card3AnimationState = 'in';
    } else {
      this.mobile = false;
      // this.card1AnimationState = 'out';
      // this.card3AnimationState = 'out';
    }
  }

  ngAfterViewInit() {
    //   if (window.innerWidth <= 1250) {
    //     this.mobile = true;
    //     this.card1AnimationState = 'in';
    //     this.card3AnimationState = 'in';
    //   } else {
    //     this.mobile = false;
    //     this.card1AnimationState = 'out';
    //     this.card3AnimationState = 'out';
    //   }

    if (this.videoPlayer?.nativeElement) {
      this.videoPlayer.nativeElement.loop = true;
      this.videoPlayer.nativeElement.muted = true;
      this.videoPlayer.nativeElement.play();
    }
  }

  getMasterData() {
    this.hotelService.getMasterData().subscribe({
      next: (data) => {
        data.object.forEach(item => {
          for (let val in item) {
            item[val].shift();
            window.localStorage.setItem(val, JSON.stringify(item[val]));
          }
        })
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getButtonName(event: any, button: any) {
    if (button == 'About') {
      event.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  // animateWord() {
  //   const wordArray = this.headerWord.split('');
  //   let currentIndex = 0;
  //   const intervalId = setInterval(() => {
  //     if (currentIndex < wordArray.length) {
  //       this.animatedWord += wordArray[currentIndex];
  //       currentIndex++;
  //     } else {
  //       clearInterval(intervalId);
  //     }
  //   }, 100);
  // }

  // selectBlog() {
  //   this.router.navigateByUrl('blog');
  //   window.scrollTo(0, 0);
  // }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectOnlineCheckInCheckOut() {
    this.router.navigateByUrl('products/online-check-in-to-online-check-out');
    window.scrollTo(0, 0);
  }

  selectInRoomServices() {
    this.router.navigateByUrl('products/in-room-services');
    window.scrollTo(0, 0);
  }

  selectFoodOrderingSystem() {
    this.router.navigateByUrl('products/food-ordering-system');
    window.scrollTo(0, 0);
  }

  selectEConciergeManagement() {
    this.router.navigateByUrl('products/virtual-concierge-services');
    window.scrollTo(0, 0);
  }

  selectTaskManagement() {
    this.router.navigateByUrl('products/task-management-software');
    window.scrollTo(0, 0);
  }

  selectPropertyManagement() {
    this.router.navigateByUrl('products/property-management-system');
    window.scrollTo(0, 0);
  }

  selectHousekeepingManagement() {
    this.router.navigateByUrl('products/housekeeping-management');
    window.scrollTo(0, 0);
  }

  selectCentralizedReservationManagement() {
    this.router.navigateByUrl('products/centralized-reservation-management');
    window.scrollTo(0, 0);
  }

  selectFoodandBeverage() {
    this.router.navigateByUrl('products/food-beverage-management');
    window.scrollTo(0, 0);
  }

  selectSpaManagement() {
    this.router.navigateByUrl('products/spa-management-module');
    window.scrollTo(0, 0);
  }

  selectEConferenceRoomManagement() {
    this.router.navigateByUrl('products/e-conference-room-management');
    window.scrollTo(0, 0);
  }

  selectFeedbackManagement() {
    this.router.navigateByUrl('products/feedback-management-software');
    window.scrollTo(0, 0);
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

  selectHicsa() {
    const navigationExtras: NavigationExtras = {
      fragment: 'correlationcontent'
    };
    this.router.navigate(['/blog/hicsa'], navigationExtras);
  }

  selectGuestCentricRevolution() {
    const navigationExtras: NavigationExtras = {
      fragment: 'AI-In-Hospitality'
    };
    this.router.navigate(['/blog/guest-centric-revolution'], navigationExtras);
  }

  selectHotelCRM() {
    const navigationExtras: NavigationExtras = {
    };
    this.router.navigate(['/blog/what-is-a-hotel-crm'], navigationExtras);
    window.scrollTo(0, 0);
  }

  toggleVideo() {
    this.isMuted = false;
  }

  getVideo(video) {
    video.play();
  }

  stopVideo(video) {
    video.pause();
  }

  // createChart() {
  //   this.barchart = new Chart("barChart", {
  //     type: 'bar',
  //     data: {
  //       labels: ['March', 'April', 'May', 'June', 'July'],
  //       datasets: [
  //         {
  //           data: ['9500', '9500', '5800', '10000', '6000'],
  //           backgroundColor: 'rgba(237, 137, 77, 1)'
  //         },
  //         {
  //           data: ['7500', '4000', '4000', '6300', '8000'],
  //           backgroundColor: 'rgba(91, 164, 183, 1)'
  //         }
  //       ]
  //     },
  //     options: {
  //       scales: {
  //         y: {
  //           beginAtZero: false,
  //           min: 2000,
  //           max: 10000,
  //           ticks: {
  //             stepSize: 2000,
  //             callback: function (value) {
  //               return '$' + value
  //             },
  //             color: 'rgba(0, 0, 0, 0.75)',
  //             font: {
  //               size: 8,
  //               family: 'Montserrat',
  //               weight: 400
  //             }
  //           },
  //           grid: {
  //             display: false
  //           }
  //         },
  //         x: {
  //           ticks: {
  //             color: 'rgba(0, 0, 0, 0.75)',
  //             font: {
  //               size: 8,
  //               family: 'Montserrat',
  //               weight: 400
  //             }
  //           },
  //           grid: {
  //             display: false
  //           }
  //         }
  //       },
  //       responsive: true,
  //       layout: {
  //         padding: 20,
  //       },
  //       plugins: {
  //         title: {
  //           display: true,
  //           text: 'Violated SLAs',
  //           color: 'rgba(38, 50, 56, 1)',
  //           font: {
  //             size: 14,
  //             family: 'Montserrat',
  //             weight: 600
  //           }
  //         },
  //         tooltip: {
  //           enabled: false
  //         },
  //         legend: {
  //           display: false
  //         }
  //       },
  //       animation: {
  //         delay: 500,
  //         duration: 2000
  //       }
  //     }
  //   });
  //   const pieChartCanvas = document.getElementById('pieChart') as HTMLCanvasElement;
  //   const pieCtx = pieChartCanvas.getContext('2d');
  //   if (!pieCtx) {
  //     console.error("Canvas context is null.");
  //     return;
  //   }
  //   let gradient = pieCtx.createLinearGradient(0, 0, 0, pieChartCanvas.height);
  //   gradient.addColorStop(0, 'rgba(209, 80, 49, 1)');
  //   gradient.addColorStop(1, 'rgba(247, 148, 44, 1)');
  //   this.piechart = new Chart(pieCtx, {
  //     type: 'doughnut',
  //     data: {
  //       datasets: [{
  //         data: [89, 11],
  //         backgroundColor: [gradient, 'rgba(217, 217, 217, 1)'],
  //         borderWidth: 1,
  //         borderAlign: 'inner'
  //       }]
  //     },
  //     options: {
  //       cutout: '75%',
  //       rotation: 270,
  //       responsive: true,
  //       layout: {
  //         padding: 20
  //       },
  //       plugins: {
  //         title: {
  //           display: true,
  //           text: 'CSAT',
  //           color: 'rgba(38, 50, 56, 1)',
  //           font: {
  //             size: 14,
  //             family: 'Montserrat',
  //             weight: 600
  //           }
  //         },
  //         tooltip: {
  //           enabled: false
  //         },
  //         legend: {
  //           display: false
  //         }
  //       },
  //       animation: {
  //         animateRotate: true,
  //         delay: 500,
  //         duration: 2000
  //       }
  //     },
  //     plugins: [{
  //       id: 'doughnutLabel',
  //       beforeDatasetDraw(chart, args, pluginOptions) {
  //         const { ctx, data } = chart;
  //         ctx.save();
  //         const xCoor = chart.getDatasetMeta(0).data[0].x;
  //         const yCoor = chart.getDatasetMeta(0).data[0].y;
  //         const radius = 80;
  //         ctx.beginPath();
  //         ctx.lineWidth = 2;
  //         ctx.strokeStyle = 'rgba(178, 178, 178, 0.25)';
  //         ctx.fillStyle = 'rgba(255, 255, 255, 1)';
  //         ctx.roundRect(xCoor - (radius / 2), yCoor - (radius / 2), radius, radius, 50);
  //         ctx.stroke();
  //         ctx.fill();
  //         ctx.font = 'bold 16px Montserrat';
  //         ctx.fillStyle = 'rgba(255, 159, 36, 1)';
  //         ctx.textAlign = 'center';
  //         ctx.textBaseline = 'middle';
  //         ctx.fillText('89%', xCoor, yCoor);
  //       }
  //     }]
  //   });
  // }



  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (data?.object?.ID) {
              this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                colorObj[data.status] || "success",
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              colorObj[e.error.status] || "error",
              e.error.status
            );
          }
        });
      },
      error: () => {
        this.disabled = false;
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }


}