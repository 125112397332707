import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject, takeUntil } from 'rxjs';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/shared/color-object';
import * as moment from 'moment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: ['./reserve.component.css']
})

export class ReserveComponent {

  loading: boolean = false;
  reserveForm: FormGroup;
  orderDetails = false;
  bookingConfirm = false;
  mobile: boolean = false;
  namePattern = /^[a-zA-Z]+$/;
  emailPattern = environment.EMAIL_PATTERN;
  mobilenopattern = /^[0-9]{1,50}$/;
  adultCount: number = 1;
  childCount: number = 0;
  totalCount: number = 0;
  updatedAdultCount: number = 1;
  updatedChildCount: number = 0;
  currentDate: Date = new Date();
  disabled: boolean = false;
  country: FormControl = new FormControl();
  private _onDestroy = new Subject<void>();
  countryCode: string = '+91';
  selectedCountry: any;
  filterList: any = [];
  countryList: any = [];
  packageId;
  experiencePackage: any = [];
  public bucketUrl = `${environment.BUCKET_URL}`;
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private fb: FormBuilder,
    private hotelService: HotelService,
    private router: Router,
    private notify: NotificationService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.reserveForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(this.namePattern), Validators.minLength(3), Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.pattern(this.namePattern), Validators.maxLength(100)]],
      emailId: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      phoneCode: [''],
      phoneNumber: ['', [Validators.required, Validators.pattern(this.mobilenopattern)]],
      date: ['', Validators.required],
      guest: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    this.packageId = window.localStorage.getItem('SELECTED_PACKAGE');
    let countryListArray = window.localStorage.getItem('COUNTRY');
    this.countryList = countryListArray;
    this.countryList = JSON.parse(this.countryList);
    this.filterList = this.countryList;
    for (let country of this.filterList) {
      if (country.CODE == '91') {
        this.reserveForm.controls['phoneCode'].setValue(country.ID);
      }
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.country.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountry();
      });
  }

  filterCountry() {
    let search = this.country.value;
    this.filterList = this.countryList.filter(country =>
      country.NAME.toLowerCase().indexOf(search.toLowerCase()) !== -1 || country.CODE.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

  changeCountry(country) {
    for (let coun of this.countryList) {
      if (coun.ID == country) {
        this.countryCode = '+' + coun.CODE;
        this.selectedCountry = coun.NAME;
      }
    }
    this.reserveForm.controls["phoneNumber"].addValidators([Validators.required]);
    this.reserveForm.controls["phoneNumber"].updateValueAndValidity();
  }

  orderDetailsForm() {
    this.orderDetails = true;
    window.scrollTo(0, 0);
    this.getPackageById();
  }

  getPackageById() {
    this.loading = true;
    this.hotelService.getExperiencePackageById(this.packageId).subscribe({
      next: (data) => {
        this.experiencePackage.push(data.object);
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  reserveOrder() {
    if (this.reserveForm.invalid) {
      return;
    }
    this.loading = true;
    this.recaptchaV3Service.execute('reserveOrder').subscribe({
      next: (token) => {
        let obj = {
          "FIRST_NAME": this.reserveForm.value.firstName,
          "LAST_NAME": this.reserveForm.value.lastName,
          "EMAIL": this.reserveForm.value.emailId,
          "COUNTRY": this.reserveForm.value.phoneCode,
          "MOBILE": this.reserveForm.value.phoneNumber,
          "CITY_ID": this.experiencePackage[0].CITY.ID,
          "CATEGORY_ID": this.experiencePackage[0].PACKAGE_CATEGORY.ID,
          "PACKAGE_ID": this.experiencePackage[0].ID,
          "DATE": moment(this.reserveForm.value.date).format('DD-MM-YYYY'),
          "ADULT": this.adultCount,
          "CHILD": this.childCount
        };

        this.hotelService.reserveExperience(obj).subscribe({
          next: (data) => {
            this.loading = false;
            this.orderDetails = false;
            this.bookingConfirm = true;
            window.scrollTo(0, 0);
          },
          error: (e) => {
            this.loading = false;
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.loading = false;
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  increaseAdultCount(event: Event) {
    event.stopPropagation();
    this.adultCount++;
  }

  decreaseAdultCount(event: Event) {
    event.stopPropagation();
    if (this.adultCount > 1) {
      this.adultCount--;
    }
  }

  increaseChildCount(event: Event) {
    event.stopPropagation();
    this.childCount++;
  }

  decreaseChildCount(event: Event) {
    event.stopPropagation();
    if (this.childCount > 0) {
      this.childCount--;
    }
  }

  updateCounts() {
    this.updatedAdultCount = this.adultCount;
    this.updatedChildCount = this.childCount;
    this.totalCount = this.adultCount + this.childCount;
    this.reserveForm.controls['guest'].setValue(`Adult: ${this.updatedAdultCount}, Child: ${this.updatedChildCount}`);
    this.menuTrigger.closeMenu();
  }

  menuClosed() {
    this.adultCount = this.updatedAdultCount;
    this.childCount = this.updatedChildCount;
  }

  backButton() {
    this.router.navigateByUrl('experiences');
    window.scrollTo(0, 0);
  }

  formbackButton() {
    this.orderDetails = false;
    this.bookingConfirm = false;
    window.scrollTo(0, 0);
  }

  doneButton() {
    this.router.navigateByUrl('experiences');
    window.scrollTo(0, 0);
  }

}