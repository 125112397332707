import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../popup/popup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const Calendly: any;

@Component({
  selector: 'app-blog16',
  templateUrl: './blog16.component.html',
  styleUrls: ['./blog16.component.css']
})
export class Blog16Component {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'What are the key pillars of sustainable travel?',
      answer: 'The key pillars of sustainable travel are environmental, social, and economic sustainability. These focus on protecting natural resources, respecting local cultures, and supporting local economies to ensure a balanced, positive impact on destinations.',
      open: false
    },
    {
      question: 'How can travelers practice sustainable travel?',
      answer: 'Travelers can practice sustainable travel by choosing eco-friendly accommodations, supporting local businesses, respecting local customs, reducing waste, and conserving resources like water and energy. Small, thoughtful actions can make a big difference.',
      open: false
    },
    {
      question: 'What are some challenges of sustainable travel?',
      answer: 'Challenges include higher costs for sustainable options, limited eco-friendly infrastructure in some destinations, lack of awareness, and misleading “greenwashing” by businesses. Overcoming these requires both industry commitment and informed travelers.',
      open: false
    },
    {
      question: 'What is the future of sustainable travel?',
      answer: 'The future of sustainable travel looks promising as more travelers seek eco-friendly options and the industry invests in sustainable practices. Innovations in green technology, community-based tourism, and policy changes are likely to make sustainable travel more accessible and impactful.',
      open: false
    }
  ];

  articles = [
    {
      title: 'AI in Hospitality Industry: Transforming Guest Experiences',
      text: 'Imagine you go to the hotel, the room temperature is just as you like it...',
      image: 'assets/images/AI-in-Hospitality Industry-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewAIInHospitalityIndustry()
    },
    {
      title: 'Hotel Room Management System: A Guide to Efficiency',
      text: 'Managing hotel rooms, from housekeeping to guest requests, can be...',
      image: 'assets/images/Hotel Room Management-image.png',
      readTime: '3 Minutes Read',
      categoryId: '3',
      onClick: () => this.viewHotelRoomManagement()
    },
    {
      title: 'Hotel cancellation policy: everything you need to know',
      text: 'However simple it may sound, booking a hotel can be harder than one imagines...',
      image: 'assets/images/cancellation-policy-image.png',
      readTime: '7 Minutes Read',
      onClick: () => this.viewCancellationPolicy()
    },
  ]

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'Introduction-Sustainable',
      'Sustainable-Travel',
      'Key-Concepts-Sustainable-Travel',
      'Role-of-Travelers',
      'Conclusion'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewAIInHospitalityIndustry() {
    this.router.navigateByUrl('blog/ai-in-hospitality-industry');
    window.scrollTo(0, 0);
  }

  viewHotelRoomManagement() {
    this.router.navigateByUrl('blog/hotel-room-management-system');
    window.scrollTo(0, 0);
  }

  viewCancellationPolicy() {
    this.router.navigateByUrl('blog/what-is-a-hotel-cancellation-policy');
    window.scrollTo(0, 0);
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  viewLinkedIn() {
    const linkedInUrl = environment.LINKEDIN_URL;
    const websiteUrl = `${environment.WEBSITE_URL}/blog/what-does-travel-sustainable-mean`;
    const fullUrl = `${linkedInUrl}${websiteUrl}`;
    window.open(fullUrl, "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.isPopupOpen = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (!!data?.object?.ID) {
              const dialogRef = this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
              dialogRef.afterClosed().subscribe(() => {
                this.isPopupOpen = false;
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewIntroduction() {
    this.scrollToSection('Introduction-Sustainable');
  }

  viewSustainableTravel() {
    this.scrollToSection('Sustainable-Travel');
  }

  viewKeyConcepts() {
    this.scrollToSection('Key-Concepts-Sustainable-Travel');
  }

  viewRoleofTravelers() {
    this.scrollToSection('Role-of-Travelers');
  }

  viewConclusion() {
    this.scrollToSection('Conclusion');
  }


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}
