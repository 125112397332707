import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Blog2Component } from 'src/app/pages/blog2/blog2.component';
import { PopupComponent } from 'src/app/pages/popup/popup.component';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { colorObj } from 'src/shared/color-object';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  mobile: boolean = false;
  email = new FormControl('', Validators.pattern(environment.EMAIL_PATTERN));
  disabled: boolean = false;
  blogComponent: Blog2Component;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }


  viewLinkedIn() {
    window.open('https://www.linkedin.com/company/gofriday/', "_blank");
  }

  viewYoutube() {
    window.open('https://www.youtube.com/@GoFriday', "_blank");
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewVision() {
    this.router.navigate(['/about-us'], { fragment: 'Vision' });
  }

  viewBelieveIn() {
    this.router.navigate(['/about-us'], { fragment: 'What-We-Believe-In' });
  }

  viewTeam() {
    this.router.navigate(['/about-us'], { fragment: 'Team' });
  }

  viewPricing() {
    this.router.navigate(['/plans'], { fragment: 'Pricing' });
  }

  viewTalktoSales() {
    this.router.navigate(['/plans'], { fragment: 'Talk-to-Sales' });
  }

  viewPropertyManagementSystem() {
    this.router.navigateByUrl('/products/property-management-system');
    window.scrollTo(0, 0);
  }

  viewCentralizedReservationSystem() {
    this.router.navigateByUrl('/products/centralized-reservation-management');
    window.scrollTo(0, 0);
  }

  viewRevolution() {
    this.router.navigateByUrl('blog/guest-centric-revolution');
    window.scrollTo(0, 0);
  }

  viewAutomatedHotel() {
    this.router.navigateByUrl('blog/automated-hotel');
    window.scrollTo(0, 0);
  }

  viewHotelCRM() {
    this.router.navigateByUrl('blog/what-is-a-hotel-crm');
    window.scrollTo(0, 0);
  }


  subscribe() {
    if (!this.email.value) {
      return;
    }
    this.disabled = true;
    this.hotelService.isHideStickyContent(true);
    this.recaptchaV3Service.execute('subscribe').subscribe({
      next: (token) => {
        let obj = {
          "EMAIL": this.email.value
        };
        this.hotelService.subscribe(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            this.email.setValue('');
            if (!!data?.object?.ID) {
              if (this.mobile == true) {
                const dialogRef = this.dialog.open(PopupComponent, {
                  width: '80%',
                  data: { name: 'subscribe', message: 'We have received your email. Stay tuned!' },
                  disableClose: true
                });
              }
              else {
                const dialogRef = this.dialog.open(PopupComponent, {
                  width: '46%',
                  data: { name: 'subscribe', message: 'We have received your email. Stay tuned!' },
                  disableClose: true
                });
                dialogRef.afterClosed().subscribe(() => {
                  this.hotelService.isHideStickyContent(false);
                });
              }

            } else {
              this.notify.showNotification(
                data.message,
                "top",
                (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
                data.status
              );
            }
          },
          error: (e) => {
            this.disabled = false;
            this.email.setValue('');
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.disabled = false;
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

}