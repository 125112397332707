import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const Calendly: any;

@Component({
  selector: 'app-feedback-management-software',
  templateUrl: './feedback-management-software.component.html',
  styleUrls: ['./feedback-management-software.component.css']
})
export class FeedbackManagementSoftwareComponent {
  @ViewChild('scrollableContainer', { static: false }) scrollableContainer!: ElementRef;
  @ViewChild('scrollableContainerFeedbackManagement', { static: false }) scrollableContainerFeedbackManagement!: ElementRef;
  mobile: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;

  features = [
    {
      title: 'AI-Powered Analytics',
      description: 'Leverage data insights to optimize pricing strategies.',
      image: 'assets/images/AI-Powered-Analytics.png',
      isHovered: false,
    },
    {
      title: 'Customizable Rules',
      description: 'Set parameters to align pricing with your revenue goals.',
      image: 'assets/images/Customizable Rules.png',
      isHovered: false,
    },
    {
      title: 'Market Comparisons',
      description: 'Stay competitive with insights into local market rates.',
      image: 'assets/images/Market Comparisons.png',
      isHovered: false,
    },
    {
      title: 'Automated Adjustments',
      description: 'Implement changes instantly without manual intervention.',
      image: 'assets/images/Automated Adjustments.png',
      isHovered: false,
    },
  ];

  benefits = [
    {
      number: '01',
      title: 'Proactive Issue Resolution',
      description: 'Quickly address operational inefficiencies as they are reported, minimizing impact on guest satisfaction.'
    },
    {
      number: '02',
      title: 'Enhanced Workflow Coordination',
      description: 'Align guest feedback with task assignments, ensuring your teams are always working toward actionable solutions.'
    },
    {
      number: '03',
      title: 'Data-Driven Training',
      description: 'Use guest insights to identify skill gaps and tailor training programs for continuous staff development.'
    },
    {
      number: '04',
      title: 'Improved Process Efficiency',
      description: 'Identify repetitive service issues and implement systemic changes to eliminate them.'
    },
    {
      number: '05',
      title: 'Boosted Team Accountability',
      description: 'Track and measure team performance based on actionable feedback data.'
    }
  ];


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(private router: Router) { }
  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  // onHover(feature: any) {
  //   feature.isHovered = true;
  // }

  // onLeave(feature: any) {
  //   feature.isHovered = false;
  // }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

  bannerScrollRight(): void {
    this.scrollableContainer.nativeElement.scrollTo({
      left: this.scrollableContainer.nativeElement.scrollLeft + 250,
      behavior: 'smooth'
    });
  }

  bannerScrollLeft(): void {
    this.scrollableContainer.nativeElement.scrollTo({
      left: this.scrollableContainer.nativeElement.scrollLeft - 250,
      behavior: 'smooth'
    });
  }

  bannerScrollRight1(): void {
    this.scrollableContainerFeedbackManagement.nativeElement.scrollTo({
      left: this.scrollableContainerFeedbackManagement.nativeElement.scrollLeft + 500,
      behavior: 'smooth'
    });
  }

  bannerScrollLeft1(): void {
    this.scrollableContainerFeedbackManagement.nativeElement.scrollTo({
      left: this.scrollableContainerFeedbackManagement.nativeElement.scrollLeft - 500,
      behavior: 'smooth'
    });
  }

}
