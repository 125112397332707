<app-header></app-header>
<div fxLayout="column" class="blogContainer width100">
    <div fxLayout="row">
        <div class="back-arrow" (click)="backButton()">
            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
        </div>
        <h1 class="blog-header">
            13 Smart Strategies on How to Improve Hotel Revenue
        </h1>
    </div>
    <mat-divider class="material-devider"></mat-divider>
    <div fxLayout="row" class="sub-content" fxLayoutAlign="space-evenly">
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">Janhavi Srivastava</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">13 Aug 2024</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <iconify-icon icon="material-symbols-light:share-outline" class="share-icon"></iconify-icon>
            <a class="blog-desc" style="cursor: pointer;" (click)="viewLinkedIn()">LinkedIn</a>
        </div>
    </div>
</div>
<div class="infoContainer width100" fxLayout="column" fxLayoutAlign=" center">
    <div class="Hicsa-prgm-container" *ngIf="!mobile">
        <img src="assets/images/Hotel Revenue.jpg" class="prgm-image">
    </div>
    <div class="Hicsa-prgm-container" *ngIf="mobile">
        <img src="assets/mobile-images/Hotel Revenue.png">
    </div>
    <div class="sticky-overview" fxFlex="35" [ngClass]="{ 'sticky-overview-active': showStickyOverview }">
        <div class="overview-header">Overview</div>
        <ul class="overview-list">
            <li><span (click)="viewRevenueManagement()" [class.active]="activeSection === 'Revenue-Management'">What Is
                    Revenue Management<br>
                    in Hotels?</span></li>
            <li><span (click)="viewHotelRevenueManagement()"
                    [class.active]="activeSection === 'Hotel-Revenue-Management'">Why Do We Need Hotel Revenue<br>
                    Management?</span></li>
            <li><span (click)="viewHotelRevenueStrategies()"
                    [class.active]="activeSection === 'Hotel-Revenue-Strategies'">13 Strategies to Improve Hotel
                    Revenue</span></li>
            <li><span (click)="viewConclusion()" [class.active]="activeSection === 'Conclusion'">Conclusion</span></li>
        </ul>
        <div fxLayout="column" class="sticky-content" fxLayoutAlign=" center">
            <div>
                <img src="assets/images/Gofryday-logo.png">
            </div>
            <div>
                <p class="sticky-text">Delight your guests <br> with AI-powered <br> experiences</p>
            </div>
            <div>
                <button class="demo-btn" (click)="openCalendlyPopup()">
                    Book a Demo
                </button>
            </div>
        </div>
    </div>
    <div class="right-side-content" fxLayout="column">
        <div fxLayout="column" class="info-content-revolution" fxLayoutGap="2%" fxFlex="65%">
            <div class="info-text">In recent years, the need to maximize hotel revenue has been hotly argued within the
                hospitality sector. With increased costs and changes in the habits of guests, there is a need to have
                smart strategies that will cut above the competition.<br>
                Revenue management plays a crucial role in boosting profitability, ensuring optimal pricing, and
                improving occupancy rates. By implementing a variety of revenue-boosting techniques hotels will be able
                to improve hotel revenue without compromising the quality of the services provided.
            </div>
            <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                <img src="assets/images/hotel-revenue-subimage.png">
            </div>
            <div *ngIf="mobile" style="display: flex;justify-content: center;">
                <img src="assets/mobile-images/hotel-revenue-subimage.png">
            </div>
        </div>
        <div id="Revenue-Management" class="info-content-crm" fxLayout="column" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">What Is Revenue Management in Hotels?</h2>
            <div class="info-text">Revenue management in hotels refers to the practice of using data and analytics to
                optimize room pricing, manage inventory, and maximize revenue. The main objective is to sell the right
                room to the right guest at the right price at the right time.<br>
                This involves a careful balance between room availability, guest demand, pricing strategies, and
                distribution channels.  With new technologies and Revenue Management Systems (RMS), hoteliers can
                analyze historical booking data, market trends, and other crucial factors that ultimately help them
                improve hotel revenue.
            </div>
            <div class="demosection">
                <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                    <div class="demo-text">
                        Delight your guests with AI-powered experiences
                    </div>
                    <div>
                        <button class="book-btn" (click)="openCalendlyPopup()">
                            Book a Demo
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="Hotel-Revenue-Management" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Why Do We Need Hotel Revenue Management?</h2>
            <div class="info-text">The hospitality landscape has become more competitive over the years and especially
                with the introduction of online travel agencies (OTAs), the need for optimizing rates and occupancy has
                become a normal job for hoteliers.
                Revenue management assists hotel operators in taking the right direction in this war zone by making sure
                that they capitalize on every booking that they can secure. The ability to adjust pricing in real-time,
                based on supply and demand, helps hotels remain competitive while avoiding revenue losses.<br><br>
                Additionally, hotel revenue management is dynamic, continuous process. It requires constant analysis and
                adaptation to market trends, guest preferences, and competitor pricing. Even in such a competitive area
                any measurable change can prove beneficial to the overall profit obtained by the hotel in such
                scenarios.
            </div>
        </div>
        <div id="Hotel-Revenue-Strategies" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">13 Strategies to Improve Hotel Revenue</h2>
            <div class="info-text">Here are 13 smart strategies that hoteliers can adopt to improve hotel revenue and
                maintain financial stability.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Dynamic Pricing
                </h3>
                <div class="info-text">Dynamic pricing means adjusting room rates based on real-time fluctuations in
                    demand. This way, it makes it possible to increase revenue during peak season and to remain
                    competitive in the slower seasons. For instance, room rates can be increased when there are events
                    in the area or holiday season. Conversely, they can be lowered during off-peak times to attract more
                    guests.<br><br>
                    To implement dynamic pricing effectively, hotels must purchase an RMS that tracks competitors’
                    prices, market forces, and booking patterns with past data. This data-driven approach makes sure
                    that decisions are made based on real-time and not guesswork. Though revenue through dynamic pricing
                    can be maximized, balance can be important, guests may decide not to book because they feel prices
                    are too high or decide to go for cheaper options eroding profits.
                </div>
 
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Practice Yield Management
                </h3>
                <div class="info-text">Yield management is the alternate strategy that adds value to revenue management
                    in hotels. It is the process of striking a delicate balance between the total number of rooms booked
                    and the price charged to generate maximum revenue. The basic principle in this method is always
                    that, available rooms must be sold at the best offer price. This strategy is best applicable for
                    hotels that operate under conditions of high demand variability such as hotels in resorts or
                    business centres.<br><br>
                    Historical booking analysis, guest behavior, and booking windows or contract timeouts can help in
                    setting a hotel’s yield management strategies which assist in adjusting room charges according to
                    demand conditions. However, Yield Management is not easy it requires continuous monitoring and
                    adjustment, as market conditions can change quickly.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Enhance Online Presence
                </h3>
                <div class="info-text">In the modern era, it is essential to have a strong online presence to attract
                    guests. Search Engine Optimization (SEO) is important to improve hotel websites’ visibility, which
                    brings in more organic traffic. Simply including relevant keywords and working on the site’s loading
                    speed are some easy optimization techniques hotels can use to improve.<br><br>
                    In addition to SEO, hotels should capitalize on the use of social media platforms to promote special
                    offers to the guests. Instagram, Facebook, and Twitter are great tools to market the unique
                    attributes of the hotel to the audience. Further, through these channels, most hotels can increase
                    their bookings by consistently uploading interesting content about the hotels responding to client
                    feedback and interests and running advertisements.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Provide Personalized Services
                </h3>
                <div class="info-text">Guests’ willingness to pay for personalized service is also in demand in the
                    hospitality business. This can be achieved by providing guests with experiences that appeal to their
                    preferences which in turn would make the guests satisfied and willing to return. Hotels can collect
                    data on guest preferences, such as room preferences, meal choices, and past activities, to offer
                    personalized touches during their stay.<br><br>
                    For instance, complimentary breakfast may be included for the returning guests in the hotel or a
                    bottle of wine for a honeymoon couple. These are gestures that make people feel and make them want
                    to come back. Personalization also encourages loyalty which is a revenue builder over time.
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/Personalized-Services.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/Personalized-Services.png">
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">5. Set Loyalty Programs
                </h3>
                <div class="info-text">Loyalty programs are a great way to attract repeat guests. By rewarding their
                    loyal customers, hotels can increase customer retention and drive long-term revenue growth. Loyalty
                    programs can offer perks such as room upgrades, discounts, free services, and exclusive offers to
                    members.<br><br>
                    The main value of the loyalty programs needs to be offered to the guests who hold them. This could
                    include offering tiered rewards, personalized offers based on stay history, or exclusive benefits
                    that enhance the guest experience. A well-thought-out loyalty program can ensure that the hotel will
                    have long-term relationships with guests.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">6. Hotel Management Software
                </h3>
                <div class="info-text">One of the most practical methods of improving the efficiency of hotel operations
                    and growing revenue is to implement hotel management software (HMS). HMS solutions provide a wide
                    range of technologies all in one solution: reservation management, occupancy tracking, payment
                    processing, and staff scheduling. Automating routine tasks improves operational efficiency allowing
                    more resources to be committed to enhancing the guest experience.<br><br>
                    Besides, most of HMS platforms have advanced analytics features that allows hoteliers to track
                    performance, predict demand, and adjust pricing strategies. Such insights allow the hotel business
                    to operate successfully and generate more revenues.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                    <h3 class="container-sub-head">7. Online Booking Platforms
                    </h3>
                    <div class="info-text">It is of great importance that popular online booking engines, like
                        Booking.com, Expedia, and Airbnb, should be used to their full potential to increase the number
                        of bookings. These platforms target diverse audiences, it easier for travelers to discover and
                        book hotels. Listing on OTAs often translates to higher volumes of bookings made.<br><br>
                        Hotels must strike a balance between driving direct bookings through their website and
                        leveraging third-party platforms. For instance, offering promotional prices only for direct
                        online bookings made through the hotel‘s website gets many guests eager by lessening the
                        attachment to OTAs.
                    </div>
                    <div class="demosection">
                        <div class="book-contents1" fxLayout="column" fxLayoutGap="3%">
                            <div class="demo-text">
                                Learn How GoFriday Helps Hotels Deliver Better Service In Real-Time.
                            </div>
                            <div>
                                <button class="book-btn" (click)="openCalendlyPopup()">
                                    Book a Demo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                    <h3 class="container-sub-head">8. Offering Packages and Promotions
                    </h3>
                    <div class="info-text">Tailored packages and promotions can attract a diverse range of guests,
                        including families, couples, and business travelers. For Instance, a hotel may put together
                        weekend getaways, festive activities or reasonable business retreats. Such packages may include
                        attractive offers such as free breakfast, discounted rates on certain spa services or tickets to
                        local attractions.<br><br>
                        Offering promotions during off seasons is a good practice that can help increase the occupancy
                        of rooms that could have otherwise been empty for too long. Packages must be structured so that
                        they do not dilute the brand value of the hotel Instead, the focus should be on creating unique
                        experiences that justify the price.
                    </div>
                </div>
                <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                    <h3 class="container-sub-head">9. Collaborate with Local Businesses
                    </h3>
                    <div class="info-text">Partnering with local businesses can be a source of mutual benefit and
                        advantages for both the hotels and the local communities. Hotels can enhance their guests’
                        experiences by collaborating with local restaurants, tour operators, or entertainment venues
                        that add value to their stay. For instance, a hotel might work together with a winery to enable
                        guests to engage in a wine-tasting experience or with a museum for discounted ticket
                        fares.<br><br>
                        These collaborations not only add value to guests but also enable hotels to stand out from other
                        hotels within the market. Partnerships with local businesses can be useful to advertise and
                        enhance community outreach.
                    </div>
                </div>
                <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                    <h3 class="container-sub-head">10. Analyze and Adjust Strategies
                    </h3>
                    <div class="info-text">One of the key features of revenue management is the constant assessment of
                        the profitability and appropriateness of the changes undertaken. Data analytics tools can help
                        hotels track key metrics such as occupancy rates, average daily rate (ADR), and revenue per
                        available room (RevPAR). Routine analysis of these metrics helps to create corrective measures
                        on pricing, marketing, or business operations.
                        For example, if a hotel notices a decline in bookings during a certain period, it might adjust
                        its pricing strategy, launch a targeted marketing campaign, or offer limited-time promotions to
                        drive demand.
                    </div>
                    <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                        <img src="assets/images/Strategies.png">
                    </div>
                    <div *ngIf="mobile">
                        <img src="assets/mobile-images/Strategies.png">
                    </div>
                </div>
                <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                    <h3 class="container-sub-head">11. Offer Early Check-In and Late Checkout
                    </h3>
                    <div class="info-text">Early check-in and late checkout are simple but effective ways to increase
                        revenue and enhance the guest experience. By offering these services for an additional fee,
                        hotels can accommodate guests with flexible travel schedules while generating extra
                        revenue.<br><br>
                        Travelers tend to appreciate the convenience of early check-in or late checkout facilities,
                        especially for business and leisure trips. The same can help alleviate the unnecessary pressure
                        of timely check-in and check-out, further enhancing the experience of the guests.
                    </div>
                </div>
                <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                    <h3 class="container-sub-head">12. Promote Your Food and Beverage Options
                    </h3>
                    <div class="info-text">Even though hotel patrons love to dine out, in-house dining options stands to
                        improve a customer’s experience more whilst bringing in more revenue to the hotel. Marketing
                        food and beverage options—be it through the hotel’s restaurant, room service or themed
                        dinners—can enhance customer experience and increase revenues.<br><br>
                        Hotels can also organize special events like wine tastings, themed dinners, or cooking classes
                        to attract guests and non-guests alike. These activities generate more income while creating
                        avenues for guests to interact with the hotel beyond their rooms.
                    </div>
                </div>

            </div>
            <h3 class="container-sub-head">13. Make a Mobile-Friendly Website
            </h3>
            <div class="info-text">With the increasing use of smartphones for travel bookings, having a mobile-friendly
                website is important. Having a mobile-friendly website guarantees that potential guests can browse,
                book, and pay for a room on their phones. A significant portion of hotel bookings now comes from mobile
                users, making it essential to have a responsive design that works seamlessly across all devices.<br><br>
                Hotels should also consider mobile-friendly additions like instant booking buttons, live chat support,
                and payment processing systems. The effective usage of the mobile version of the website will result in
                enhanced conversions and an effective guest experience.
            </div>
            <div class="demosection">
                <div class="book-contents1" fxLayout="column" fxLayoutGap="3%">
                    <div class="demo-text">
                        Discover How GoFriday Can Enhance Your Guest Experience Today!
                    </div>
                    <div>
                        <button class="book-btn" (click)="openCalendlyPopup()">
                            Book a Demo
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div id="Conclusion" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Conclusion</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div class="info-text">Improving hotel revenue requires a multifaceted approach that combines smart
                    pricing strategies, personalized services, and data-driven decision-making. From dynamic pricing to
                    loyalty programs, each of the 13 strategies outlined above can help hotels boost profitability while
                    enhancing guest satisfaction.<br><br>
                    By adopting these strategies and continually monitoring their performance, hoteliers can ensure
                    long-term success in a competitive industry. Whether through optimizing online presence,
                    collaborating with local
                </div>
                <div fxLayout="row" fxLayoutGap="3%" class="social-media">
                    <div>
                        <img src="assets/images/whatsapp.png">
                    </div>
                    <div>
                        <img src="assets/images/linkedin.png">
                    </div>
                    <div>
                        <img src="assets/images/email.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div #faqSection class="similar-article width100">
    <div style="padding: 0% 10%;">
        <h2 class="container-head">Similar articles</h2>
    </div>
    <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="card" *ngFor="let article of articles" (click)="article.onClick()">
            <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div class="card-image">
                    <img [src]="article.image" alt="{{ article.title }}">
                </div>
                <div class="card-content">
                    <div class="card-header">{{ article.title }}</div>
                    <div class="card-text">{{ article.text }}</div>
                </div>
                <div style="margin: 0 5% 5% 5%;">
                    <span class="readmore-text">Read More</span>&nbsp;&nbsp;
                    <span class="card-text">({{ article.readTime }})</span>
                </div>
            </div>
        </mat-card>
    </div>
</div>

<div class="faq-container" id="faq-section">
    <h2 class="faq-header">Frequently Asked Questions</h2>
    <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
            {{ faq.question }}
            <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
            {{ faq.answer }}
        </div>
    </div>
</div>
<div class="learn-more-contents" fxLayout="column" fxLayoutGap="2%">
    <div class="learn-more-head">
        Not sure how to get started?
    </div>
    <div class="learn-text">
        Join thousands of hoteliers from around the world already leveraging the power of AI, with GoFriday.
    </div>
    <div>
        <button class="book-btn" (click)="openCalendlyPopup()">
            Book a Demo
        </button>
    </div>
</div>
<div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div>
            <img src="assets/images/contact-us.png">
        </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
        <div class="form-heading">
            Contact Us
        </div>
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                            placeholder="Email" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
        Contact Us
    </div>
    <div>
        <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
        <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                            autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<app-footer></app-footer>