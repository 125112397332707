<app-header></app-header>
<div class="container">
  <div class="guest-app-container width100" fxLayout="row">
    <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
      <div fxLayout="row">
        <button class="online-button">
          <ul class="custom-list">
            <li>Online Check-In and Check-Out Software
            </li>
          </ul>
        </button>
      </div>
      <h2 class="guest-app-child-heading">Redefining Guest Journey: From Online Check-In to Check-Out</h2>
      <div *ngIf="mobile" style="align-items: center;">
        <img class="image" src="/assets/images/Redefining Guest Journey.png" />
      </div>
      <div class="guest-app-child-text">Did you know that 60% of travelers say a seamless online Check-In process
        greatly influences their first impression of a hotel? 
        GoFriday’s Online Check In to Check Out ensures a frictionless, guest-centric journey while enhancing hotel
        operations, with a focus on delighting guests at every step and providing a contactless hotel check-in
        experience. 
      </div>
      <div class="buttons">
        <button class="book-btn" (click)="openCalendlyPopup()">
          Book A Demo Today
        </button>
        <button class="demo-link" (click)="selectTrial()">
          Talk to Us
          <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
        </button>
      </div>
    </div>
    <div class="image-container" *ngIf="!mobile">
      <img class="image" src="/assets/images/Redefining Guest Journey.png" />
    </div>
  </div>
  <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
    <h2 class="guest-app-child-heading2">
      Online Check In: Effortless Arrivals
    </h2>
    <div class="guest-app-child-text2">
      GoFriday’s Online Check In lets guests handle pre-arrival formalities and check in via smartphone, reducing wait
      times and ensuring a a stress-free, welcoming arrival. 
    </div>
    <div fxLayout="column" class="why-choose-content-container">
      <h3 class="guest-app-child-heading2" style="padding-bottom: 5%;">
        Key Benefits of Online Check In
      </h3>
      <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
        <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
          <img class="choose-image" src="/assets/images/Guest Preferences.png" alt="" />
          <h3 class="why-choose-content-head">
            Guest Preferences
          </h3>
          <div class="why-choose-text">Guests select room types, bedding, and amenities ahead of time to ensure their
            stay meets expectations.
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/Document Uploads.png" alt="" />
          <h3 class="why-choose-content-head">
            Document Uploads
          </h3>
          <div class="why-choose-text">Securely upload identification and documents in advance, streamlining the process
            and saving guests time and effort.</div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/Reservation Verification.png" alt="" />
          <h3 class="why-choose-content-head">
            Reservation Verification
          </h3>
          <div class="why-choose-text">Confirm bookings and payments online for added convenience.
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/Digital Room Keys.png" alt="" />
          <h3 class="why-choose-content-head">
            Digital Room Keys
          </h3>
          <div class="why-choose-text">Provide secure, Contactless hotel check-in access to rooms directly from guests’
            devices
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
    <h2 class="guest-app-child-heading2">
      Online Check out: Simplified Departures
    </h2>
    <div class="guest-app-child-text2">
      GoFriday’s Online Check Out enables guests to settle bills, give feedback, and check out seamlessly via their
      smartphones—all at their convenience, without visiting the front desk.
    </div>
  </div>
  <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
    <h3 class="guest-app-child-heading2">
      Key Features of Online Check out
    </h3>
    <div class="knowledge-row" fxLayout="row" fxLayoutAlign="center center">
      <mat-card class="knowledge-card">
        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
          <div class="knowledge-image">
            <img src="assets/images/Digital Payments.png">
          </div>
          <div class="knowledge-content">
            <div class="knowledge-card-header">
              Digital Payments
            </div>
            <div class="knowledge-card-text">
              Enable secure, hassle-free bill settlement from anywhere.
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="knowledge-card">
        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
          <div class="knowledge-image">
            <img src="assets/images/Feedback Collection.png">
          </div>
          <div class="knowledge-content">
            <div class="knowledge-card-header">
              Feedback Collection
            </div>
            <div class="knowledge-card-text">
              Gather guest insights at the moment of Check Out  for actionable improvements.
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="knowledge-card">
        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
          <div class="knowledge-image">
            <img src="assets/images/Automated Invoicing.png">
          </div>
          <div class="knowledge-content">
            <div class="knowledge-card-header">
              Automated Invoicing
            </div>
            <div class="knowledge-card-text">
              Generate and email invoices instantly.
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card class="knowledge-card">
        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
          <div class="knowledge-image">
            <img src="assets/images/Departure Notifications.png">
          </div>
          <div class="knowledge-content">
            <div class="knowledge-card-header">
              Departure Notifications
            </div>
            <div class="knowledge-card-text">
              Ensure a smooth exit with real-time updates to your team.
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
    <div style="background: #F9F9F9;width: 100%;">
      <div class="features-section2" fxLayout="column" *ngIf="!mobile">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="features-image-container-left">
            <img class="features-images" src="assets/images/Guest-Centric Journey.png">
          </div>
          <div fxLayout="column" fxFlex="47%" fxLayoutGap="3%">
            <h2 class="features-heading">
              A Unified, Guest-Centric Journey
            </h2>
            <div class="features-sub-text">
              From arrival to departure, Online Check In to Check Out ensures a seamless, personalized guest experience:
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
              <div class="features-sub-heading">
                Pre-Arrival
              </div>
              <div class="features-sub-text">
                Guests complete preferences, documents, and payment verification before arrival.
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
              <div class="features-sub-heading">
                Check In
              </div>
              <div class="features-sub-text">
                On arrival, they use their smartphones to access their rooms with zero wait time.
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
              <div class="features-sub-heading">
                Stay
              </div>
              <div class="features-sub-text">
                Personalized services and minimal disruptions enhance their overall experience.
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
              <div class="features-sub-heading">
                Check out
              </div>
              <div class="features-sub-text">
                Guests finalize their stay seamlessly, ensuring a lasting positive impression.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
        <div fxLayout="column" fxLayoutAlign="space-between center">
          <div fxLayout="column" fxLayoutGap="3%" style="padding: 4% 5% 3% 5%;">
            <h2 class="features-heading">
              A Unified, Guest-Centric Journey
            </h2>
            <div class="features-sub-text">
              From arrival to departure, Online Check In to Check Out ensures a seamless, personalized guest experience:
            </div>
          </div>
          <div class="features-image-container-left">
            <img class="features-images" src="assets/mobile-images/Guest-Centric Journey.png">
          </div>
          <div fxLayout="column" fxLayoutGap="3%" style="padding: 15% 5% 5% 5%;">
            <div class="features-sub-heading">
              Pre-Arrival
            </div>
            <div class="features-sub-text">
              Guests complete preferences, documents, and payment verification before arrival.
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
            <div class="features-sub-heading">
              Check In
            </div>
            <div class="features-sub-text">
              On arrival, they use their smartphones to access their rooms with zero wait time.
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
            <div class="features-sub-heading">
              Stay
            </div>
            <div class="features-sub-text">
              Personalized services and minimal disruptions enhance their overall experience.
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
            <div class="features-sub-heading">
              Check out
            </div>
            <div class="features-sub-text">
              Guests finalize their stay seamlessly, ensuring a lasting positive impression.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
    <h2 class="guest-app-child-heading2">
      Guest & Staff Benefits
    </h2>
    <div class="benefits-container">
      <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
        <div class="benefit-number">01</div>
        <h3>ENHANCED GUEST EXPERIENCE</h3>
        <p>A smoother, faster process from start to finish.</p>
      </div>
      <mat-divider *ngIf="!mobile"></mat-divider>
      <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
        <div class="benefit-number">02</div>
        <h3>OPERATIONAL EFFICIENCY</h3>
        <p>Reduce manual tasks and front desk workload.</p>
      </div>
      <mat-divider *ngIf="!mobile"></mat-divider>
      <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
        <div class="benefit-number">03</div>
        <h3>DATA ACCURACY</h3>
        <p>Synchronize all guest data with your property management system.</p>
      </div>
    </div>
  </div>

  <div fxLayout="row" class="info-container1 width100" fxLayoutAlign="center center" *ngIf="!mobile">
    <div>
      <div>
        <h2 class="header"> Operational Advantages For Your Hotel </h2>
      </div>
      <div class="info-text">
        <ul class="list-items">
          <li>
            <span class="container-sub-header">Improved Workflow:</span> Free up staff resources and focus on guest
            engagement.
          </li>
          <li>
            <span class="container-sub-header">Scalability:</span> Accommodate growing guest volumes with ease
          </li>
          <li>
            <span class="container-sub-header">Hygiene & Safety:</span> Contactless features promote a secure,
            worry-free environment.
          </li>
        </ul>
      </div>
    </div>
    <div class="contact-image" fxFlex="40%">
      <img src="assets/images/Advantages of Hotel.png">
    </div>
  </div>
  <div fxLayout="column" class="info-container1 width100" fxLayoutAlign="center center" *ngIf="mobile">
    <div>
      <div>
        <h2 class="header"> Operational Advantages For Your Hotel </h2>
      </div>
      <div class="contact-image" style="text-align:center;">
        <img src="assets/mobile-images/Advantages of Hotel.png">
      </div>
      <div class="info-text">
        <ul class="list-items">
          <li>
            <span class="container-sub-header">Improved Workflow:</span> Free up staff resources and focus on guest
            engagement.
          </li>
          <li>
            <span class="container-sub-header">Scalability:</span> Accommodate growing guest volumes with ease
          </li>
          <li>
            <span class="container-sub-header">Hygiene & Safety:</span> Contactless features promote a secure,
            worry-free environment.
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="!mobile">
    <div class="contact-image" fxFlex="50%">
      <img src="assets/images/Guest Satisfaction-Products.png">
    </div>
    <div fxFlex="50%">
      <div>
        <h2 class="header"> Redefine Guest Satisfaction with GoFriday </h2>
      </div>
      <div class="info-text">
        GoFriday’s Online Check-In to Check-Out redefines the hospitality experience by streamlining the hotel check-in
        process and ensuring every interaction with your hotel is smooth, efficient, and memorable.
      </div>
      <div style="margin-top: 3%;">
        <button class="book-btn" (click)="openCalendlyPopup()">
          Book A Demo Today
        </button>
      </div>
    </div>
  </div>

  <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
    <div>
      <div>
        <h2 class="header"> Redefine Guest Satisfaction with GoFriday </h2>
      </div>
      <div class="contact-image" *ngIf="mobile" style="text-align: center;">
        <img src="assets/mobile-images/Guest Satisfaction-Products.png">
      </div>
      <div class="info-text">
        GoFriday’s Online Check-In to Check-Out redefines the hospitality experience by streamlining the hotel check-in
        process and ensuring every interaction with your hotel is smooth, efficient, and memorable.
      </div>
      <div style="margin-top: 3%;text-align: center;">
        <button class="book-btn" (click)="openCalendlyPopup()">
          Book A Demo Today
        </button>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>