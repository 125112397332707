<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Centralized Reservation System
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Centralized Reservation System for Streamlined Hotel Operations
            </h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Centralized Reservation System.png" />
            </div>
            <div class="guest-app-child-text">Managing reservations, room distribution, and dynamic rates across
                channels is effortless with GoFriday’s Centralized Reservation System (CRS). This all-in-one platform
                streamlines operations, boosts revenue, and elevates guest experiences by centralizing reservation
                management and integrating channel connectivity.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Centralized Reservation System.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            Centralized Reservation Management: Simplify Every Booking
        </h2>
        <div class="guest-app-child-text2">
            Our CRS brings all your reservation data into one easy-to-manage system, enabling seamless handling of
            bookings from various sources, ensuring a smooth and reliable booking process for your guests.
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <h3 class="guest-app-child-heading2" style="padding-bottom: 5%;">
                Key Features of In-Room Hotel Services
            </h3>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Performance Insights.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Unified Reservation Dashboard
                    </h3>
                    <div class="why-choose-text">Access all reservations, whether from your website, OTAs, or direct
                        bookings, in one place.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Task Assignments.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Real-Time Updates
                    </h3>
                    <div class="why-choose-text">Ensure instant synchronization of bookings across systems to prevent
                        double bookings.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Digital Menus.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Custom Booking Rules
                    </h3>
                    <div class="why-choose-text">Set policies for cancellations, minimum stays, or peak season
                        requirements.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Guest Preferences.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Integrated Guest Profiles
                    </h3>
                    <div class="why-choose-text">Leverage guest history and preferences to personalize their experience.
                    </div>
                </div>
            </div>
        </div>
        <h2 class="guest-app-child-heading2">
            Benefits
        </h2>
        <div class="benefits-container">
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">01</div>
                <h3>Improved Efficiency</h3>
                <p>Minimize manual entry errors with automated updates.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">02</div>
                <h3>Better Guest Experience</h3>
                <p>Offer guests faster responses and tailored services.
                </p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">03</div>
                <h3>Streamlined Operations</h3>
                <p>Save time by managing all reservations from one interface.</p>
            </div>
        </div>
    </div>

    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            Distribution Channel Integration: Expand Your Reach and Guest Access
        </h2>
        <div class="guest-app-child-text2">
            Maximize your property’s visibility with seamless integration across multiple distribution channels.
            GoFriday’s CRS ensures your rooms are listed accurately and consistently, no matter the platform.
        </div>

        <div class="dynamic-pricing-section">
            <h3 class="guest-app-child-heading2">Key Features</h3>
            <div class="card-container">
                <mat-card *ngFor="let feature of features" class="feature-card" (mouseenter)="feature.isHovered = true"
                    (mouseleave)="feature.isHovered = false">
                    <div class="card-content">
                        <div class="default-content">
                            <span>{{ feature.title }}</span>
                        </div>

                        <div class="hover-content" [ngClass]="{ 'show-hover-content': feature.isHovered }">
                            <div class="hover-image">
                                <img [src]="feature.image" alt="Feature Icon" />
                            </div>
                            <p>{{ feature.description }}</p>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="guest-app-child-heading2">
            Benefits
        </h3>
        <div class="knowledge-row" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Increased Revenue.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Increased Bookings
                        </div>
                        <div class="knowledge-card-text">
                            Capture more bookings by being visible across diverse platforms.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Digital Payments.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Consistent Pricing
                        </div>
                        <div class="knowledge-card-text">
                            Avoid rate discrepancies with synchronized updates.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Competitive Edge.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Market Penetration
                        </div>
                        <div class="knowledge-card-text">
                            Reach untapped customer segments effortlessly.
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            Rate & Inventory Management: Optimize Revenue, Simplify Control
        </h2>
        <div class="guest-app-child-text2">
            Dynamic and efficient management of rates and inventory is crucial for maximizing profitability.
            GoFriday’s CRS offers powerful tools to help you stay competitive.
        </div>
    </div>
    <div class="info-container5 width100" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="guest-app-child-heading2">
            Key Features
        </h3>
        <div class="knowledge-row1" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Pricing Engine.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Dynamic Pricing Engine
                        </div>
                        <div class="knowledge-card-text">
                            Adjust room rates based on demand, market trends, and occupancy levels.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Inventory Allocation.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Inventory Allocation
                        </div>
                        <div class="knowledge-card-text">
                            Distribute room inventory strategically across channels to avoid overbooking or
                            underselling.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Real-Time-Analytics.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Real-Time Analytics
                        </div>
                        <div class="knowledge-card-text">
                            Use actionable insights to refine your pricing and inventory strategies.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Revenue Optimization.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Revenue Optimization
                        </div>
                        <div class="knowledge-card-text">
                            Set rules to ensure competitive pricing during high and low seasons.
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>

        <h3 class="guest-app-child-heading2">
            Benefits
        </h3>
        <div class="benefits-row" fxLayout="row" fxLayoutAlign="center center">
            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Higher Revenue.png" alt="Streamlined Operations">
                </div>
                <mat-card class="benefits-card1">
                    <div class="knowledge-card-header">Higher Revenue</div>
                    <div class="knowledge-card-text">
                        Capitalize on demand-driven pricing.
                    </div>
                </mat-card>
            </div>

            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Efficient Management.png" alt="Personalized Service">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Efficient Management</div>
                    <div class="knowledge-card-text">
                        Simplify inventory control and prevent overbookings.
                    </div>
                </mat-card>
            </div>

            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Strategic Growth.png" alt="Staff Productivity">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Strategic Growth</div>
                    <div class="knowledge-card-text">
                        Use data-driven insights to enhance your market presence.
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center"
        style="background: #F9F9F9;width: 100%;padding: 10% 10% 3% 10%" *ngIf="!mobile">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2%">
            <h2 class="guest-app-child-heading2">
                Why GoFriday’s Centralized Reservation System?
            </h2>
            <div class="guest-app-child-text2">
                Our CRS is built to make centralized reservation management smarter, faster, and more intuitive. By
                automating processes, integrating seamlessly with distribution channels, and offering data-driven rate
                and inventory management, it delivers unmatched efficiency for your hotel operations.
            </div>
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center"
        style="background: #F9F9F9;width: 100%;padding: 2%" *ngIf="mobile">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4%">
            <h2 class="guest-app-child-heading2">
                Why GoFriday’s Centralized Reservation System?
            </h2>
            <div class="guest-app-child-text2">
                Our CRS is built to make centralized reservation management smarter, faster, and more intuitive. By
                automating processes, integrating seamlessly with distribution channels, and offering data-driven rate
                and inventory management, it delivers unmatched efficiency for your hotel operations.
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="background: #F9F9F9;width: 100%;">
            <div class="features-section1 width100" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxFlex="33%" fxLayoutGap="3%">
                        <h2 class="features-heading">
                            Operational Advantages
                        </h2>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Centralized Control
                            </div>
                            <div class="features-sub-text">
                                Manage reservations, rates, and inventory from a single platform.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Real-Time Sync
                            </div>
                            <div class="features-sub-text">
                                Eliminate delays and inconsistencies across channels.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Scalable Solutions
                            </div>
                            <div class="features-sub-text">
                                Perfect for independent hotels and large chains alike.
                            </div>
                        </div>
                    </div>
                    <div class="features-image-container-right">
                        <img class="features-images2" src="assets/images/Operational Advantages1.png" alt="Guest App">
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" style="padding: 12% 5% 3% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Operational Advantages1.png">
                        </div>
                        <h2 class="features-heading" style="padding-top: 40%;">
                            Operational Advantages
                        </h2>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 5% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Centralized Control
                        </div>
                        <div class="features-sub-text">
                            Manage reservations, rates, and inventory from a single platform.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Real-Time Sync
                        </div>
                        <div class="features-sub-text">
                            Eliminate delays and inconsistencies across channels.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Scalable Solutions
                        </div>
                        <div class="features-sub-text">
                            Perfect for independent hotels and large chains alike.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="background: #F9F9F9;width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Guest-Centric Benefits.png">
                    </div>
                    <div fxLayout="column" fxFlex="36%" fxLayoutGap="3%">
                        <h2 class="features-heading">
                            Guest-Centric Benefits
                        </h2>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Enhanced Booking Experience
                            </div>
                            <div class="features-sub-text">
                                Faster responses and accurate availability for guests.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Consistency Across Channels
                            </div>
                            <div class="features-sub-text">
                                Ensure guests see the same information no matter where they book.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" style="padding: 12% 5% 3% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Guest-Centric Benefits.png">
                        </div>
                        <h2 class="features-heading" style="padding-top: 40%;">
                            Guest-Centric Benefits
                        </h2>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 5% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Enhanced Booking Experience
                        </div>
                        <div class="features-sub-text">
                            Faster responses and accurate availability for guests.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Consistency Across Channels
                        </div>
                        <div class="features-sub-text">
                            Ensure guests see the same information no matter where they book.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img src="assets/images/Centralized Reservation.png">
        </div>
        <div fxFlex="45%">
            <div>
                <h2 class="header">Revolutionize Your Reservation Management with GoFriday’s Centralized Reservation
                    System</h2>
            </div>
            <div class="info-text">
                Elevate your operational efficiency and guest experience by adopting GoFriday’s CRS.
                Centralized. Integrated. Automated—this is the future of reservation management.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Revolutionize Your Reservation Management with GoFriday’s Centralized Reservation
                    System</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/Centralized Reservation.png">
            </div>
            <div class="info-text">
                Elevate your operational efficiency and guest experience by adopting GoFriday’s CRS.
                Centralized. Integrated. Automated—this is the future of reservation management.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>