<app-header></app-header>
<div fxLayout="column" class="blogContainer width100">
    <div fxLayout="row">
        <div class="back-arrow" (click)="backButton()">
            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
        </div>
        <h1 class="blog-header">
            What is a Hotel Profit Margin?<br>
            Strategies to Boost in 2024
        </h1>
    </div>
    <mat-divider class="material-devider"></mat-divider>
    <div fxLayout="row" class="sub-content" fxLayoutAlign="space-evenly">
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">Janhavi Srivastava</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">24 Aug 2024</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <iconify-icon icon="material-symbols-light:share-outline" class="share-icon"></iconify-icon>
            <a class="blog-desc" style="cursor: pointer;" (click)="viewLinkedIn()">LinkedIn</a>
        </div>
    </div>
</div>
<div class="infoContainer width100" fxLayout="column" fxLayoutAlign=" center">
    <div class="Hicsa-prgm-container" *ngIf="!mobile">
        <img src="assets/images/Hotel Profit Margin.jpg" class="prgm-image">
    </div>
    <div class="Hicsa-prgm-container" *ngIf="mobile">
        <img src="assets/mobile-images/Hotel Profit Margin.png">
    </div>
    <div class="sticky-overview" fxFlex="35" [ngClass]="{ 'sticky-overview-active': showStickyOverview }">
        <div class="overview-header">Overview</div>
        <ul class="overview-list">
            <li><span (click)="viewHotelProfitMargin()" [class.active]="activeSection === 'Hotel-Profit-Margin'">What is
                    a Hotel’s Profit Margin?</span></li>
            <li><span (click)="viewHotelCalculatedProfitMargin()"
                    [class.active]="activeSection === 'Hotel-Profit-Margin-Calculated'">How is the Profit Margin
                    Calculated?</span></li>
            <li><span (click)="viewGoodNetProfitMargin()"
                    [class.active]="activeSection === 'Hotel-Good-Net-Profit-Margin'">What is a
                    Good Net Profit Margin <br> for a Hotel?</span></li>
            <li><span (click)="viewFactorsAffecting()"
                    [class.active]="activeSection === 'Hotel-Factors-Affecting-Profit-Margin'">Factors Affecting Hotel
                    Profit
                    Margins</span></li>
            <li><span (click)="viewBoostHotelProfitMargins()"
                    [class.active]="activeSection === 'Boost-Hotel-Profit-Margins'">Strategies to Boost Hotel Profit
                    <br>Margins in 2024</span></li>
            <li><span (click)="viewConclusion()" [class.active]="activeSection === 'Conclusion'">Conclusion</span></li>
        </ul>
        <div fxLayout="column" class="sticky-content" fxLayoutAlign=" center">
            <div>
                <img src="assets/images/Gofryday-logo.png">
            </div>
            <div>
                <p class="sticky-text">Delight your guests <br> with AI-powered <br> experiences</p>
            </div>
            <div>
                <button class="demo-btn" (click)="openCalendlyPopup()">
                    Book a Demo
                </button>
            </div>
        </div>
    </div>
    <div class="right-side-content" fxLayout="column">
        <div fxLayout="column" class="info-content-revolution" fxLayoutGap="2%" fxFlex="65%">
            <div class="info-text">Operating a hotel in this age is no piece of cake, is it? Everyone would agree that
                with great rivalry and unending evolution, it is crucial more than ever to watch your books. Hotel
                profitability can be compared to the compass, showing the extent of profitability of your hotel. <br>
                Knowing what the profit margin is, cannot be the end of it, as it requires proper decision-making skills
                that assist in nurturing the growth of a hotel. Whether increasing income, lowering expenses, or
                building a more accurate understanding of the comprehensive results, this manual provides all who
                prioritize efficiency with the instruments necessary for achieving it.
            </div>
        </div>
        <div id="Hotel-Profit-Margin" class="info-content-crm" fxLayout="column" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">What is a Hotel’s Profit Margin?</h2>
            <div class="info-text">Hotel profit margins are not just a figure; they show the percentage of money left
                after paying all operating costs. This number gives a quick view of how well a hotel is doing
                financially and how well it controls its expenses compared to its income. A higher profit margin means
                the hotel is doing better in its operations. Therefore, it's an important number for hotel owners and
                managers to keep an eye on.
                <br><br>
                In the hospitality industry, profit margins can fluctuate significantly depending on various factors,
                such as the hotel's type, location, and prevailing market conditions. For instance, luxury hotels often
                have higher operating costs, which can impact their profit margins differently compared to budget or
                mid-range hotels.
            </div>
            <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                <img src="assets/images/Hotel-Profit-Margin.png">
            </div>
            <div *ngIf="mobile" style="display: flex;justify-content: center;">
                <img src="assets/mobile-images/Hotel-Profit-Margin.png">
            </div>
        </div>
        <div id="Hotel-Profit-Margin-Calculated" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">How is the Profit Margin Calculated?</h2>
            <div class="info-text">The formula for calculating gross operating profit (GOP) for a given period, such as
                a month or year, is as follows:<br>
                Gross Operating Profit (GOP) = Total Revenue – Total Operating Costs<br>
                And here’s the formula for calculating the gross margin:<br>
                Gross Profit Margin = (Gross Operating Profit / Total Revenue) x 100<br>
                For example, if a hotel takes in $125,000 in revenue in November and has $92,500 in expenses, its gross
                operating profit for the month is $32,500, and its gross profit margin is 26%.<br>
                Effective hotel revenue management could help increase these figures by optimizing pricing and reducing
                unnecessary expenses.
            </div>
            <div class="demosection">
                <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                    <div class="demo-text">
                        Delight your guests with AI-powered experiences
                    </div>
                    <div>
                        <button class="book-btn" (click)="openCalendlyPopup()">
                            Book a Demo
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div id="Hotel-Good-Net-Profit-Margin" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">What is a Good Net Profit Margin for a Hotel?</h2>
            <div class="info-text">A satisfactory net profit margin for hoteliers is usually between 10% to 20%.
                Nevertheless, different factors influence such operating margins such as location, size and other
                available options as well the prevailing conditions in the market.
                Here’s a breakdown of average net profit margins across different hotel segments, which can guide
                strategies to increase hotel profits:
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Luxury Hotels
                </h3>
                <div class="info-text">Luxury hotels, known for their premium services and amenities, often operate with
                    higher costs. The average net profit margin for luxury hotels typically ranges between 10% to 15%.
                    While these margins might seem lower, the higher revenue generated from high room rates and
                    additional services often compensates for the lower percentage.
                </div>
                
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Mid-Range Hotels
                </h3>
                <div class="info-text">Mid-range hotels are those that provide some measure of comfort that is however
                    not too expensive and here the average profit margins are between 20%- 25%. They often are in the
                    right area, with a good amount of business and leisure travelers, with families who want favorable
                    pricing.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Budget Hotels
                </h3>
                <div class="info-text">Budget hotels, with their focus on providing basic amenities at low costs, can
                    achieve net profit margins between 25% to 35%. Their low operating costs and high occupancy rates
                    drive these higher margins, making it easier to increase hotel profits.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Boutique Hotels
                </h3>
                <div class="info-text">Boutique hotels, known for their unique style and personalized service, often see
                    net profit margins ranging from 15% to 20%. Their specialized offerings and niche market appeal can
                    create loyal customer bases, driving steady revenue.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">5. Resort Hotels
                </h3>
                <div class="info-text">Resort hotels, which offer a range of activities and experiences, tend to have
                    varied profit margins depending on location and amenities. On average, resort hotels can expect net
                    profit margins between 15% to 20%.
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/Resort Hotels.png">
                </div>
                <div *ngIf="mobile" style="display: flex;justify-content: center;">
                    <img src="assets/mobile-images/Resort Hotels.png">
                </div>
            </div>
        </div>

        <div id="Hotel-Factors-Affecting-Profit-Margin" fxLayout="column" class="info-content1" fxLayoutGap="2%"
            fxFlex="65%">
            <h2 class="container-head">Factors Affecting Hotel Profit Margins</h2>
            <div class="info-text">Several factors influence a hotel’s profit margin, and understanding these can help
                in optimizing profitability:
            </div>
            <!-- <div class="guest-experience" fxLayoutGap="2%" fxLayout="column"> -->
            <!-- <h3 class="container-sub-head">1. Improved Guest Experience
                </h3> -->
            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head">1. Room Occupancy Rates:
                    &nbsp;</span>The higher the occupancy ratios, the higher the revenue, provided the hotels are
                managed well. Another factor that is critical to track together with occupancy is the Revenue per
                available room (RevPAR), a key metric in improving hotel margins.
            </div>
            <!-- </div> -->
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head">1. Room Occupancy Rates
                </h3>
                <div class="info-text">The higher the occupancy ratios, the higher the revenue, provided the hotels are
                    managed well. Another factor that is critical to track together with occupancy is the Revenue per
                    available room (RevPAR), a key metric in improving hotel margins.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head">2. Operating Expenses:
                    &nbsp;</span>Costs such as labor, utilities, and maintenance can significantly
                impact profit margins. Due care must be taken when handling such cost in order to ensure that the profit
                margin is increased.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head">2. Operating Expenses
                </h3>
                <div class="info-text">Costs such as labor, utilities, and maintenance can significantly impact profit
                    margins. Due care must be taken when handling such cost in order to ensure that the profit margin is
                    increased.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head">3. Average Daily Rate (ADR):
                    &nbsp;</span>Setting the right room rate is essential. Charging too low can reduce
                profitability, while setting rates too high can lead to lower occupancy.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head">3. Average Daily Rate (ADR)
                </h3>
                <div class="info-text">Setting the right room rate is essential. Charging too low can reduce
                    profitability, while setting rates too high can lead to lower occupancy.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head">4. Seasonality: &nbsp;</span>There
                are seasons
                during which there is more business and others with less. Proper pricing
                and an attractive selling package can reduce some of the damage caused by off-peak periods.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head">4. Seasonality
                </h3>
                <div class="info-text">There are seasons during which there is more business and others with less.
                    Proper
                    pricing and an attractive selling package can reduce some of the damage caused by off-peak periods.
                </div>
            </div>

            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="!mobile">
                <div class="info-text"><span class="container-sub-head">5. Gross Operating Profit Per
                        Available Room (GOPPAR):
                        &nbsp;</span>This metric gives a more comprehensive view of
                    profitability by accounting for both revenue and operating costs. Monitoring GOPPAR helps in making
                    informed financial decisions.
                </div>
                <div class="demosection">
                    <div class="book-contents1" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Learn How GoFriday Helps Hotels Deliver Better Service In Real-Time.
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head">5. Gross Operating Profit Per Available Room (GOPPAR)
                </h3>
                <div class="info-text">This metric gives a more comprehensive view of
                    profitability by accounting for both revenue and operating costs. Monitoring GOPPAR helps in making
                    informed financial decisions.
                </div>
                <div class="demosection">
                    <div class="book-contents1" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Learn How GoFriday Helps Hotels Deliver Better Service In Real-Time.
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div id="Boost-Hotel-Profit-Margins" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Strategies to Boost Hotel Profit Margins in 2024</h2>
            <div class="info-text">With the competitive landscape of the hotel industry, here are some strategies to
                boost your hotel’s profit margins in 2024:
            </div>
            <!-- <div class="guest-experience" fxLayoutGap="2%" fxLayout="column"> -->
            <!-- <h3 class="container-sub-head">1. Improved Guest Experience
                </h3> -->
            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head">1. Optimize Revenue Management:
                    &nbsp;</span>Utilize dynamic pricing strategies to adjust room rates based on demand, seasonality,
                and competition. Regularly reviewing and adjusting your pricing strategy, with a focus on improving
                hotel margins, can lead to significant increases in revenue.
            </div>
            <!-- </div> -->
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head">1. Optimize Revenue Management
                </h3>
                <div class="info-text">Utilize dynamic pricing strategies to adjust room rates based on demand,
                    seasonality,
                    and competition. Regularly reviewing and adjusting your pricing strategy, with a focus on improving
                    hotel margins, can lead to significant increases in revenue.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head">2. Enhance Guest Experience:
                    &nbsp;</span>Focus on improving guest satisfaction to encourage repeat business and positive
                reviews. Personalized services, loyalty programs, and seamless check-in/check-out processes can make a
                big difference.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head">2. Enhance Guest Experience
                </h3>
                <div class="info-text">Focus on improving guest satisfaction to encourage repeat business and positive
                    reviews. Personalized services, loyalty programs, and seamless check-in/check-out processes can make
                    a big difference.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head">3. Control Operating Costs:
                    &nbsp;</span>Implement cost-saving measures such as energy-efficient lighting, automated HVAC
                systems, and water-saving initiatives. Regular audits of expenses can help identify areas for further
                savings.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head">3. Control Operating Costs
                </h3>
                <div class="info-text">Implement cost-saving measures such as energy-efficient lighting, automated HVAC
                    systems, and water-saving initiatives. Regular audits of expenses can help identify areas for
                    further savings.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head">4. Increase Ancillary Revenue:
                    &nbsp;</span>Explore additional revenue streams such as spa services, event hosting, and food and
                beverage packages. Offering package deals and promoting in-house services can boost overall revenue.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head">4. Increase Ancillary Revenue
                </h3>
                <div class="info-text">Explore additional revenue streams such as spa services, event hosting, and food
                    and beverage packages. Offering package deals and promoting in-house services can boost overall
                    revenue.
                </div>
            </div>

            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="!mobile">
                <div class="info-text"><span class="container-sub-head">5. Leverage Technology:
                        &nbsp;</span>Tools like GoFriday’s hospitality management software can streamline operations,
                    improve task assignment, and provide real-time updates, ultimately enhancing efficiency and reducing
                    costs. By automating routine tasks and centralizing operations, hotels can focus more on delivering
                    exceptional guest experiences, which in turn boosts profitability.
                </div>
                <div style="display: flex;justify-content: center;">
                    <img src="assets/images/Leverage Technology.png">
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head">5. Leverage Technology
                </h3>
                <div class="info-text">Tools like GoFriday’s hospitality management software can streamline operations,
                    improve task assignment, and provide real-time updates, ultimately enhancing efficiency and reducing
                    costs. By automating routine tasks and centralizing operations, hotels can focus more on delivering
                    exceptional guest experiences, which in turn boosts profitability.
                </div>
                <div style="display: flex;justify-content: center;">
                    <img src="assets/mobile-images/Leverage Technology.png">
                </div>
            </div>
        </div>

        <div id="Conclusion" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Conclusion</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div class="info-text">Hotel profit margins are a critical measure of financial performance and
                    long-term viability. By understanding how they are calculated and what influences them, hoteliers
                    can implement strategies to enhance profitability. From optimizing revenue management to leveraging
                    innovative tools like GoFriday, here are numerous ways to ensure you're improving hotel margins in
                    2024.
                </div>
                <div fxLayout="row" fxLayoutGap="3%" class="social-media">
                    <div>
                        <img src="assets/images/whatsapp.png">
                    </div>
                    <div>
                        <img src="assets/images/linkedin.png">
                    </div>
                    <div>
                        <img src="assets/images/email.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div #faqSection class="similar-article width100">
    <div style="padding: 0% 10%;">
        <h2 class="container-head">Similar articles</h2>
    </div>
    <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="card" *ngFor="let article of articles" (click)="article.onClick()">
            <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div class="card-image">
                    <img [src]="article.image" alt="{{ article.title }}">
                </div>
                <div class="card-content">
                    <div class="card-header">{{ article.title }}</div>
                    <div class="card-text">{{ article.text }}</div>
                </div>
                <div style="margin: 0 5% 5% 5%;">
                    <span class="readmore-text">Read More</span>&nbsp;&nbsp;
                    <span class="card-text">({{ article.readTime }})</span>
                </div>
            </div>
        </mat-card>
    </div>
</div>


<div class="faq-container" id="faq-section">
    <h2 class="faq-header">Frequently Asked Questions</h2>
    <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
            {{ faq.question }}
            <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
            {{ faq.answer }}
        </div>
    </div>
</div>
<div class="learn-more-contents" fxLayout="column" fxLayoutGap="2%">
    <div class="learn-more-head">
        Not sure how to get started?
    </div>
    <div class="learn-text">
        Join thousands of hoteliers from around the world already leveraging the power of AI, with GoFriday.
    </div>
    <div>
        <button class="book-btn" (click)="openCalendlyPopup()">
            Book a Demo
        </button>
    </div>
</div>
<div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div>
            <img src="assets/images/contact-us.png">
        </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
        <div class="form-heading">
            Contact Us
        </div>
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                            placeholder="Email" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
        Contact Us
    </div>
    <div>
        <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
        <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                            autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<app-footer></app-footer>