<app-header></app-header>
<div class="container">
  <div class="guest-app-container width100" fxLayout="row">
    <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
      <div fxLayout="row">
        <button class="online-button">
          <ul class="custom-list">
            <li>In-room hotel services Softwaree
            </li>
          </ul>
        </button>
      </div>
      <h2 class="guest-app-child-heading">Redefining guests’ Comfort with In-room hotel service</h2>
      <div *ngIf="mobile" style="align-items: center;">
        <img class="image" src="/assets/images/In-room hotel service.png" />
      </div>
      <div class="guest-app-child-text">GoFriday’s In-Room Hotel Services leverage smart hotel technology to provide
        guests with convenient, personalized access to hotel amenities. From ordering room service to requesting extras,
        guests enjoy comfort and control, while hotels streamline operations.
      </div>
      <div class="buttons">
        <button class="book-btn" (click)="openCalendlyPopup()">
          Book A Demo Today
        </button>
        <button class="demo-link" (click)="selectTrial()">
          Talk to Us
          <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
        </button>
      </div>
    </div>
    <div class="image-container" *ngIf="!mobile">
      <img class="image" src="/assets/images/In-room hotel service.png" />
    </div>
  </div>
  <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
    <h2 class="guest-app-child-heading2">
      What Are In-Room Hotel Services by GoFriday?
    </h2>
    <div class="guest-app-child-text2">
      GoFriday’s In-Room Services integrate with the Guest App, allowing your guests to effortlessly manage requests
      directly from their smartphones or in-room tablets. This smart hotel technology ensures a seamless, guest-focused
      service experience and enhances in-room satisfaction.
    </div>
    <div fxLayout="column" class="why-choose-content-container">
      <h3 class="guest-app-child-heading2" style="padding-bottom: 5%;">
        Key Features of In-Room Hotel Services
      </h3>
      <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
        <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
          <img class="choose-image" src="/assets/images/One-Tap Service Requests.png" alt="" />
          <h3 class="why-choose-content-head">
            One-Tap Service Requests
          </h3>
          <div class="why-choose-text">Guests can request housekeeping, laundry, additional toiletries, or other
            amenities with a single tap.
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/Room Service Ordering.png" alt="" />
          <h3 class="why-choose-content-head">
            Guest-Friendly Room Service Ordering
          </h3>
          <div class="why-choose-text">Simplify dining with a digital menu that allows guests to browse, customize, and
            place orders directly from their own device, at their own pace.</div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/Smart Notifications.png" alt="" />
          <h3 class="why-choose-content-head">
            Smart Notifications
          </h3>
          <div class="why-choose-text">Keep guests informed with real-time updates on their requests, ensuring they know
            exactly when their service will be delivered.
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/Recommendations for Guests.png" alt="" />
          <h3 class="why-choose-content-head">
            Personalized Recommendations for Guests
          </h3>
          <div class="why-choose-text">Upsell premium services, spa treatments, or dining offers based on guest
            preferences, right from the app.
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/Multilingual Guest Support.png" alt="" />
          <h3 class="why-choose-content-head">
            Multilingual Guest Support
          </h3>
          <div class="why-choose-text">Break language barriers with options for guests to request services in their
            preferred language.
          </div>
        </div>
      </div>
    </div>
    <h2 class="guest-app-child-heading2">
      Why Choose In-Room Hotel Services?
    </h2>
    <div class="benefits-container">
      <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
        <div class="benefit-number">01</div>
        <h3>Enhanced Guest Satisfaction</h3>
        <p>Meet guest needs faster and more efficiently, making every stay unforgettable.</p>
      </div>
      <mat-divider *ngIf="!mobile"></mat-divider>
      <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
        <div class="benefit-number">02</div>
        <h3>Streamlined Operations</h3>
        <p>Reduce the back-and-forth between staff and guests, ensuring requests are fulfilled quickly and accurately.
        </p>
      </div>
      <mat-divider *ngIf="!mobile"></mat-divider>
      <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
        <div class="benefit-number">03</div>
        <h3>Upselling Opportunities</h3>
        <p>Turn service requests into revenue opportunities with targeted recommendations for upgrades or additional
          services.</p>
      </div>
      <mat-divider *ngIf="!mobile"></mat-divider>
      <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
        <div class="benefit-number">04</div>
        <h3>Real-Time Insights</h3>
        <p>Use the GoFriday dashboard to monitor guest requests and ensure timely completion.</p>
      </div>
    </div>
  </div>
  <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
    <div style="background: #F9F9F9;width: 100%;">
      <div class="features-section2" fxLayout="column" *ngIf="!mobile">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5%">
          <div class="features-image-container-left">
            <img class="features-images" src="assets/images/Guest Experience Flow.png">
          </div>
          <div fxLayout="column" fxFlex="44%" fxLayoutGap="3%">
            <h2 class="features-heading">
              The Guest Experience Flow
            </h2>
            <div fxLayout="column" fxLayoutGap="3%">
              <div class="features-sub-heading">
                Easy Access
              </div>
              <div class="features-sub-text">
                Guests open the GoFriday Guest App or use the in-room tablet to explore available services.
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
              <div class="features-sub-heading">
                Seamless Requests
              </div>
              <div class="features-sub-text">
                With just a few taps, they place their requests or orders.
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
              <div class="features-sub-heading">
                Real-Time Updates
              </div>
              <div class="features-sub-text">
                Notifications keep them informed every step of the way.
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
              <div class="features-sub-heading">
                Prompt Fulfillment
              </div>
              <div class="features-sub-text">
                Hotel staff receives the request via the Task App, ensuring timely delivery.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
        <div fxLayout="column" fxLayoutAlign="space-between center">
          <div fxLayout="column" fxLayoutGap="3%" style="padding: 4% 5% 3% 5%;">
            <h2 class="features-heading">
              The Guest Experience Flow
            </h2>
          </div>
          <div class="features-image-container-left">
            <img class="features-images" src="assets/mobile-images/Guest Experience Flow.png">
          </div>
          <div fxLayout="column" fxLayoutGap="3%" style="padding: 15% 5% 5% 5%;">
            <div class="features-sub-heading">
              Easy Access
            </div>
            <div class="features-sub-text">
              Guests open the GoFriday Guest App or use the in-room tablet to explore available services.
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
            <div class="features-sub-heading">
              Seamless Requests
            </div>
            <div class="features-sub-text">
              With just a few taps, they place their requests or orders.
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
            <div class="features-sub-heading">
              Real-Time Updates
            </div>
            <div class="features-sub-text">
              Notifications keep them informed every step of the way.
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
            <div class="features-sub-heading">
              Prompt Fulfillment
            </div>
            <div class="features-sub-text">
              Hotel staff receives the request via the Task App, ensuring timely delivery.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" class="info-container1 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
    <div fxFlex="48%">
      <div>
        <h2 class="header">Part of the GoFriday Guest Experience Ecosystem</h2>
      </div>
      <div class="info-text">
        In-Room Services seamlessly integrates with GoFriday’s Guest App, Task Management App, and PMS Dashboard,
        offering hotels a complete solution to enhance service delivery and guest satisfaction.
      </div>
    </div>
    <div class="contact-image" fxFlex="40%">
      <img src="assets/images/GoFriday Guest Experience Ecosystem.png">
    </div>
  </div>
  <div fxLayout="column" class="info-container1 width100" fxLayoutAlign="center center" *ngIf="mobile">
    <div>
      <div>
        <h2 class="header">Part of the GoFriday Guest Experience Ecosystem</h2>
      </div>
      <div class="contact-image" style="text-align:center;">
        <img src="assets/mobile-images/Guest Experience Ecosystem.png">
      </div>
      <div class="info-text">
        In-Room Services seamlessly integrates with GoFriday’s Guest App, Task Management App, and PMS Dashboard,
        offering a unified and guest-first solution to enhance service delivery and satisfaction.
      </div>
    </div>
  </div>


  <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="!mobile">
    <div class="contact-image" fxFlex="50%">
      <img src="assets/images/Expectations.png">
    </div>
    <div fxFlex="50%">
      <div>
        <h2 class="header">Elevate Comfort, Exceed Expectations</h2>
      </div>
      <div class="info-text">
        Turn every room into a hub of convenience with GoFriday’s In-Room hotel Services. Deliver faster service,
        personalized experiences, and ultimate comfort—while optimizing your hotel operations.
      </div>
      <div style="margin-top: 3%;">
        <button class="book-btn" (click)="openCalendlyPopup()">
          Book A Demo Today
        </button>
      </div>
    </div>
  </div>

  <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
    <div>
      <div>
        <h2 class="header">Elevate Comfort, Exceed Expectations</h2>
      </div>
      <div class="contact-image" *ngIf="mobile" style="text-align: center;">
        <img src="assets/mobile-images/Expectations.png">
      </div>
      <div class="info-text">
        Turn every room into a hub of convenience with GoFriday’s In-Room hotel Services. Deliver faster service,
        personalized experiences, and ultimate comfort—while optimizing your hotel operations.
      </div>
      <div style="margin-top: 3%;text-align: center;">
        <button class="book-btn" (click)="openCalendlyPopup()">
          Book A Demo Today
        </button>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>