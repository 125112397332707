<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Housekeeping Management Software
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Iceberg – Housekeeping Management
            </h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Housekeeping Management Software.png" />
            </div>
            <div class="guest-app-child-text">Optimize your housekeeping operations with Iceberg Housekeeping Management
                software, a module designed to streamline workflows, enhance staff coordination, and ensure a pristine,
                guest-ready environment at all times. Boost efficiency, improve service quality, and provide guests with
                an exceptional stay experience.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Housekeeping Management Software.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            Real-Time Task Assignment & Tracking
        </h2>
        <div class="guest-app-child-text2">
            Iceberg’s Housekeeping Management module keeps your team on track with real-time task assignments and
            progress tracking. No more delays or miscommunications—every task is easily assigned and monitored to
            maintain peak productivity.
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Document Uploads.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Instant Task Updates
                    </h3>
                    <div class="why-choose-text">Assign tasks and update status in real-time for immediate visibility.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/One-Tap Service Requests.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Task Prioritization
                    </h3>
                    <div class="why-choose-text">Set task priorities based on urgency, room type, or guest preferences.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Task Assignments.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Checklists & SOPs
                    </h3>
                    <div class="why-choose-text">Ensure staff adhere to standard operating procedures with built-in
                        checklists.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div class="features-section1 width100" fxLayout="column" *ngIf="!mobile">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="26%" fxLayoutGap="4%">
                    <div class="features-heading">
                        Benefit
                    </div>
                    <div class="features-sub-text">
                        Streamline task management, reduce errors, and improve response time for better guest
                        satisfaction.
                    </div>
                </div>
                <div class="features-image-container-right">
                    <img class="features-images2" src="assets/images/benefit-image9.png" alt="Guest App">
                </div>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
            <div fxLayout="column" fxLayoutAlign="space-between center">
                <h2 class="features-heading">
                    Benefit
                </h2>
                <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/benefit-image9.png">
                    </div>
                    <div class="features-sub-text" style="margin-top: 20%;">
                        Streamline task management, reduce errors, and improve response time for better guest
                        satisfaction.
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <div class="dynamic-pricing-section">
            <h2 class="guest-app-child-heading2">
                Efficient Room Inspections
            </h2>
            <div class="guest-app-child-text2" style="padding-top: 8px;">
                Maintain high cleanliness standards with Iceberg’s room inspection capabilities. Conduct thorough checks
                to ensure rooms meet your quality expectations before guests enter, enhancing guest trust and comfort.
            </div>
            <div class="card-container">
                <mat-card *ngFor="let feature of features"
                    [ngClass]="{'feature-card': !feature.imageOnly, 'image-only-card': feature.imageOnly}"
                    (mouseenter)="onHover(feature)" (mouseleave)="onLeave(feature)"
                    [class.hidden-mobile]="feature.imageOnly && mobile">
                    <!-- Image-only card -->
                    <div *ngIf="feature.imageOnly" class="image-only-content">
                        <img [src]="feature.image" alt="Feature Image" />
                    </div>

                    <!-- Regular card with hover effects -->
                    <div *ngIf="!feature.imageOnly" class="card-content">
                        <div class="default-content">
                            <span>{{ feature.title }}</span>
                        </div>

                        <div class="hover-content" [ngClass]="{ 'show-hover-content': feature.isHovered }">
                            <div class="hover-image">
                                <img [src]="feature.image" alt="Feature Icon" />
                            </div>
                            <p>{{ feature.description }}</p>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #FFFF;">
        <h3 class="guest-app-child-heading2">
            Inventory Management
        </h3>
        <div class="guest-app-child-text2" style="padding-top: 8px;">
            Keep track of linens, toiletries, cleaning supplies, and other essentials with Iceberg’s inventory
            management features. Maintain optimal stock levels to avoid shortages and disruptions.
        </div>
        <div class="knowledge-row" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Real-Time Updates.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Automated Stock Alerts
                        </div>
                        <div class="knowledge-card-text">
                            Receive notifications when stock levels are running low.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Multi-Channel Integration.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Supply Reordering
                        </div>
                        <div class="knowledge-card-text">
                            Streamline the reordering process to ensure essential items are always in place.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/actionable tasks.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Usage Reports
                        </div>
                        <div class="knowledge-card-text">
                            Analyze consumption trends to adjust purchasing as needed.
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Benefit-service2.png">
                    </div>
                    <div fxLayout="column" fxFlex="35%" fxLayoutGap="3%">
                        <h2 class="features-heading">
                            Benefit
                        </h2>
                        <div class="features-sub-text">
                            Reduce waste, prevent stockouts, and ensure consistent service without interruptions.
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <h2 class="features-heading">
                        Benefit
                    </h2>
                    <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Benefit-service2.png">
                        </div>
                        <div class="features-sub-text" style="margin-top: 20%;">
                            Reduce waste, prevent stockouts, and ensure consistent service without interruptions.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <h2 class="guest-app-child-heading2">
            Analytics & Performance Monitoring
        </h2>
        <div class="guest-app-child-text2">
            Gain valuable insights with Iceberg’s analytics tools to monitor team performance and identify areas for
            improvement. Optimize your housekeeping strategy with data-driven decisions.
        </div>
        <!-- </div> -->
        <!-- <div class="info-container5 width100" fxLayout="column" fxLayoutAlign="center center"> -->
        <div class="knowledge-row1" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Task Completion Metrics.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Task Completion Metrics
                        </div>
                        <div class="knowledge-card-text">
                            Track how quickly tasks are completed and identify bottlenecks.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Staff Performance Reports Image.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Staff Performance Reports
                        </div>
                        <div class="knowledge-card-text">
                            Review individual and team productivity to recognize top performers and improve training.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Service Feedback.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Service Feedback
                        </div>
                        <div class="knowledge-card-text">
                            Collect and analyze feedback to identify patterns and enhance service quality.
                        </div>
                    </div>
                </div>
            </mat-card>
            <!-- </div> -->
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <div class="features-section1 width100" fxLayout="column" *ngIf="!mobile">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="26%" fxLayoutGap="4%">
                    <div class="features-heading">
                        Benefit
                    </div>
                    <div class="features-sub-text">
                        Boost team productivity, Improve service quality, and enhance overall operational efficiency.
                    </div>
                </div>
                <div class="features-image-container-right">
                    <img class="features-images2" src="assets/images/benefit-image10.png" alt="Guest App">
                </div>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
            <div fxLayout="column" fxLayoutAlign="space-between center">
                <h2 class="features-heading">
                    Benefit
                </h2>
                <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/benefit-image10.png">
                    </div>
                    <div class="features-sub-text" style="margin-top: 20%;">
                        Boost team productivity, Improve service quality, and enhance overall operational efficiency.
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="info-container5 width100" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="guest-app-child-heading2">
            Guest Preferences & Custom Requests
        </h3>
        <div class="guest-app-child-text2">
            Iceberg helps your team cater to individual guest preferences, making every stay more personalized. From
            special bedding requests to extra amenities, your staff can accommodate guest needs efficiently and
            thoughtfully.
        </div>
        <div class="benefits-row" fxLayout="row" fxLayoutAlign="center center">
            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Custom Requests Logging.png" alt="Streamlined Operations">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Custom Requests Logging</div>
                    <div class="knowledge-card-text">
                        Record and fulfill special requests seamlessly.
                    </div>
                </mat-card>
            </div>

            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Guest Profiles2.png" alt="Personalized Service">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Guest Profiles</div>
                    <div class="knowledge-card-text">
                        Access guest preferences and history to provide tailored service.
                    </div>
                </mat-card>
            </div>

            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Immediate Access.png" alt="Staff Productivity">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Immediate Access</div>
                    <div class="knowledge-card-text">
                        Ensure all staff are informed and updated about guest-specific requirements.
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Benefit-menu-changes7.png">
                    </div>
                    <div fxLayout="column" fxFlex="35%" fxLayoutGap="3%">
                        <h2 class="features-heading">
                            Benefit
                        </h2>
                        <div class="features-sub-text">
                            Create memorable guest experiences, improve satisfaction, and foster repeat business.
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <h2 class="features-heading">
                        Benefit
                    </h2>
                    <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Benefit-menu-changes7.png">
                        </div>
                        <div class="features-sub-text" style="margin-top: 20%;">
                            Create memorable guest experiences, improve satisfaction, and foster repeat business.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <h2 class="guest-app-child-heading2">
            Why Iceberg – Housekeeping Management?
        </h2>
        <div class="guest-app-child-text2">
            Iceberg Housekeeping Management is essential for any property aiming to elevate its cleaning operations.
            With comprehensive task management, real-time updates, and performance analytics, your housekeeping staff
            will be more efficient and prepared to provide guests with the best possible experience.
        </div>
    </div>


    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img src="assets/images/housekeeping operations with Iceberg.png">
        </div>
        <div fxFlex="45%">
            <div class="info-text">
                Take control of your housekeeping operations with Iceberg. Improve service consistency, save time, and
                make guest satisfaction a top priority.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/housekeeping operations with Iceberg.png">
            </div>
            <div class="info-text">
                Take control of your housekeeping operations with Iceberg. Improve service consistency, save time, and
                make guest satisfaction a top priority.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>