import { Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';


@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.css']
})

export class BlogPageComponent {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  articles = [
    {
      title: 'HICSA and GoFriday: A Perfect Correlation',
      text: 'The recent HICSA (Hotel Investment Conference - South Asia) 2024 conference...',
      image: 'assets/images/HICSA-image.png',
      readTime: '5 Minutes Read',
      categoryId: '6',
      onClick: () => this.viewHicsa()
    },
    {
      title: 'THE GUEST-CENTRIC REVOLUTION',
      text: 'Artificial Intelligence (AI) has transitioned from a futuristic concept to....',
      image: 'assets/images/guest-image.png',
      readTime: '3 Minutes Read',
      categoryId: '1',
      onClick: () => this.viewRevolution()
    },
    {
      title: 'What Is A Hotel CRM & How It Maximizes Hotel Success?',
      text: 'Discover how hotel CRM systems can enhance guest experience...',
      image: 'assets/images/CRM-image.png',
      readTime: '5 Minutes Read',
      categoryId: '1',
      onClick: () => this.viewHotelCRM()
    },
    {
      title: '9 Core Features of a CRM System for Hotels',
      text: 'The modern hotel industry is driven by data: customer data! In the competitive world...',
      image: 'assets/images/Features-CRM-image.png',
      readTime: '4 Minutes Read',
      categoryId: '1',
      onClick: () => this.viewFeaturesOfCRM()
    },
    {
      title: 'Exploring Automated Hotel',
      text: `Ever wondered how those fancy hotels seem to know exactly what you want before you even ask? It's all thanks to a magical....`,
      image: 'assets/images/Automated-Hotel-image.png',
      readTime: '3 Minutes Read',
      categoryId: '4',
      onClick: () => this.viewAutomatedHotel()
    },
    {
      title: 'What is a Hotel Profit Margin?',
      text: 'Operating a hotel in this age is no piece of cake, is it? Everyone would agree that with great rivalry and unending evolution',
      image: 'assets/images/Hotel-Profit-Margin-image.png',
      readTime: '6 Minutes Read',
      categoryId: '2',
      onClick: () => this.viewHotelProfitMargin()
    },
    {
      title: 'What Is Hospitality Management',
      text: 'Hospitality management is both art and science of managing multiple factors of the hospitality industry',
      image: 'assets/images/Hospitality-Management-image.png',
      readTime: '5 Minutes Read',
      categoryId: '5',
      onClick: () => this.viewHospitalityManagement()
    },
    {
      title: '13 Smart Strategies on How to Improve Hotel Revenue',
      text: 'In recent years, the need to maximize hotel revenue has been hotly argued within the..',
      image: 'assets/images/Hotel-Revenue-image.png',
      readTime: '6 Minutes Read',
      categoryId: '2',
      onClick: () => this.viewHotelRevenue()
    },
    {
      title: 'Hotel cancellation policy: everything you need to know',
      text: 'However simple it may sound, booking a hotel can be harder than one imagines...',
      image: 'assets/images/cancellation-policy-image.png',
      readTime: '7 Minutes Read',
      categoryId: '1',
      onClick: () => this.viewCancellationPolicy()
    },
    {
      title: '5 core features of a crm system for hotels',
      text: 'Let’s consider a situation where you know your guests’ preferences so well, that every... ',
      image: 'assets/images/5-core-features-image.png',
      readTime: '5 Minutes Read',
      categoryId: '1',
      onClick: () => this.viewCoreFeaturesOfACrm()
    },
    {
      title: 'how to travel cheaper: insider tips for affordable adventures',
      text: 'Apart from being one of the best activities during a vacation, travel can...',
      image: 'assets/images/Travel-Cheaper-image.png',
      readTime: '7 Minutes Read',
      categoryId: '6',
      onClick: () => this.viewTravelCheaper()
    },
    {
      title: 'what’s the difference between hotel and motel',
      text: 'When planning your next trip, it is necessary to understand the difference...',
      image: 'assets/images/hotel-and-motel-image.png',
      readTime: '7 Minutes Read',
      categoryId: '6',
      onClick: () => this.viewHotelAndMotel()
    },
    {
      title: 'Step-by-step guide: how to start a travel agency',
      text: 'Business Opportunities in Travel and Tourism for the Passionate Traveler has...',
      image: 'assets/images/travel-agency-image.png',
      readTime: '5 Minutes Read',
      categoryId: '6',
      onClick: () => this.viewTravelAgency()
    },
    {
      title: 'Best Hotel CRM Software: Why Choose GoFriday?',
      text: 'In today’s ultra-competitive hospitality industry, delivering exceptional...',
      image: 'assets/images/Best-Hotel-CRM-Software-image.png',
      readTime: '5 Minutes Read',
      categoryId: '1',
      onClick: () => this.viewBestHotelCRMSoftware()
    },
    {
      title: 'AI in Hospitality Industry: Transforming Guest Experiences',
      text: 'Imagine you go to the hotel, the room temperature is just as you like it...',
      image: 'assets/images/AI-in-Hospitality Industry-image.png',
      readTime: '5 Minutes Read',
      categoryId: '3',
      onClick: () => this.viewAIInHospitalityIndustry()
    },
    {
      title: 'What Does Travel Sustainable Mean? Key Concepts Explained',
      text: 'Travel is a means by which individuals open themselves to new cultures...',
      image: 'assets/images/Travel Sustainable-image.png',
      readTime: '5 Minutes Read',
      categoryId: '6',
      onClick: () => this.viewTravelSustainable()
    },
    {
      title: 'Hotel Room Management System: A Guide to Efficiency',
      text: 'Managing hotel rooms, from housekeeping to guest requests, can be...',
      image: 'assets/images/Hotel Room Management-image.png',
      readTime: '3 Minutes Read',
      categoryId: '3',
      onClick: () => this.viewHotelRoomManagement()
    },
    {
      title: 'Cloud Based CRM: Top Benefits for Hotel Business',
      text: 'Customer relationships are and have always been crucial in the highly...',
      image: 'assets/images/Cloud Based CRM-image.png',
      readTime: '6 Minutes Read',
      categoryId: '1',
      onClick: () => this.viewCloudBasedCRM()
    }

  ];

  categories = [
    { id: 'all', name: 'All' },
    { id: '1', name: 'CRM & Guest Relations' },
    { id: '2', name: 'Revenue & Profit' },
    { id: '3', name: 'Guest Experience' },
    { id: '4', name: 'Tech & Automation' },
    { id: '5', name: 'Hospitality Careers' },
    { id: '6', name: 'Travel Insights' },
  ];

  filteredArticles = this.articles;
  searchQuery = '';
  selectedCategoryId: string = 'all';

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();

  }


  filterArticles() {
    this.filteredArticles = this.articles.filter(article =>
      article.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
      article.text.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  filterArticlesByCategory(categoryId: string): void {
    this.selectedCategoryId = categoryId;
    if (categoryId === 'all') {
      this.filteredArticles = [...this.articles];
    } else {
      this.filteredArticles = this.articles.filter(article => article.categoryId === categoryId);
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  viewHicsa() {
    this.router.navigateByUrl('blog/hicsa');
    window.scrollTo(0, 0);
  }

  viewRevolution() {
    this.router.navigateByUrl('blog/guest-centric-revolution');
    window.scrollTo(0, 0);
  }

  viewHotelCRM() {
    this.router.navigateByUrl('blog/what-is-a-hotel-crm');
    window.scrollTo(0, 0);
  }

  viewFeaturesOfCRM() {
    this.router.navigateByUrl('blog/features-of-a-crm-system-for-hotels');
    window.scrollTo(0, 0);
  }

  viewAutomatedHotel() {
    this.router.navigateByUrl('blog/automated-hotel');
    window.scrollTo(0, 0);
  }

  viewHotelProfitMargin() {
    this.router.navigateByUrl('blog/hotel-profit-margin');
    window.scrollTo(0, 0);
  }

  viewHospitalityManagement() {
    this.router.navigateByUrl('blog/what-is-hospitality-management');
    window.scrollTo(0, 0);
  }

  viewHotelRevenue() {
    this.router.navigateByUrl('blog/how-to-improve-hotel-revenue');
    window.scrollTo(0, 0);
  }

  viewCancellationPolicy() {
    this.router.navigateByUrl('blog/what-is-a-hotel-cancellation-policy');
    window.scrollTo(0, 0);
  }

  viewCoreFeaturesOfACrm() {
    this.router.navigateByUrl('blog/benefits-of-crm-in-hotel-industry');
    window.scrollTo(0, 0);
  }

  viewTravelCheaper() {
    this.router.navigateByUrl('blog/how-to-travel-cheap');
    window.scrollTo(0, 0);
  }

  viewHotelAndMotel() {
    this.router.navigateByUrl('blog/difference-between-hotel-and-motel');
    window.scrollTo(0, 0);
  }

  viewTravelAgency() {
    this.router.navigateByUrl('blog/how-to-start-a-travel-agency');
    window.scrollTo(0, 0);
  }

  viewBestHotelCRMSoftware() {
    this.router.navigateByUrl('blog/gofriday-best-crm-software-for-hotels');
    window.scrollTo(0, 0);
  }

  viewAIInHospitalityIndustry() {
    this.router.navigateByUrl('blog/ai-in-hospitality-industry');
    window.scrollTo(0, 0);
  }

  viewTravelSustainable() {
    this.router.navigateByUrl('blog/what-does-travel-sustainable-mean');
    window.scrollTo(0, 0);
  }

  viewHotelRoomManagement() {
    this.router.navigateByUrl('blog/hotel-room-management-system');
    window.scrollTo(0, 0);
  }

  viewCloudBasedCRM() {
    this.router.navigateByUrl('blog/cloud-based-crm-benefits-for-hotel-business');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (data?.object?.ID) {
              this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                colorObj[data.status] || "success",
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              colorObj[e.error.status] || "error",
              e.error.status
            );
          }
        });
      },
      error: () => {
        this.disabled = false;
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

}