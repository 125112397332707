<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Spa Management Module
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Elevate Your Wellness Services with Iceberg
            </h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Spa Management Module.png" />
            </div>
            <div class="guest-app-child-text">Transform your spa services with Iceberg's guest-centric  Spa Management
                Module. Designed to optimize every aspect of your spa’s operations, this module ensures enhanced guest
                experiences, smooth coordination, improved staff productivity, and personalized guest experiences.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Spa Management Module.png" />
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="column" class="why-choose-content-container">
            <h2 class="guest-app-child-heading2" style="padding-bottom: 7%;">
                Key Features of the Spa Management Module
            </h2>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Automated Task Scheduling.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Appointment Scheduling & Management
                    </h3>
                    <div class="why-choose-text">Easily manage spa bookings with an intuitive system. Guests can book
                        online or via the app, while staff track availability and manage schedules in real-time ensuring
                        guest convenience at every step.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/SLA and SOP Compliance.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Staff Coordination & Task Management
                    </h3>
                    <div class="why-choose-text">Streamline staff workflows, assign treatment rooms, track availability,
                        and ensure seamless team communication.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/One-Tap Service Requests.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Inventory Tracking & Management
                    </h3>
                    <div class="why-choose-text">Automate inventory tracking to maintain stock levels, reduce waste, and
                        ensure the availability of supplies for guest treatments.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Performance Reporting.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Guest Preferences & Loyalty Tracking
                    </h3>
                    <div class="why-choose-text">Capture guest preferences and treatment history to offer highly
                        personalized experiences and support loyalty programs for repeat visits.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Integrated Payments & Billing.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Integrated Payments & Billing
                    </h3>
                    <div class="why-choose-text">Simplify payments with seamless PMS integration. Guests can pay through
                        the app or at the front desk for a hassle-free experience.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Multi-Department Coordination.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Performance Analytics & Reporting
                    </h3>
                    <div class="why-choose-text">Access detailed performance insights, including revenue, popular
                        treatments, and staff productivity, to optimize spa operations.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <div class="dynamic-pricing-section">
            <h3 class="guest-app-child-heading2">Key Features Of Dynamic Pricing</h3>
            <div class="card-container">
                <mat-card *ngFor="let feature of features"
                    [ngClass]="{'feature-card': !feature.imageOnly, 'image-only-card': feature.imageOnly}"
                    (mouseenter)="onHover(feature)" (mouseleave)="onLeave(feature)"
                    [class.hidden-mobile]="feature.imageOnly && mobile">
                    <!-- Image-only card -->
                    <div *ngIf="feature.imageOnly" class="image-only-content">
                        <img [src]="feature.image" alt="Feature Image" />
                    </div>

                    <!-- Regular card with hover effects -->
                    <div *ngIf="!feature.imageOnly" class="card-content">
                        <div class="default-content">
                            <span>{{ feature.title }}</span>
                        </div>

                        <div class="hover-content" [ngClass]="{ 'show-hover-content': feature.isHovered }">
                            <div class="hover-image">
                                <img [src]="feature.image" alt="Feature Icon" />
                            </div>
                            <p>{{ feature.description }}</p>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img src="assets/images/Elevate Spa Services Today.png">
        </div>
        <div fxFlex="45%">
            <div>
                <h2 class="header">Elevate Your Spa Services Today</h2>
            </div>
            <div class="info-text">
                Upgrade your spa operations and offer a seamless, personalized experience to every guest. Discover how
                Iceberg's Spa Management Module can enhance efficiency, streamline workflows, and create unforgettable
                wellness moments
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Elevate Your Spa Services Today</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/Hotel Operations.png">
            </div>
            <div class="info-text">
                Upgrade your spa operations and offer a seamless, personalized experience to every guest. Discover how
                Iceberg's Spa Management Module can enhance efficiency, streamline workflows, and create unforgettable
                wellness moments
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>