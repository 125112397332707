import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const Calendly: any;
interface Feature {
  title: string;
  description: string;
  image: string;
  isHovered: boolean;
  imageOnly: boolean;
}

@Component({
  selector: 'app-spa-management-module',
  templateUrl: './spa-management-module.component.html',
  styleUrls: ['./spa-management-module.component.css']
})
export class SpaManagementModuleComponent {
  @ViewChild('scrollableContainer', { static: false }) scrollableContainer!: ElementRef;
  @ViewChild('scrollableContainerFeedbackManagement', { static: false }) scrollableContainerFeedbackManagement!: ElementRef;
  mobile: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;

  features: Feature[] = [
    {
      title: 'Enhanced Guest Satisfaction',
      description: `Deliver tailored wellness experiences that leave a lasting impression and meet each guest's unique needs.`,
      image: 'assets/images/Enhanced Guest Satisfaction.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Operational Efficiency',
      description: 'Streamline booking, staffing, and inventory management to optimize spa operations allowing your team to focus on guest-centric service.',
      image: 'assets/images/Automated Adjustments.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Increased Revenue Opportunities',
      description: 'Upsell packages, track high-demand services, and use data insights to boost sales.',
      image: 'assets/images/Increased Revenue Opportunities.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Customizable Rules',
      description: 'Set parameters to align pricing with your revenue goals.',
      image: 'assets/images/spa-image1.png',
      isHovered: false,
      imageOnly: true
    },
    {
      title: 'Seamless Integration',
      description: 'Integrates smoothly with your hotel’s PMS and other management tools for a unified guest experience.',
      image: 'assets/images/Seamless Integration1.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Market Comparisons',
      description: 'Stay competitive with insights into local market rates.',
      image: 'assets/images/spa-image2.png',
      isHovered: false,
      imageOnly: true
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(private router: Router) { }
  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  onHover(feature: Feature) {
    if (!feature.imageOnly) {
      feature.isHovered = true;
    }
  }

  onLeave(feature: Feature) {
    if (!this.mobile && !feature.imageOnly) {
      feature.isHovered = false;
    }
  }
  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

  bannerScrollRight(): void {
    this.scrollableContainer.nativeElement.scrollTo({
      left: this.scrollableContainer.nativeElement.scrollLeft + 250,
      behavior: 'smooth'
    });
  }

  bannerScrollLeft(): void {
    this.scrollableContainer.nativeElement.scrollTo({
      left: this.scrollableContainer.nativeElement.scrollLeft - 250,
      behavior: 'smooth'
    });
  }

  bannerScrollRight1(): void {
    this.scrollableContainerFeedbackManagement.nativeElement.scrollTo({
      left: this.scrollableContainerFeedbackManagement.nativeElement.scrollLeft + 500,
      behavior: 'smooth'
    });
  }

  bannerScrollLeft1(): void {
    this.scrollableContainerFeedbackManagement.nativeElement.scrollTo({
      left: this.scrollableContainerFeedbackManagement.nativeElement.scrollLeft - 500,
      behavior: 'smooth'
    });
  }

}
