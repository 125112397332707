import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const Calendly: any;
interface Feature {
  title: string;
  description: string;
  image: string;
  isHovered: boolean;
  imageOnly: boolean;
}

@Component({
  selector: 'app-property-management-system',
  templateUrl: './property-management-system.component.html',
  styleUrls: ['./property-management-system.component.css']
})
export class PropertyManagementSystemComponent {
  mobile: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;

  features = [
    {
      title: 'AI-Powered Analytics',
      description: 'Leverage data insights to optimize pricing strategies.',
      image: 'assets/images/AI-Powered-Analytics.png',
      isHovered: false,
    },
    {
      title: 'Customizable Rules',
      description: 'Set parameters to align pricing with your revenue goals.',
      image: 'assets/images/Customizable Rules.png',
      isHovered: false,
    },
    {
      title: 'Market Comparisons',
      description: 'Stay competitive with insights into local market rates.',
      image: 'assets/images/Market Comparisons.png',
      isHovered: false,
    },
    {
      title: 'Automated Adjustments',
      description: 'Implement changes instantly without manual intervention.',
      image: 'assets/images/Automated Adjustments.png',
      isHovered: false,
    },
  ];


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(private router: Router) { }
  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  onHover(feature: Feature) {
    if (!feature.imageOnly) {
      feature.isHovered = true;
    }
  }

  onLeave(feature: Feature) {
    if (!this.mobile) {
      feature.isHovered = false;
    }
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

}
