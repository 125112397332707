<mat-toolbar class="toolbar fixed-header" *ngIf="!mobile">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%">
        <div fxLayout="row">
            <span class="logo" (click)="selectHome()">
                <img src="assets/images/logo.svg" />
            </span>
            <span>
                <button class="home" [ngClass]="currentRoute == '/'? 'selected-btn' : 'home' " (click)="selectHome()">
                    Home
                </button>
            </span>
            <span>
                <button class="feature"
                    [ngClass]="currentRoute == '/features' || currentRoute == '/products/hotel-guest-experience-software' || currentRoute == '/products/clio-hotel-business-intelligence-software' || currentRoute == '/products/hotel-task-management-software'
                    || currentRoute == '/products/online-check-in-to-online-check-out' || currentRoute == '/products/in-room-services' || currentRoute == '/products/food-ordering-system' || currentRoute == '/products/virtual-concierge-services'
                    || currentRoute == '/products/360-feedback' || currentRoute == '/products/property-management-system' || currentRoute == '/products/feedback-management-software' || currentRoute == '/products/task-management-software'
                    || currentRoute == '/products/spa-management-module' || currentRoute == '/products/food-beverage-management' || currentRoute == '/products/e-conference-room-management' || currentRoute == '/products/e-concierge-management'
                    || currentRoute == '/products/housekeeping-management' || currentRoute == '/products/centralized-reservation-management'  ? 'selected-btn' : 'feature' "
                    (click)="selectFeatures()">Products</button>
            </span>
            <span>
                <button class="plans"
                    [ngClass]="currentRoute == '/plans' || currentRoute == '/plans#Pricing' || currentRoute == '/plans#Talk-to-Sales'? 'selected-btn' : 'plans' "
                    (click)="selectPricingPlans()">
                    Plans
                </button>
            </span>
            <span>
                <button class="about"
                    [ngClass]="currentRoute == '/about-us' || currentRoute == '/about-us#Vision' || currentRoute == '/about-us#What-We-Believe-In' || currentRoute == '/about-us#Team'? 'selected-btn' : 'about' "
                    (click)="selectAboutUs()">
                    About us
                </button>
            </span>
            <span>
                <button class="blog" [ngClass]="currentRoute == '/blog' || currentRoute == '/blog/hicsa' || currentRoute == '/blog/hicsa#correlationcontent' || currentRoute == '/blog/guest-centric-revolution' || currentRoute == '/blog/guest-centric-revolution#AI-In-Hospitality' ||
                    currentRoute == '/blog/what-is-a-hotel-crm' || currentRoute == '/blog/features-of-a-crm-system-for-hotels' || currentRoute == '/blog/automated-hotel' || currentRoute == '/blog/hotel-profit-margin' || currentRoute == '/blog/what-is-hospitality-management' || 
                    currentRoute == '/blog/how-to-improve-hotel-revenue' || currentRoute == '/blog/what-is-a-hotel-cancellation-policy' || currentRoute == '/blog/benefits-of-crm-in-hotel-industry' || currentRoute == '/blog/how-to-travel-cheap' || currentRoute == '/blog/difference-between-hotel-and-motel' || 
                    currentRoute == '/blog/how-to-start-a-travel-agency' || currentRoute == '/blog/gofriday-best-crm-software-for-hotels' || currentRoute == '/blog/ai-in-hospitality-industry' || currentRoute == '/blog/what-does-travel-sustainable-mean' || currentRoute == '/blog/hotel-room-management-system' ||
                    currentRoute == '/blog/cloud-based-crm-benefits-for-hotel-business' ? 'selected-btn' : 'blog' "
                    (click)="selectBlog()">
                    Blog
                </button>
            </span>
            <span>
                <button class="about" [ngClass]="currentRoute == '/partner-program'? 'selected-btn' : 'program' "
                    (click)="selectPartnerProgram()">
                    Partner Program
                </button>
            </span>
        </div>
        <div>
            <span>
                <button class="experience"
                    [ngClass]="currentRoute == '/experiences' || currentRoute == '/experience-details' || currentRoute == '/reserve'? 'selected-btn' : 'program' "
                    (click)="selectExperiences()">
                    Experiences
                </button>
            </span>
            <button class="book-btn" (click)="openCalendlyPopup()">
                Book a Demo
            </button>
        </div>
    </div>
</mat-toolbar>
<mat-toolbar class="toolbar" *ngIf="mobile" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="logo" (click)="selectHome()">
        <img src="assets/images/logo.svg" />
    </span>
    <div>
        <button mat-button [matMenuTriggerFor]="menu" class="menu-btn">
            <span class="material-symbols-outlined">
                menu
            </span>
        </button>
        <mat-menu #menu="matMenu" (closed)="resetMenuState()">
            <div class="menu-bar">
                <button mat-menu-item (click)="selectHome()">Home</button>
                <button mat-menu-item (click)="selectAboutUs()">About us</button>
                <button mat-menu-item (click)="selectBlog()">Blog</button>
                <div (click)="toggleFeatures($event)" class="custom-menu-item">
                    <span [ngClass]="{ 'active-features': showFeatures }">Products</span>
                    <span class="material-symbols-outlined">
                        {{ showFeatures ? 'expand_less' : 'expand_more' }}
                    </span>
                </div>
                <div class="sub-menu" *ngIf="showFeatures">
                    <div (click)="toggleAllInOne($event)" class="custom-menu-item">
                        <span [ngClass]="{ 'active-features': allInOneExpanded }">All in One Platform</span>
                        <span class="material-symbols-outlined">
                            {{ allInOneExpanded ? 'expand_less' : 'expand_more' }}
                        </span>
                    </div>
                    <div class="sub-sub-menu" *ngIf="allInOneExpanded">
                        <button mat-menu-item (click)="toggleGuestAppSubMenu($event)">
                            <span [ngClass]="{ 'active-features': guestAppExpanded }">Guest App</span>
                            <span class="material-symbols-outlined move-down">
                                {{ guestAppExpanded ? 'expand_less' : 'expand_more' }}
                            </span>
                        </button>
                        <div class="sub-sub-sub-menu" *ngIf="guestAppExpanded">
                            <button mat-menu-item (click)="selectOnlineCheck()">Online Check-in to Online
                                Check-out</button>
                            <button mat-menu-item (click)="selectInRoomService()">In-Room Service</button>
                            <button mat-menu-item (click)="selectFoodOrdering()">Food Ordering System</button>
                            <button mat-menu-item (click)="selectFeedback()">360 Feedback</button>
                            <button mat-menu-item (click)="selectEconcierge()">E-concierge</button>
                        </div>

                        <!-- <button mat-menu-item (click)="toggleTaskAppSubMenu($event)">
                            <span [ngClass]="{ 'active-features': taskAppExpanded }">Task App</span>
                            <span class="material-symbols-outlined move-down">
                                {{ taskAppExpanded ? 'expand_less' : 'expand_more' }}
                            </span>
                        </button> -->
                        <div class="sub-sub-sub-menu" *ngIf="taskAppExpanded">
                            <button mat-menu-item>Guest Request</button>
                            <button mat-menu-item>Performance management/ SOP adherence</button>
                        </div>

                        <button mat-menu-item (click)="toggleCLIOSubMenu($event)">
                            <span [ngClass]="{ 'active-features': clioExpanded }">CLIO</span>
                            <span class="material-symbols-outlined move-down">
                                {{ clioExpanded ? 'expand_less' : 'expand_more' }}
                            </span>
                        </button>
                        <div class="sub-sub-sub-menu" *ngIf="clioExpanded">
                            <button mat-menu-item (click)="selectPropertyManagement()">Property Management
                                System</button>
                            <button mat-menu-item (click)="selectFeedbackManagement()">Feedback Management</button>
                            <button mat-menu-item (click)="selectTaskManagement()">Task Management</button>
                        </div>

                        <button mat-menu-item (click)="toggleIcebergSubMenu($event)">
                            <span [ngClass]="{ 'active-features': IcebergExpanded }">Iceberg</span>
                            <span class="material-symbols-outlined move-down">
                                {{ IcebergExpanded ? 'expand_less' : 'expand_more' }}
                            </span>
                        </button>
                        <div class="sub-sub-sub-menu" *ngIf="IcebergExpanded">
                            <button mat-menu-item (click)="selectSpaManagementModule()">Spa Management Module</button>
                            <button mat-menu-item (click)="selectFoodManagement()">Food & Beverage (F&B)
                                Management</button>
                            <button mat-menu-item (click)="selectConferenceManagement()">E -Conference Room
                                Management</button>
                            <button mat-menu-item (click)="selectConciergeManagement()">E -Concierge Management</button>
                            <button mat-menu-item (click)="selectHousekeepingManagement()">Housekeeping
                                Management</button>
                        </div>
                    </div>

                    <div (click)="toggleMultiProperty($event)" class="custom-menu-item">
                        <span [ngClass]="{ 'active-features': multiPropertyExpanded }">Multi Property Management</span>
                        <span class="material-symbols-outlined">
                            {{ multiPropertyExpanded ? 'expand_less' : 'expand_more' }}
                        </span>
                    </div>
                    <div class="sub-sub-menu" *ngIf="multiPropertyExpanded">
                        <button mat-menu-item (click)="selectReservationManagement()">Centralized Reservation
                            Management</button>
                    </div>
                </div>
                <button mat-menu-item (click)="selectPricingPlans()">Plans</button>
                <button mat-menu-item (click)="selectPartnerProgram()">Partner Program</button>
                <button mat-menu-item (click)="selectExperiences()">Experiences</button>
                <button mat-menu-item class="free-trial-btn-mob" (click)="openCalendlyPopup()">
                    Book a Demo
                </button>
            </div>
        </mat-menu>
    </div>
</mat-toolbar>