<app-header></app-header>
<div fxLayout="column" class="blogContainer width100">
    <div fxLayout="row">
        <div class="back-arrow" (click)="backButton()">
            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
        </div>
        <h1 class="blog-header">
            What Does Travel Sustainable Mean? <br> Key Concepts Explained
        </h1>
    </div>
    <mat-divider class="material-devider"></mat-divider>
    <div fxLayout="row" class="sub-content" fxLayoutAlign="space-evenly">
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">Janhavi Srivastava</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">21 OCT 2024</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <iconify-icon icon="material-symbols-light:share-outline" class="share-icon"></iconify-icon>
            <a class="blog-desc" style="cursor: pointer;" (click)="viewLinkedIn()">LinkedIn</a>
        </div>
    </div>
</div>
<div class="infoContainer width100" fxLayout="column" fxLayoutAlign=" center">
    <div class="Hicsa-prgm-container" *ngIf="!mobile">
        <img src="assets/images/Travel Sustainable.jpg" class="prgm-image">
    </div>
    <div class="Hicsa-prgm-container" *ngIf="mobile">
        <img src="assets/mobile-images/Travel Sustainable.png">
    </div>
    <div class="sticky-overview" fxFlex="35" [ngClass]="{ 'sticky-overview-active': showStickyOverview }">
        <div class="overview-header">Overview</div>
        <ul class="overview-list">
            <li><span (click)="viewIntroduction()"
                    [class.active]="activeSection === 'Introduction-Sustainable'">Introduction: Understanding
                    Sustainable <br> Travel</span></li>
            <li><span (click)="viewSustainableTravel()" [class.active]="activeSection === 'Sustainable-Travel'">What is
                    Sustainable Travel?</span></li>
            <li><span (click)="viewKeyConcepts()"
                    [class.active]="activeSection === 'Key-Concepts-Sustainable-Travel'">Key Concepts of Sustainable
                    Travel</span></li>
            <li><span (click)="viewRoleofTravelers()" [class.active]="activeSection === 'Role-of-Travelers'">Role of
                    Travelers in Promoting Sustainable <br> Travel</span></li>
            <li><span (click)="viewConclusion()" [class.active]="activeSection === 'Conclusion'">Conclusion: Why
                    Sustainable Travel Matters</span></li>
        </ul>
        <div fxLayout="column" class="sticky-content" fxLayoutAlign=" center">
            <div>
                <img src="assets/images/Gofryday-logo.png">
            </div>
            <div>
                <p class="sticky-text">Delight your guests <br> with AI-powered <br> experiences</p>
            </div>
            <div>
                <button class="demo-btn" (click)="openCalendlyPopup()">
                    Book a Demo
                </button>
            </div>
        </div>
    </div>
    <div class="right-side-content" fxLayout="column">
        <div id="Introduction-Sustainable" class="info-content-revolution" fxLayout="column" fxLayoutGap="2%"
            fxFlex="65%">
            <h2 class="container-head">Introduction: Understanding Sustainable Travel</h2>
            <div class="info-text">Travel is a means by which individuals open themselves to new cultures, new places,
                and new interactions. However, the means through which travel is at times exercised is termed tourism
                can have a very serious negative impression on the environment, new economies, or even heritage sites.
                Sustainable travel options are introduced in such a scenario. It is about exploring the beauties of the
                world but with minimum impact and maximum conservation of the visited sites for the future generations.
                Think of it as a win-win situation for everyone involved, but first, what does travel sustainable mean
                in this context?
            </div>
        </div>
        <div id="Sustainable-Travel" class="info-content-crm" fxLayout="column" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">What is Sustainable Travel?</h2>
            <div class="info-text">The United Nations World Tourism Organization (UNWTO) defines sustainable travel
                (search volume: 30) as "tourism that takes full account of its current and future economic, social and
                environmental impacts, addressing the needs of visitors, the industry, the environment and host
                communities." In simpler terms, what does travel sustainable mean? In simpler terms, it is about
                traveling in a way that is eco-friendly, boosts economic growth, and observes cultural practices. The
                question "What is sustainable travel?" also encompasses practices that conserve resources and reduce
                carbon footprints.
            </div>
            <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                <img src="assets/images/Sustainable Travel.png">
            </div>
            <div *ngIf="mobile" style="display: flex;justify-content: center;">
                <img src="assets/mobile-images/Sustainable Travel.png">
            </div>
        </div>
        <div id="Key-Concepts-Sustainable-Travel" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Key Concepts of Sustainable Travel</h2>
            <div class="info-text">Sustainable travel encompasses several key ideas:
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Eco-Friendly Travel
                </h3>
                <div class="info-text">Eco-friendly travel aims to reduce the burden imposed on the environment. It
                    includes such practices as minimizing emissions by not using cars, choosing to travel on trains or
                    buses, or using bicycles instead, choosing accommodations that are less harmful to the environment,
                    and reducing waste during the trip.
                </div>

            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Responsible Tourism
                </h3>
                <div class="info-text">This goes beyond the environment. It is about respecting local cultures and
                    traditions. This includes For instance, sensible dressing when going to places of worship,
                    remembering a few phrases in the local language, supporting local businesses, and shunning things
                    that exploit animals/culture.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Conservation Efforts
                </h3>
                <div class="info-text">Many destinations rely on tourism revenue to fund conservation initiatives.
                    Choosing tours and experiences that support wildlife conservation or habitat restoration shows your
                    commitment to protecting the environment that you're enjoying.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Waste Reduction and Circular Economy
                </h3>
                <div class="info-text">There is less waste generation by practicing sustainability tourism principles.
                    It includes having one’s reusable water bottles, getting vegetables and fruits from local farms
                    rather than shopping from supermarkets (which are known to create a lot of packaging pollution), and
                    utilizing the efforts of the business that adopts the model of the circular economy especially those
                    that are engaged in upcycling or using materials already in use. This ties directly into what does
                    travel sustainable mean, reflecting eco-conscious consumer habits.
                </div>
                <div class="demosection">
                    <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Delight Your Guests With AI-Powered Experiences
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="Role-of-Travelers" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Role of Travelers in Promoting Sustainable Travel</h2>
            <div class="info-text">Each of us has the power to make a difference. Here's how you can contribute to
                sustainable travel:
            </div>
            <div class="info-text">
                <ul class="list-items">
                    <li>
                        <span class="container-sub-header">Do your research:</span> Choose destinations with a strong
                        commitment to sustainability and support eco-friendly accommodations and tour operators.
                    </li>
                    <li>
                        <span class="container-sub-header">Travel slowly:</span> As many would say, good things take
                        time and so does appreciating the beauty of a specific destination, by doing this, such
                        travelers would not only negate unnecessary carbon emissions, but become part of the local
                        community building bonds that are hard to forget.
                    </li>
                    <li>
                        <span class="container-sub-header">Pack light:</span> Bringing only what you need minimizes
                        transportation emissions. Opt for versatile clothing that can be mixed and matched for different
                        activities.
                    </li>
                    <li>
                        <span class="container-sub-header">Support local businesses:</span> Dine at local restaurants,
                        buy souvenirs from local artisans, and participate in activities organized by local communities.
                    </li>
                    <li>
                        <span class="container-sub-header">Be a responsible guest:</span> Respect the environment, local
                        customs, and wildlife. Leave no trace behind, and minimize your water and energy consumption.
                    </li>
                </ul>
            </div>
            <div class="info-text">By following these simple practices, you can become a champion of sustainable travel.
                Remember, even small changes can make a big difference.
            </div>
            <div class="demosection">
                <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                    <div class="demo-text">
                        Delight Your Guests With AI-Powered Experiences
                    </div>
                    <div>
                        <button class="book-btn" (click)="openCalendlyPopup()">
                            Book a Demo
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div id="Conclusion" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Conclusion: Why Sustainable Travel Matters</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div *ngIf="!mobile">
                    <img src="assets/images/Conclusion Sustainable Travel.png">
                </div>
                <div *ngIf="mobile" style="place-content: center;display: flex;">
                    <img src="assets/mobile-images/Conclusion Sustainable Travel.png">
                </div>
                <div class="info-text">There are a lot of wonderful locations that the world has to offer. Its for this
                    reason that we should practice sustainable travel for our future descendants. It is not solely about
                    seeing the sights but rather about what kind of difference we can make in every place we visit. So
                    on your next trip, remember the influence you have as a traveler remember what does travel
                    sustainable mean to you, choose sustainable options, travel responsibly, and be a part of the
                    solution. Together, Let’s change how we see and experience the world. The impact of thoughtful
                    travel choices, backed by sustainable travel examples, can ripple across communities and
                    conservation efforts globally.
                </div>
                <div fxLayout="row" fxLayoutGap="3%" class="social-media">
                    <div>
                        <img src="assets/images/whatsapp.png">
                    </div>
                    <div>
                        <img src="assets/images/linkedin.png">
                    </div>
                    <div>
                        <img src="assets/images/email.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div #faqSection class="similar-article width100">
    <div style="padding: 0% 10%;">
        <h2 class="container-head">Similar articles</h2>
    </div>
    <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="card" *ngFor="let article of articles" (click)="article.onClick()">
            <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div class="card-image">
                    <img [src]="article.image" alt="{{ article.title }}">
                </div>
                <div class="card-content">
                    <div class="card-header">{{ article.title }}</div>
                    <div class="card-text">{{ article.text }}</div>
                </div>
                <div style="margin: 0 5% 5% 5%;">
                    <span class="readmore-text">Read More</span>&nbsp;&nbsp;
                    <span class="card-text">({{ article.readTime }})</span>
                </div>
            </div>
        </mat-card>
    </div>
</div>


<div class="faq-container" id="faq-section">
    <h2 class="faq-header">Frequently Asked Questions</h2>
    <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
            {{ faq.question }}
            <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
            {{ faq.answer }}
        </div>
    </div>
</div>
<div class="learn-more-contents" fxLayout="column" fxLayoutGap="2%">
    <div class="learn-more-head">
        Not sure how to get started?
    </div>
    <div class="learn-text">
        Join thousands of hoteliers from around the world already leveraging the power of AI, with GoFriday.
    </div>
    <div>
        <button class="book-btn" (click)="openCalendlyPopup()">
            Book a Demo
        </button>
    </div>
</div>
<div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div>
            <img src="assets/images/contact-us.png">
        </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
        <div class="form-heading">
            Contact Us
        </div>
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                            placeholder="Email" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
        Contact Us
    </div>
    <div>
        <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
        <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                            autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<app-footer></app-footer>