<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Property Management System
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Transforming Hotel Operations with a Smart Property Management System
            </h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Property Management System.png" />
            </div>
            <div class="guest-app-child-text">Managing a hotel demands precision and efficiency. CLIO - Property
                Management System (PMS) simplifies operations, from housekeeping
                and dynamic pricing to front desk management. Equip your team to
                deliver exceptional guest experiences while optimizing resources and
                ensuring operational excellence.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Property Management System.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            Housekeeping Module: Clean, Organized, and Efficient
        </h2>
        <div class="guest-app-child-text2">
            Transform your housekeeping operations with a module designed for real-time task assignment and monitoring.
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <h3 class="guest-app-child-heading2" style="padding-bottom: 5%;">
                Key Features of Housekeeping Module
            </h3>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Automated Task Scheduling.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Automated Task Scheduling
                    </h3>
                    <div class="why-choose-text">Transform your housekeeping operations with a module designed for
                        real-time task assignment and monitoring.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Real-Time Updates.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Real-Time Updates
                    </h3>
                    <div class="why-choose-text">Keep your housekeeping staff connected with live updates and instant
                        notifications.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Room Status Visibility.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Room Status Visibility
                    </h3>
                    <div class="why-choose-text">Track room readiness in real time to ensure availability for incoming
                        guests.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Performance Insights.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Performance Insights
                    </h3>
                    <div class="why-choose-text">Monitor staff efficiency and identify areas for improvement.
                    </div>
                </div>
            </div>
        </div>
        <h2 class="guest-app-child-heading2">
            Benefits
        </h2>
        <div class="benefits-container">
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">01</div>
                <h3>Faster Turnovers</h3>
                <p>Streamlined workflows mean quicker room availability.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">02</div>
                <h3>Improved Communication</h3>
                <p>Minimize delays and errors with automated updates.
                </p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">03</div>
                <h3>Enhanced Guest Experience</h3>
                <p>Ensure spotless rooms ready when guests arrive.</p>
            </div>
        </div>
    </div>

    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            Dynamic Pricing: Maximize Revenue, Minimize Effort
        </h2>
        <div class="guest-app-child-text2">
            Dynamic pricing isn’t just a trend—it’s a necessity in today’s competitive market. CLIO's Dynamic Pricing
            module helps you stay ahead by adjusting room rates in real time based on demand, occupancy, and market
            trends.
        </div>

        <div class="dynamic-pricing-section">
            <h3 class="guest-app-child-heading2">Key Features Of Dynamic Pricing</h3>
            <div class="card-container">
                <mat-card *ngFor="let feature of features" class="feature-card" (mouseenter)="feature.isHovered = true"
                    (mouseleave)="feature.isHovered = false">
                    <div class="card-content">
                        <div class="default-content">
                            <span>{{ feature.title }}</span>
                        </div>

                        <div class="hover-content" [ngClass]="{ 'show-hover-content': feature.isHovered }">
                            <div class="hover-image">
                                <img [src]="feature.image" alt="Feature Icon" />
                            </div>
                            <p>{{ feature.description }}</p>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="guest-app-child-heading2">
            Benefits of Dynamic Pricing
        </h3>
        <div class="knowledge-row" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Increased Revenue.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Increased Revenue
                        </div>
                        <div class="knowledge-card-text">
                            Capture demand with timely rate adjustments.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Competitive Edge.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Competitive Edge
                        </div>
                        <div class="knowledge-card-text">
                            Ensure your pricing aligns with market expectations.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Operational Simplicity.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Operational Simplicity
                        </div>
                        <div class="knowledge-card-text">
                            Reduce reliance on manual rate management.
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            Front Desk Management: The Heart of Hotel Operations
        </h2>
        <div class="guest-app-child-text2">
            Your front desk is the first point of contact for guests. CLIO’s Front Desk Management module ensures a
            smooth, professional, and efficient check-in and check-out process
        </div>
    </div>
    <div class="info-container5 width100" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="guest-app-child-heading2">
            Key Features front Desk Management
        </h3>
        <div class="knowledge-row1" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Guest Profiles.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Guest Profiles
                        </div>
                        <div class="knowledge-card-text">
                            Access detailed guest histories and preferences at a glance.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Unified Dashboard.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Unified Dashboard
                        </div>
                        <div class="knowledge-card-text">
                            Manage reservations, room statuses, and guest inquiries from one screen.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Task Automation.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Task Automation
                        </div>
                        <div class="knowledge-card-text">
                            Automate repetitive tasks, from room assignments to billing.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Integrated Communication.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Integrated Communication
                        </div>
                        <div class="knowledge-card-text">
                            Easily relay messages between departments.
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>

        <!-- <div class="info-container5 width100" fxLayout="column" fxLayoutAlign="center center"> -->
        <h3 class="guest-app-child-heading2">
            Benefits
        </h3>
        <div class="benefits-row" fxLayout="row" fxLayoutAlign="center center">
            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Streamlined Operations.png" alt="Streamlined Operations">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Streamlined Operations</div>
                    <div class="knowledge-card-text">
                        Reduce wait times and handle peak periods with ease.
                    </div>
                </mat-card>
            </div>

            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Personalized Service.png" alt="Personalized Service">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Personalized Service</div>
                    <div class="knowledge-card-text">
                        Use guest data to create tailored experiences.
                    </div>
                </mat-card>
            </div>

            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Staff Productivity.png" alt="Staff Productivity">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Staff Productivity</div>
                    <div class="knowledge-card-text">
                        Free up front desk staff for high-value interactions.
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <!-- </div> -->

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center"
        style="background: #F9F9F9;width: 100%;padding: 10% 10% 3% 10%" *ngIf="!mobile">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2%">
            <h2 class="guest-app-child-heading2">
                Why Choose CLIO - Property Management System for Your Hotel?
            </h2>
            <div class="guest-app-child-text2">
                CLIO - Property Management System combines powerful features with intuitive interfaces, allowing your
                hotel to run like a well-oiled machine.
            </div>
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center"
        style="background: #F9F9F9;width: 100%;padding: 2%" *ngIf="mobile">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4%">
            <h2 class="guest-app-child-heading2">
                Why Choose CLIO - Property Management System for Your Hotel?
            </h2>
            <div class="guest-app-child-text2">
                CLIO - Property Management System combines powerful features with intuitive interfaces, allowing your
                hotel to run like a well-oiled machine.
            </div>
        </div>
    </div>
    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="background: #F9F9F9;width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Operational Advantages.png">
                    </div>
                    <div fxLayout="column" fxFlex="47%" fxLayoutGap="3%">
                        <h2 class="features-heading">
                            Operational Advantages
                        </h2>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Centralized Control
                            </div>
                            <div class="features-sub-text">
                                Manage all operational aspects from one platform.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Real-Time Collaboration
                            </div>
                            <div class="features-sub-text">
                                Keep every department in sync with live updates.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Scalable Solutions
                            </div>
                            <div class="features-sub-text">
                                Designed to adapt to hotels of all sizes and complexities.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" style="padding: 12% 5% 3% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Operational Advantages.png">
                        </div>
                        <h2 class="features-heading" style="padding-top: 40%;">
                            Operational Advantages
                        </h2>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 5% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Centralized Control
                        </div>
                        <div class="features-sub-text">
                            Manage all operational aspects from one platform.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Real-Time Collaboration
                        </div>
                        <div class="features-sub-text">
                            Keep every department in sync with live updates.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Scalable Solutions
                        </div>
                        <div class="features-sub-text">
                            Designed to adapt to hotels of all sizes and complexities.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div fxLayout="row" class="info-container1 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div fxFlex="48%">
            <div>
                <h2 class="header">Guest Satisfaction Delivered</h2>
            </div>
            <div class="info-text">
                By optimizing your property’s core operations, CLIO frees your staff to focus on what matters most—your
                guests.
            </div>
        </div>
        <div class="contact-image" fxFlex="40%">
            <img src="assets/images/Guest Satisfaction Delivered.png">
        </div>
    </div>
    <div fxLayout="column" class="info-container1 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Guest Satisfaction Delivered</h2>
            </div>
            <div class="contact-image" style="text-align:center;">
                <img src="assets/mobile-images/Guest Satisfaction Delivered.png">
            </div>
            <div class="info-text">
                By optimizing your property’s core operations, CLIO frees your staff to focus on what matters most—your
                guests.
            </div>
        </div>
    </div>


    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img src="assets/images/Hotel Operations.png">
        </div>
        <div fxFlex="45%">
            <div>
                <h2 class="header">Ready to Elevate Your Hotel Operations?</h2>
            </div>
            <div class="info-text">
                Experience the future of property management with CLIO PMS. From housekeeping to pricing and front desk
                management, CLIO has everything you need to deliver exceptional results.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Ready to Elevate Your Hotel Operations?</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/Hotel Operations.png">
            </div>
            <div class="info-text">
                Experience the future of property management with CLIO PMS. From housekeeping to pricing and front desk
                management, CLIO has everything you need to deliver exceptional results.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>