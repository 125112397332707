<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Feedback Management Software
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">360° Feedback: Understand Guest Sentiment Like Never Before</h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Feedback Management Software.png" />
            </div>
            <div class="guest-app-child-text">Guest feedback is the cornerstone of guest-centric hotel services.
                GoFriday’s 360° Feedback captures real-time insights and post-checkout reviews, empowering you to
                enhance guest satisfaction and refine operations for a guest-first experience. 
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Feedback Management Software.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            What is 360° Feedback by GoFriday?
        </h2>
        <div class="guest-app-child-text2">
            360° Feedback integrates with the GoFriday Guest App to collect guest feedback across every touchpoint. From
            in-stay suggestions to post-stay reviews, gain valuable insights to enhance services and elevate guest
            satisfaction
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <h3 class="guest-app-child-heading2" style="padding-bottom: 5%;">
                Features of 360° Feedback
            </h3>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Real-Time Feedback.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Real-Time Feedback
                    </h3>
                    <div class="why-choose-text">Guests can share their thoughts instantly during their stay, ensuring
                        timely action to resolve issues or enhance their
                        experience.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Post-Stay Reviews.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Post-Stay Reviews
                    </h3>
                    <div class="why-choose-text">Gather detailed insights after checkout, giving guests the opportunity
                        to reflect on their overall stay and leave meaningful feedback</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Multichannel Collection.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Multichannel Collection
                    </h3>
                    <div class="why-choose-text">Feedback can be captured via the app, email, or in-room devices,
                        catering to guest preferences and maximizing response rates
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Sentiment Analysis.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Sentiment Analysis
                    </h3>
                    <div class="why-choose-text">AI-driven tools analyze feedback to uncover trends, satisfaction
                        levels, and areas for improvement, providing actionable guest-centric insights
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Personalized Surveys.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Personalized Surveys
                    </h3>
                    <div class="why-choose-text">Create tailored surveys that focus on specific services, such as
                        dining, housekeeping, or spa experiences, to dig deeper into guest opinions.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Dashboard Reporting.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Dashboard Reporting
                    </h3>
                    <div class="why-choose-text">View aggregated feedback data in the GoFriday PMS Dashboard, with
                        easy-to-read metrics and reports that guide guest-focused strategie.
                    </div>
                </div>
            </div>
        </div>
        <h2 class="guest-app-child-heading2">
            Benefits of 360° Feedback for Your Hotel
        </h2>
        <div class="benefits-container">
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">01</div>
                <h3>Proactive Service Recovery</h3>
                <p>Address guest concerns in real time to turn potential complaints into positive experiences.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">02</div>
                <h3>Enhanced Guest Loyalty</h3>
                <p>Show guests you value their opinions by acting on feedback to improve your offerings.
                </p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">03</div>
                <h3>Operational Improvements</h3>
                <p>Use insights to optimize staff performance, streamline operations, and enhance service delivery.</p>
            </div>
            <mat-divider *ngIf="!mobile"></mat-divider>
            <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                <div class="benefit-number">04</div>
                <h3>Improved Reputation</h3>
                <p>Leverage positive feedback to attract future guests and bolster your online presence.</p>
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5%">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Feedback Works.png">
                    </div>
                    <div fxLayout="column" fxFlex="44%" fxLayoutGap="3%">
                        <h2 class="features-heading">
                            How 360° Feedback Works
                        </h2>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Invite Feedback
                            </div>
                            <div class="features-sub-text">
                                Guests are prompted to leave feedback at key moments, such as after meals, room service,
                                or during checkout.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Gather Insights
                            </div>
                            <div class="features-sub-text">
                                Feedback is collected through user-friendly forms or quick rating systems within the
                                app.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Analyze Sentiment
                            </div>
                            <div class="features-sub-text">
                                AI tools sort and analyze feedback, highlighting key trends and areas needing attention.
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="3%">
                            <div class="features-sub-heading">
                                Take Action
                            </div>
                            <div class="features-sub-text">
                                Hotel teams receive actionable recommendations to improve guest satisfaction and refine
                                operations to guest needs.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 4% 5% 3% 5%;">
                        <h2 class="features-heading">
                            How 360° Feedback Works
                        </h2>
                    </div>
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/Feedback Works.png">
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 15% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Invite Feedback
                        </div>
                        <div class="features-sub-text">
                            Guests are prompted to leave feedback at key moments, such as after meals, room service, or
                            during checkout.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Gather Insights
                        </div>
                        <div class="features-sub-text">
                            Feedback is collected through user-friendly forms or quick rating systems within the app.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Analyze Sentiment
                        </div>
                        <div class="features-sub-text">
                            AI tools sort and analyze feedback, highlighting key trends and areas needing attention.
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="3%" style="padding: 2% 5% 5% 5%;">
                        <div class="features-sub-heading">
                            Take Action
                        </div>
                        <div class="features-sub-text">
                            Hotel teams receive actionable recommendations to improve guest satisfaction and refine
                            operations to guest needs.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="info-container1 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div fxFlex="48%">
            <div>
                <h2 class="header">Part of GoFriday’s Guest Experience Suite</h2>
            </div>
            <div class="info-text">
                360° Feedback seamlessly integrates with GoFriday’s Guest App, Task Management App, and PMS
                Dashboard to ensure a unified, data-driven approach to guest satisfaction.
            </div>
        </div>
        <div class="contact-image" fxFlex="40%">
            <img src="assets/images/Guest Experience Suite.png">
        </div>
    </div>
    <div fxLayout="column" class="info-container1 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Part of GoFriday’s Guest Experience Suite</h2>
            </div>
            <div class="contact-image" style="text-align:center;">
                <img src="assets/mobile-images/Guest Experience Suite.png">
            </div>
            <div class="info-text">
                360° Feedback seamlessly integrates with GoFriday’s Guest App, Task Management App, and PMS
                Dashboard to ensure a unified, data-driven approach to guest satisfaction.
            </div>
        </div>
    </div>


    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img src="assets/images/Feedback.png">
        </div>
        <div fxFlex="45%">
            <div>
                <h2 class="header">Discover the Power of 360° Feedback</h2>
            </div>
            <div class="info-text">
                Transform feedback into a competitive advantage. With GoFriday’s 360° Feedback, you can uncover what
                truly matters to your guests, exceed their expectations, and build loyalty that lasts.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Discover the Power of 360° Feedback</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/Feedback.png">
            </div>
            <div class="info-text">
                Transform feedback into a competitive advantage. With GoFriday’s 360° Feedback, you can uncover what
                truly matters to your guests, exceed their expectations, and build loyalty that lasts.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>