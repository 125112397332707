import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const Calendly: any;

@Component({
  selector: 'app-virtual-concierge-services',
  templateUrl: './virtual-concierge-services.component.html',
  styleUrls: ['./virtual-concierge-services.component.css']
})
export class VirtualConciergeServicesComponent {

  mobile: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(private router: Router) { }
  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

}
