import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const Calendly: any;
interface Feature {
  title: string;
  description: string;
  image: string;
  isHovered: boolean;
  imageOnly: boolean;
}

@Component({
  selector: 'app-housekeeping-management',
  templateUrl: './housekeeping-management.component.html',
  styleUrls: ['./housekeeping-management.component.css']
})
export class HousekeepingManagementComponent {
  @ViewChild('scrollableContainer', { static: false }) scrollableContainer!: ElementRef;
  @ViewChild('scrollableContainerFeedbackManagement', { static: false }) scrollableContainerFeedbackManagement!: ElementRef;
  mobile: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;

  features: Feature[] = [
    {
      title: 'Feedback Integration',
      description: `Allow staff to report issues and receive feedback directly.`,
      image: 'assets/images/Feedback Integration.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Non-Conformance Alerts',
      description: 'Get instant notifications of issues that need attention.',
      image: 'assets/images/Non-Conformance Alerts.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Inspection Reports',
      description: 'Capture detailed inspection notes and add photos for full accountability.',
      image: 'assets/images/Inspection Reports.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Customizable Rules',
      description: 'Set parameters to align pricing with your revenue goals.',
      image: 'assets/images/spa-image9.png',
      isHovered: false,
      imageOnly: true
    },
    {
      title: 'Benefit',
      description: 'Increase room quality control, reduce guest complaints, and improve staff accountability.',
      image: 'assets/images/Benefit-image5.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Market Comparisons',
      description: 'Stay competitive with insights into local market rates.',
      image: 'assets/images/spa-image10.png',
      isHovered: false,
      imageOnly: true
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(private router: Router) { }
  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  onHover(feature: Feature) {
    if (!feature.imageOnly) {
      feature.isHovered = true;
    }
  }

  onLeave(feature: Feature) {
    if (!this.mobile && !feature.imageOnly) {
      feature.isHovered = false;
    }
  }
  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

  bannerScrollRight(): void {
    this.scrollableContainer.nativeElement.scrollTo({
      left: this.scrollableContainer.nativeElement.scrollLeft + 250,
      behavior: 'smooth'
    });
  }

  bannerScrollLeft(): void {
    this.scrollableContainer.nativeElement.scrollTo({
      left: this.scrollableContainer.nativeElement.scrollLeft - 250,
      behavior: 'smooth'
    });
  }

  bannerScrollRight1(): void {
    this.scrollableContainerFeedbackManagement.nativeElement.scrollTo({
      left: this.scrollableContainerFeedbackManagement.nativeElement.scrollLeft + 500,
      behavior: 'smooth'
    });
  }

  bannerScrollLeft1(): void {
    this.scrollableContainerFeedbackManagement.nativeElement.scrollTo({
      left: this.scrollableContainerFeedbackManagement.nativeElement.scrollLeft - 500,
      behavior: 'smooth'
    });
  }


}
