import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { PopupComponent } from '../popup/popup.component';
import { colorObj } from 'src/shared/color-object';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const Calendly: any;

@Component({
  selector: 'app-blog1',
  templateUrl: './blog1.component.html',
  styleUrls: ['./blog1.component.css']
})
export class Blog1Component {

  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;
  @ViewChild('learnMoreSection') learnMoreSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  articles = [
    {
      title: '13 Smart Strategies on How to Improve Hotel Revenue',
      text: 'In recent years, the need to maximize hotel revenue has been hotly argued within the..',
      image: 'assets/images/Hotel-Revenue-image.png',
      readTime: '6 Minutes Read',
      onClick: () => this.viewHotelRevenue()
    },
    {
      title: 'What Is Hospitality Management',
      text: 'Hospitality management is both art and science of managing multiple factors of the hospitality industry',
      image: 'assets/images/Hospitality-Management-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewHospitalityManagement()
    },
    {
      title: 'What Is A Hotel CRM & How It Maximizes Hotel Success?',
      text: 'Discover how hotel CRM systems can enhance guest experience...',
      image: 'assets/images/CRM-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewHotelCRM()
    }
  ]

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const learnMoreSectionTop = this.learnMoreSectionRef.nativeElement.offsetTop;
    const threshold1 = 600;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (learnMoreSectionTop - threshold1);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'hicsa-2024',
      'correlationcontent',
      'hospitalityLandscapesection',
      'Investmentcontent',
      'guestExperiencecontent',
      'cloudsection',
      'revenuesection',
      'Sustainabilitysection',
      'conclusionsection'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewHotelRevenue() {
    this.router.navigateByUrl('blog/how-to-improve-hotel-revenue');
    window.scrollTo(0, 0);
  }

  viewHospitalityManagement() {
    this.router.navigateByUrl('blog/what-is-hospitality-management');
    window.scrollTo(0, 0);
  }

  viewHotelCRM() {
    this.router.navigateByUrl('blog/what-is-a-hotel-crm');
    window.scrollTo(0, 0);
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  viewLinkedIn() {
    const linkedInUrl = environment.LINKEDIN_URL;
    const websiteUrl = `${environment.WEBSITE_URL}/blog/hicsa`;
    const fullUrl = `${linkedInUrl}${websiteUrl}`;
    window.open(fullUrl, "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.isPopupOpen = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (!!data?.object?.ID) {
              const dialogRef = this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
              dialogRef.afterClosed().subscribe(() => {
                this.isPopupOpen = false;
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  hicsaIntroduction() {
    this.scrollToSection('hicsa-2024');
  }

  viewCorrelation() {
    this.scrollToSection('correlationcontent');
  }

  viewHospitalityLandscape() {
    this.scrollToSection('hospitalityLandscapesection');
  }

  viewInvestmentcontent() {
    this.scrollToSection('Investmentcontent');
  }

  viewGuestExperience() {
    this.scrollToSection('guestExperiencecontent');
  }

  viewCloud() {
    this.scrollToSection('cloudsection');
  }

  viewRevenue() {
    this.scrollToSection('revenuesection');
  }

  viewSustainability() {
    this.scrollToSection('Sustainabilitysection');
  }

  viewConclusion() {
    this.scrollToSection('conclusionsection');
  }

  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

}