import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const Calendly: any;
interface Feature {
  title: string;
  description: string;
  image: string;
  isHovered: boolean;
  imageOnly: boolean;
}


@Component({
  selector: 'app-food-beverage-management',
  templateUrl: './food-beverage-management.component.html',
  styleUrls: ['./food-beverage-management.component.css']
})
export class FoodBeverageManagementComponent {
  @ViewChild('scrollableContainer', { static: false }) scrollableContainer!: ElementRef;
  @ViewChild('scrollableContainerFeedbackManagement', { static: false }) scrollableContainerFeedbackManagement!: ElementRef;
  mobile: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;

  features: Feature[] = [
    {
      title: 'Centralized Order Dashboard',
      description: `View and manage all orders in one place.`,
      image: 'assets/images/Centralized Order Dashboard.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Real-Time Updates',
      description: 'Monitor order status and make adjustments as needed.',
      image: 'assets/images/Real-Time-Updates.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Integration with POS',
      description: 'Seamlessly sync with your existing point-of-sale systems.',
      image: 'assets/images/Integration with POS.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Customizable Rules',
      description: 'Set parameters to align pricing with your revenue goals.',
      image: 'assets/images/spa-image3.png',
      isHovered: false,
      imageOnly: true
    },
    {
      title: 'Benefit',
      description: 'Reduce order errors, speed up service, and improve guest satisfaction.',
      image: 'assets/images/Benefit-image.png',
      isHovered: false,
      imageOnly: false
    },
    {
      title: 'Market Comparisons',
      description: 'Stay competitive with insights into local market rates.',
      image: 'assets/images/spa-image4.png',
      isHovered: false,
      imageOnly: true
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(private router: Router) { }
  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  onHover(feature: Feature) {
    if (!this.mobile && !feature.imageOnly) {
      feature.isHovered = true;
    }
  }

  onLeave(feature: Feature) {
    if (!this.mobile && !feature.imageOnly) {
      feature.isHovered = false;
    }
  }
  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

  bannerScrollRight(): void {
    this.scrollableContainer.nativeElement.scrollTo({
      left: this.scrollableContainer.nativeElement.scrollLeft + 250,
      behavior: 'smooth'
    });
  }

  bannerScrollLeft(): void {
    this.scrollableContainer.nativeElement.scrollTo({
      left: this.scrollableContainer.nativeElement.scrollLeft - 250,
      behavior: 'smooth'
    });
  }

  bannerScrollRight1(): void {
    this.scrollableContainerFeedbackManagement.nativeElement.scrollTo({
      left: this.scrollableContainerFeedbackManagement.nativeElement.scrollLeft + 500,
      behavior: 'smooth'
    });
  }

  bannerScrollLeft1(): void {
    this.scrollableContainerFeedbackManagement.nativeElement.scrollTo({
      left: this.scrollableContainerFeedbackManagement.nativeElement.scrollLeft - 500,
      behavior: 'smooth'
    });
  }


}
