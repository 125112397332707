<app-header></app-header>
<div fxLayout="column" class="blogContainer width100">
    <div fxLayout="row">
        <div class="back-arrow" (click)="backButton()">
            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
        </div>
        <h1 class="blog-header">
            AI in Hospitality Industry:<br>Transforming Guest Experiences
        </h1>
    </div>
    <mat-divider class="material-devider"></mat-divider>
    <div fxLayout="row" class="sub-content" fxLayoutAlign="space-evenly">
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">Janhavi Srivastava</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">10 OCT 2024</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <iconify-icon icon="material-symbols-light:share-outline" class="share-icon"></iconify-icon>
            <a class="blog-desc" style="cursor: pointer;" (click)="viewLinkedIn()">LinkedIn</a>
        </div>
    </div>
</div>
<div class="infoContainer width100" fxLayout="column" fxLayoutAlign=" center">
    <div class="Hicsa-prgm-container" *ngIf="!mobile">
        <img src="assets/images/AI in Hospitality Industry.jpg" class="prgm-image">
    </div>
    <div class="Hicsa-prgm-container" *ngIf="mobile">
        <img src="assets/mobile-images/AI in Hospitality Industry.png">
    </div>
    <div class="sticky-overview" fxFlex="35" [ngClass]="{ 'sticky-overview-active': showStickyOverview }">
        <div class="overview-header">Overview</div>
        <ul class="overview-list">
            <li><span (click)="viewHospitalityIndustry()" [class.active]="activeSection === 'Hospitality-Industry'">What
                    is AI in Hospitality Industry?</span></li>
            <li><span (click)="viewGuestExperiences()" [class.active]="activeSection === 'Guest-Experiences'">Key Ways
                    AI is Transforming Guest <br> Experiences</span></li>
            <li><span (click)="viewAIInHospitalityIndustry()"
                    [class.active]="activeSection === 'AI-In-Hospitality-Industry'">Use of AI in Hospitality
                    Industry</span></li>
            <li><span (click)="viewFutureOfAI()" [class.active]="activeSection === 'Future-Of-AI'">Future of AI in
                    Hospitality Industry</span></li>
            <li><span (click)="viewAIImplementation()" [class.active]="activeSection === 'AI-Implementation'">How to
                    Implement AI in Your
                    Hospitality <br> Business</span></li>
            <li><span (click)="viewConclusion()" [class.active]="activeSection === 'Conclusion'">Conclusion</span></li>
        </ul>
        <div fxLayout="column" class="sticky-content" fxLayoutAlign=" center">
            <div>
                <img src="assets/images/Gofryday-logo.png">
            </div>
            <div>
                <p class="sticky-text">Delight your guests <br> with AI-powered <br> experiences</p>
            </div>
            <div>
                <button class="demo-btn" (click)="openCalendlyPopup()">
                    Book a Demo
                </button>
            </div>
        </div>
    </div>
    <div class="right-side-content" fxLayout="column">
        <div fxLayout="column" class="info-content-revolution" fxLayoutGap="2%" fxFlex="65%">
            <div class="info-text">Imagine you go to the hotel, the room temperature is just as you like it, you have
                some of your preferred snacks in the room and the receptionist recognizes you and treats you as an old
                friend. This is the kind of simple service that used to be a luxury, but due to the ever increasing
                artificial intelligence (AI) in the hospitality sector, personalization is becoming the new norm. AI is
                more than just algorithms and programming language, it is about providing unforgettable experiences to
                guests and improving how hospitality personnel work. Let’s see how AI is revolutionizing the hospitality
                industry, improving guest interactions, and what future looks like.
            </div>
        </div>
        <div id="Hospitality-Industry" class="info-content-crm" fxLayout="column" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">What is AI in Hospitality Industry?</h2>
            <div class="info-text">At its core, the role of artificial intelligence in hospitality industry refers to
                the use of smart technologies that help businesses understand and anticipate guest needs. Think of AI as
                a supercharged assistant, analyzing everything from booking patterns to customer feedback to create a
                more seamless experience. The role of artificial intelligence in hospitality is all about making
                interactions smoother, more personalized, and, ultimately, more enjoyable for everyone involved.
            </div>
            <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                <img src="assets/images/Hospitality Industry.png">
            </div>
            <div *ngIf="mobile" style="display: flex;justify-content: center;">
                <img src="assets/mobile-images/Hospitality Industry.png">
            </div>
        </div>
        <div id="Guest-Experiences" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Key Ways AI is Transforming Guest Experiences</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Personalized Experiences
                </h3>
                <div class="info-text">Let’s say you arrive at your hotel and there’s a note from the staff welcoming
                    you to the hotel and a pillow with your preferred design, and your favorite nuts or chocolates in
                    the mini bar, how would you feel? That’s the power of AI! They track your preferences and create
                    tailor-made experiences that highlight your individuality and importance. It’s not just about
                    calling out your name, it’s about understanding you.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Friendly Chatbots
                </h3>
                <div class="info-text">It can be hard to keep track of guest communication when there are a multitude of
                    channels active such as emails, SMS, WhatsApp, and so many other 3rd-party platforms all at the same
                    time. GoFriday simplifies everything by merging the various communication channels into one. As a
                    result, every message that a hotel guest sends out or calls for, gets addressed without unnecessary
                    delays. If it is confirming the booking made via your hotel’s website, sending out earlier emails or
                    requesting customer reviews, one is guaranteed that the response will always be polished and
                    courteous while attending to the queries will not take ages.
                </div>
                <div class="demosection">
                    <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Delight Your Guests With AI-Powered Experiences
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Smart Pricing
                </h3>
                <div class="info-text">Have you ever noticed, why it is that sometimes hotel stays are more expensive
                    than at other times? Through AI algorithms, hotels are able to offer their customers the best
                    possible prices while ensuring to remain profitable at the end of the day by changing their prices
                    in accordance with customer demand. This enables you to get the best deals while travelling and
                    hotels to get good clearance of their rooms.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Streamlined Operations
                </h3>
                <div class="info-text">AI is a game-changer that enables seamless operations across the board. Tasks
                    such as staff scheduling or inventory management can be automated through hospitality management
                    software and hotel guest experience software, allowing hotel teams to concentrate on guest
                    satisfaction. With these tools, employees can focus more on guests rather than logistical tasks,
                    elevating the guest experience.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">5. Predicting Guest Needs
                </h3>
                <div class="info-text">Have you ever experienced something when a hotel employee meets your expectations
                    even before you say a word? That’s AI in action! Based on statistics, it shouldn’t be too difficult
                    for hotels to guess what their guests might need including an extra towel, a strong request to
                    address the manager, or quiet spa hours. It’s like having a personal concierge who knows you better
                    than you know yourself!
                </div>
            </div>
        </div>

        <div id="AI-In-Hospitality-Industry" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Use of AI in Hospitality Industry</h2>
            <div class="info-text">The use of AI in hospitality industry is transforming how businesses operate and
                interact with guests. Here are a few exciting applications:
            </div>
            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head-text">1. Smart Room Features:
                    &nbsp;</span>Walking into a room and just the right amount of lighting and temperature appears
                without you lifting a finger? Thanks to AI, the guest can walk into a smart room that is powered by AI
                and set to the temperature, lighting, and decor of their choice.
            </div>
            <!-- </div> -->
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head-text">1. Smart Room Features
                </h3>
                <div class="info-text">Walking into a room and just the right amount of lighting and temperature appears
                    without you lifting a finger? Thanks to AI, the guest can walk into a smart room that is powered by
                    AI
                    and set to the temperature, lighting, and decor of their choice.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head-text">2. Targeted Marketing:
                    &nbsp;</span>Thanks to AI tools for emerging technologies in travel and hospitality, guest data
                analytics will allow for targeted marketing campaign deployment within hotels. It means that there are
                chances of seeing promotions which are of interest to a guest more, which increases chances of the guest
                taking the offer.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head-text">2. Targeted Marketing
                </h3>
                <div class="info-text">Thanks to AI tools for emerging technologies in travel and hospitality, guest
                    data analytics will allow for targeted marketing campaign deployment within hotels. It means that
                    there are chances of seeing promotions which are of interest to a guest more, which increases
                    chances of the guest taking the offer.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="!mobile">
                <div class="info-text"><span class="container-sub-head-text">3. Understanding Guest Sentiment:
                        &nbsp;</span>I can evaluate guest feedback and social media interactions in order to assist
                    hoteliers in determining the love or pain points of their clients. This feedback loop enables hotels
                    to improve and perfect their services and assist in providing a better experience for future guests.
                </div>
                <div class="demosection">
                    <div class="book-contents1" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Delight Your Guests With AI-Powered Experiences
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head-text">3. Understanding Guest Sentiment
                </h3>
                <div class="info-text">I can evaluate guest feedback and social media interactions in order to assist
                    hoteliers in determining the love or pain points of their clients. This feedback loop enables hotels
                    to improve and perfect their services and assist in providing a better experience for future guests.
                </div>
                <div class="demosection">
                    <div class="book-contents1" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Delight Your Guests With AI-Powered Experiences
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div id="Future-Of-AI" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Future of AI in Hospitality Industry</h2>
            <div class="info-text">As we move on to the use cases becoming more mainstream, it is clear that AI future
                in hospitality is bright as it focuses on personalization. Some of the trends, however, can be pointed
                out for the future of the industry:
            </div>
            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head-text">1. Seamless Integration with
                    Smart
                    Technologies:
                    &nbsp;</span>The technology to provide the level of personalization that tourist demand will be
                placed into practice. Imagine the future, where, as soon as you check into a hotel, the room prepares
                according to your likes, or where the guests feel as if the hotel rooms are speaking to them and magic
                happens even before they walk into the rooms.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head-text">1. Seamless Integration with Smart Technologies
                </h3>
                <div class="info-text">The technology to provide the level of personalization that tourist demand will
                    be placed into practice. Imagine the future, where, as soon as you check into a hotel, the room
                    prepares according to your likes, or where the guests feel as if the hotel rooms are speaking to
                    them and magic happens even before they walk into the rooms.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head-text">2. Virtual Reality Experiences:
                    &nbsp;</span>What if you could take a virtual tour of a hotel before booking? AI combined with
                virtual reality could allow guests to explore their accommodations and amenities in an immersive way,
                making decision-making easier and more fun.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head-text">2. Virtual Reality Experiences
                </h3>
                <div class="info-text">What if you could take a virtual tour of a hotel before booking? AI combined with
                    virtual reality could allow guests to explore their accommodations and amenities in an immersive
                    way, making decision-making easier and more fun.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="!mobile">
                <div class="info-text"><span class="container-sub-head-text">3. Intelligent Personal Assistants:
                        &nbsp;</span>The personal assistants we use today will become even more intuitive. They may
                    learn your likes and dislikes over time, anticipating your needs without you needing to ask—a true
                    game changer in hospitality!
                </div>
                <div style="display: flex;justify-content: center;">
                    <img src="assets/images/Personal Assistants.png">
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head-text">3. Intelligent Personal Assistants
                </h3>
                <div class="info-text">The personal assistants we use today will become even more intuitive. They may
                    learn your likes and dislikes over time, anticipating your needs without you needing to ask—a true
                    game changer in hospitality!
                </div>
                <div style="display: flex;justify-content: center;">
                    <img src="assets/mobile-images/Personal Assistants.png">
                </div>
            </div>
        </div>

        <div id="AI-Implementation" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">How to Implement AI in Your Hospitality Business</h2>
            <div class="info-text">Thinking about bringing AI into your hospitality business? Here’s how to get started:
            </div>
            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head-text">1. Identify Your Goals:
                    &nbsp;</span>Start by asking what you want to achieve with AI. Is it enhancing guest experiences,
                improving operational efficiency, or increasing revenue? Defining clear objectives will help guide your
                strategy.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head-text">1. Identify Your Goals
                </h3>
                <div class="info-text">Start by asking what you want to achieve with AI. Is it enhancing guest
                    experiences, improving operational efficiency, or increasing revenue? Defining clear objectives will
                    help guide your strategy.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head-text">2. Choose the Right Tools:
                    &nbsp;</span>Look for AI solutions that match your goals. Whether it’s a chatbot for customer
                service or smart room technologies, selecting the right tools can significantly impact your success.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head-text">2. Choose the Right Tools
                </h3>
                <div class="info-text">Look for AI solutions that match your goals. Whether it’s a chatbot for customer
                    service or smart room technologies, selecting the right tools can significantly impact your success.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head-text">3. Train Your Team:
                    &nbsp;</span>Ensure your staff is trained to use AI technologies effectively. A knowledgeable team
                will maximize the benefits of these tools and create a seamless experience for guests.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head-text">3. Train Your Team
                </h3>
                <div class="info-text">Ensure your staff is trained to use AI technologies effectively. A knowledgeable
                    team will maximize the benefits of these tools and create a seamless experience for guests.
                </div>
            </div>

            <div class="info-text" *ngIf="!mobile"><span class="container-sub-head-text">4. Gather Feedback:
                    &nbsp;</span>Continuously collect feedback from guests and staff to understand how well your AI
                initiatives are performing. Use this information to make adjustments and improvements over time.
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column" *ngIf="mobile">
                <h3 class="container-sub-head-text">4. Gather Feedback
                </h3>
                <div class="info-text">Continuously collect feedback from guests and staff to understand how well your
                    AI initiatives are performing. Use this information to make adjustments and improvements over time.
                </div>
            </div>
        </div>

        <div id="Conclusion" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Conclusion</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div *ngIf="!mobile">
                    <img src="assets/images/conclusion.png">
                </div>
                <div *ngIf="mobile" style="place-content: center;display: flex;">
                    <img src="assets/mobile-images/conclusion.png">
                </div>
                <div class="info-text">The use of AI in hotels, while being a buzzword for some, has completely changed
                    the way guests engage with travel and accommodation. Hospitality providers like hotel management
                    software are now able to utilize AI and withstand the competition by offering jaw-dropping
                    experiences while managing the business easily and effortlessly. As this trend, which is turning
                    into a value, continues developing, the options are limitless. As a hotelier looking to raise your
                    number of voice compliments or increase the experience of the next best tourist site for the
                    travelers, the future is bold as this technology is the future. With artificial intelligence on
                    their side, the hospitality sector can manage to make every accommodation experience look like the
                    highlight of a particular trip. In this new paradigm, everyone gets what they want—amazing trips for
                    guests and prosperous businesses.
                </div>
                <div fxLayout="row" fxLayoutGap="3%" class="social-media">
                    <div>
                        <img src="assets/images/whatsapp.png">
                    </div>
                    <div>
                        <img src="assets/images/linkedin.png">
                    </div>
                    <div>
                        <img src="assets/images/email.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div #faqSection class="similar-article width100">
    <div style="padding: 0% 10%;">
        <h2 class="container-head">Similar articles</h2>
    </div>
    <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="card" *ngFor="let article of articles" (click)="article.onClick()">
            <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div class="card-image">
                    <img [src]="article.image" alt="{{ article.title }}">
                </div>
                <div class="card-content">
                    <div class="card-header">{{ article.title }}</div>
                    <div class="card-text">{{ article.text }}</div>
                </div>
                <div style="margin: 0 5% 5% 5%;">
                    <span class="readmore-text">Read More</span>&nbsp;&nbsp;
                    <span class="card-text">({{ article.readTime }})</span>
                </div>
            </div>
        </mat-card>
    </div>
</div>

<div class="faq-container" id="faq-section">
    <h2 class="faq-header">Frequently Asked Questions</h2>
    <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
            {{ faq.question }}
            <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
            <p [innerHTML]="faq.answer"></p>
        </div>
    </div>
</div>

<div class="learn-more-contents" fxLayout="column" fxLayoutGap="2%">
    <div class="learn-more-head">
        Not sure how to get started?
    </div>
    <div class="learn-text">
        Join thousands of hoteliers from around the world already leveraging the power of AI, with GoFriday.
    </div>
    <div>
        <button class="book-btn" (click)="openCalendlyPopup()">
            Book a Demo
        </button>
    </div>
</div>
<div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div>
            <img src="assets/images/contact-us.png">
        </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
        <div class="form-heading">
            Contact Us
        </div>
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                            placeholder="Email" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
        Contact Us
    </div>
    <div>
        <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
        <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                            autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<app-footer></app-footer>