<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>E-Concierge Room Management
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Iceberg – E-Conference Room Management
            </h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/E-Conference Room Management.png" />
            </div>
            <div class="guest-app-child-text">Transform your conference and meeting room operations with Iceberg
                E-Conference Room Management. Designed to optimize space utilization, streamline bookings, and improve
                coordination, this module makes managing your conference facilities efficient and hassle-free and
                guest-focused.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/E-Conference Room Management.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            Easy Room Booking & Scheduling
        </h2>
        <div class="guest-app-child-text2">
            Simplify the booking process for meeting and conference rooms with Iceberg’s intuitive platform. Allow
            guests and staff to reserve rooms effortlessly, ensuring that each space is used to its fullest potential.
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Real-Time-Availability.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Real-Time Availability
                    </h3>
                    <div class="why-choose-text">Check room schedules and book in seconds.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/One-Tap Service Requests.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Flexible Booking Options
                    </h3>
                    <div class="why-choose-text">Reserve by hour, half-day, or full-day to suit varying needs.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Multi-Channel Integration.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Automated Reminders
                    </h3>
                    <div class="why-choose-text">Keep participants informed with automated notifications to reduce
                        no-shows and enhance their experience.
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="background: #F9F9F9;width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Benefit-room-utilization.png">
                    </div>
                    <div fxLayout="column" fxFlex="35%" fxLayoutGap="3%">
                        <h2 class="features-heading">
                            Benefit
                        </h2>
                        <div class="features-sub-text">
                            Optimize room utilization, minimize scheduling conflicts, and streamline the booking
                            process.
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <h2 class="features-heading">
                        Benefit
                    </h2>
                    <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Benefit-room-utilization.png">
                        </div>
                        <div class="features-sub-text" style="margin-top: 20%;">
                            Optimize room utilization, minimize scheduling conflicts, and streamline the booking
                            process.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <div class="dynamic-pricing-section">
            <h2 class="guest-app-child-heading2">
                Advanced Equipment & Resource Management
            </h2>
            <div class="guest-app-child-text2" style="padding-top: 8px;">
                Ensure that your conference rooms are always ready with Iceberg’s equipment and resource management
                tools. From AV equipment to video conferencing setups, stay ahead of your guests' expectations:
            </div>
            <div class="card-container">
                <mat-card *ngFor="let feature of features"
                    [ngClass]="{'feature-card': !feature.imageOnly, 'image-only-card': feature.imageOnly}"
                    (mouseenter)="onHover(feature)" (mouseleave)="onLeave(feature)"
                    [class.hidden-mobile]="feature.imageOnly && mobile">
                    <!-- Image-only card -->
                    <div *ngIf="feature.imageOnly" class="image-only-content">
                        <img [src]="feature.image" alt="Feature Image" />
                    </div>

                    <!-- Regular card with hover effects -->
                    <div *ngIf="!feature.imageOnly" class="card-content">
                        <div class="default-content">
                            <span>{{ feature.title }}</span>
                        </div>

                        <div class="hover-content" [ngClass]="{ 'show-hover-content': feature.isHovered }">
                            <div class="hover-image">
                                <img [src]="feature.image" alt="Feature Icon" />
                            </div>
                            <p>{{ feature.description }}</p>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <h3 class="guest-app-child-heading2">
            Customizable Room Configurations
        </h3>
        <div class="guest-app-child-text2" style="padding-top: 8px;">
            Iceberg’s E-Conference Room Management supports a variety of room layouts, making it easy to customize each
            space according to event requirements. Ensure every guest feels catered to with flexible space options:
        </div>
        <div class="knowledge-row" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Configurable Spaces.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Configurable Spaces
                        </div>
                        <div class="knowledge-card-text">
                            Analyze response times, completion rates, and guest feedback.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/actionable tasks.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Seating Arrangements
                        </div>
                        <div class="knowledge-card-text">
                            Pre-set and modify seating layouts with ease.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Task Assignments.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Room Personalization
                        </div>
                        <div class="knowledge-card-text">
                            Add notes or special instructions for unique meeting needs.
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <div class="features-section1 width100" fxLayout="column" *ngIf="!mobile">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="26%" fxLayoutGap="4%">
                    <div class="features-heading">
                        Benefit
                    </div>
                    <div class="features-sub-text">
                        Flexibly adapt spaces for diverse events, boost guest satisfaction, and improve service quality.
                    </div>
                </div>
                <div class="features-image-container-right">
                    <img class="features-images2" src="assets/images/benefit-image-guest-satisfaction.png"
                        alt="Guest App">
                </div>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
            <div fxLayout="column" fxLayoutAlign="space-between center">
                <h2 class="features-heading">
                    Benefit
                </h2>
                <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/benefit-image-guest-satisfaction.png">
                    </div>
                    <div class="features-sub-text" style="margin-top: 20%;">
                        Flexibly adapt spaces for diverse events, boost guest satisfaction, and improve service quality.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #FFFFFF;">
        <h2 class="guest-app-child-heading2">
            Data & Analytics for Better Management
        </h2>
        <div class="guest-app-child-text2">
            Gain valuable insights into room utilization and booking trends with Iceberg’s powerful reporting tools.
            Make data-driven decisions to maximize your space's efficiency and improve guest experiences.
        </div>

        <div class="knowledge-row1" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Utilization Reports.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Utilization Reports
                        </div>
                        <div class="knowledge-card-text">
                            Analyze which rooms are most in demand and optimize schedules.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Booking Trends.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Booking Trends
                        </div>
                        <div class="knowledge-card-text">
                            Understand peak booking times and adjust staffing accordingly.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Guest Feedback Image.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Guest Feedback
                        </div>
                        <div class="knowledge-card-text">
                            Collect feedback on meeting room experiences to make continuous improvements.
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Benefit-menu-decisions.png">
                    </div>
                    <div fxLayout="column" fxFlex="35%" fxLayoutGap="3%">
                        <h2 class="features-heading">
                            Benefit
                        </h2>
                        <div class="features-sub-text">
                            Make informed decisions, improve space management, and enhance service delivery.
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <h2 class="features-heading">
                        Benefit
                    </h2>
                    <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Benefit-menu-decisions.png">
                        </div>
                        <div class="features-sub-text" style="margin-top: 20%;">
                            Make informed decisions, improve space management, and enhance service delivery.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div fxLayout="row" class="info-container1 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div fxFlex="48%">
            <div>
                <h2 class="header">Why Iceberg – E-Conference Room Management?</h2>
            </div>
            <div class="info-text">
                Iceberg E-Conference Room Management is ideal for hotels that host business meetings, seminars, or large
                conferences. This module streamlines the entire conference room process, making it more efficient for
                both staff and guests, ensuring a seamless and high-quality experience for hotel visitors.
            </div>
        </div>
        <div class="contact-image" fxFlex="40%">
            <img src="assets/images/E-Conference Room Management1.png">
        </div>
    </div>
    <div fxLayout="column" class="info-container1 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Why Iceberg – E-Conference Room Management?</h2>
            </div>
            <div class="contact-image" style="text-align:center;">
                <img src="assets/mobile-images/E-Conference Room Management1.png">
            </div>
            <div class="info-text">
                Iceberg E-Conference Room Management is ideal for hotels that host business meetings, seminars, or large
                conferences. This module streamlines the entire conference room process, making it more efficient for
                both staff and guests, ensuring a seamless and high-quality experience for hotel visitors.
            </div>
        </div>
    </div>


    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img src="assets/images/space management with Iceberg.png">
        </div>
        <div fxFlex="45%">
            <div class="info-text">
                Enhance your conference and meeting space management with Iceberg. Improve booking efficiency, resource
                management, and guest satisfaction—all while reducing overhead and operational complexities.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/space management with Iceberg.png">
            </div>
            <div class="info-text">
                Enhance your conference and meeting space management with Iceberg. Improve booking efficiency, resource
                management, and guest satisfaction—all while reducing overhead and operational complexities.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>