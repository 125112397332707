import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../popup/popup.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const Calendly: any;

@Component({
  selector: 'app-blog14',
  templateUrl: './blog14.component.html',
  styleUrls: ['./blog14.component.css']
})
export class Blog14Component {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'What is the best CRM software for small hotels?',
      answer: 'GoFriday’s CRM offers an intuitive interface and robust automation tools, perfect for simplifying guest management for smaller hotels.',
      open: false
    },
    {
      question: 'How can CRM software improve guest engagement?',
      answer: 'By automating personalized communications and offering insights into guest preferences, CRM software helps you engage guests at every stage of their journey.',
      open: false
    },
    {
      question: 'Does CRM software integrate with existing property management systems (PMS)?',
      answer: 'Yes, GoFriday’s CRM integrates seamlessly with popular PMS platforms, ensuring smooth data flow between systems.',
      open: false
    },
    {
      question: 'Can CRM software help increase direct hotel bookings?',
      answer: 'Absolutely. With automated marketing campaigns and personalized guest engagement, CRM software can drive more direct bookings and reduce reliance on third-party platforms.',
      open: false
    },
    {
      question: 'What are the pricing plans for hotel CRM software?',
      answer: 'GoFriday offers flexible pricing models tailored to different hotel sizes. Contact us to find the package that suits your needs.',
      open: false
    },
    {
      question: 'How does CRM software ensure data security for hotels?',
      answer: 'GoFriday prioritizes data security with advanced encryption and compliance with industry regulations to keep your guest information safe.',
      open: false
    },
    {
      question: 'Is there a free trial available for hotel CRM software?',
      answer: 'Yes, we offer a free demo so you can explore the features of GoFriday’s CRM before making a commitment.',
      open: false
    },
  ];

  articles = [
    {
      title: 'HICSA and GoFriday: A Perfect Correlation',
      text: 'The recent HICSA (Hotel Investment Conference - South Asia) 2024 conference...',
      image: 'assets/images/HICSA-image.png',
      readTime: '5 Minutes Read',
      onClick: () => this.viewHicsa()
    },
    {
      title: 'THE GUEST-CENTRIC REVOLUTION',
      text: 'Artificial Intelligence (AI) has transitioned from a futuristic concept to....',
      image: 'assets/images/guest-image.png',
      readTime: '3 Minutes Read',
      onClick: () => this.viewRevolution()
    },
    {
      title: 'Hotel cancellation policy: everything you need to know',
      text: 'However simple it may sound, booking a hotel can be harder than one imagines...',
      image: 'assets/images/cancellation-policy-image.png',
      readTime: '7 Minutes Read',
      onClick: () => this.viewCancellationPolicy()
    },
  ]

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'CRM-Software',
      'Why-Choose-CRM-Software',
      'Key-Features',
      'Hotels-Benefits',
      'How-To-Get-Started',
      'Conclusion'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewHicsa() {
    this.router.navigateByUrl('blog/hicsa');
    window.scrollTo(0, 0);
  }

  viewRevolution() {
    this.router.navigateByUrl('blog/guest-centric-revolution');
    window.scrollTo(0, 0);
  }

  viewCancellationPolicy() {
    this.router.navigateByUrl('blog/what-is-a-hotel-cancellation-policy');
    window.scrollTo(0, 0);
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  viewLinkedIn() {
    const linkedInUrl = environment.LINKEDIN_URL;
    const websiteUrl = `${environment.WEBSITE_URL}/blog/gofriday-best-crm-software-for-hotels`;
    const fullUrl = `${linkedInUrl}${websiteUrl}`;
    window.open(fullUrl, "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.isPopupOpen = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (!!data?.object?.ID) {
              const dialogRef = this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
              dialogRef.afterClosed().subscribe(() => {
                this.isPopupOpen = false;
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewCRMSoftware() {
    this.scrollToSection('CRM-Software');
  }

  viewWhyChooseCRMSoftware() {
    this.scrollToSection('Why-Choose-CRM-Software');
  }

  viewKeyFeatures() {
    this.scrollToSection('Key-Features');
  }

  viewHotelsBenefits() {
    this.scrollToSection('Hotels-Benefits');
  }

  viewHowToGetStarted() {
    this.scrollToSection('How-To-Get-Started');
  }

  viewConclusion() {
    this.scrollToSection('Conclusion');
  }


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

}
