<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>Feedback Management System
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Feedback Management: Operational Insights for Superior Guest-Centric
                Performance
            </h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/Feedback Management System.png" />
            </div>
            <div class="guest-app-child-text">With CLIO – Feedback Management, GoFryDay makes it easy to collect,
                analyze, and act on feedback, helping you resolve issues quickly and deliver unforgettable guest
                experiences.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/Feedback Management System.png" />
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <div fxLayout="column" class="why-choose-content-container">
            <h2 class="guest-app-child-heading2" style="padding-bottom: 7%;">
                Operational Features of Feedback Management System
            </h2>
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Real-Time Feedback Alerts.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Real-Time Feedback Alerts
                    </h3>
                    <div class="why-choose-text">Automatically notify relevant teams when feedback highlights
                        operational issues, ensuring immediate resolution and minimal disruption.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Multi-Channel Integration.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Multi-Channel Integration
                    </h3>
                    <div class="why-choose-text">Capture feedback from in-app surveys, email requests, or QR codes
                        placed across key touchpoints, ensuring seamless input collection at every stage.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Performance Insights.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Staff Performance Insights
                    </h3>
                    <div class="why-choose-text">Track team performance through guest feedback, enabling managers to
                        identify top-performing staff or areas requiring additional training.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Root Cause Analysis.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Root Cause Analysis
                    </h3>
                    <div class="why-choose-text">Dive deep into recurring issues with AI-driven analysis to pinpoint
                        operational bottlenecks or service inconsistencies.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Task Assignments.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Integrated Task Assignments
                    </h3>
                    <div class="why-choose-text">Feedback highlighting specific actions, like room service delays or
                        maintenance issues, can automatically trigger tasks in CLIO’s Task Management system for swift
                        resolution.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Dashboard Opportunities.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Upselling Comprehensive Dashboard Opportunities
                    </h3>
                    <div class="why-choose-text">Access real-time feedback insights alongside operational data on a
                        centralized dashboard, enabling you to track trends, performance metrics, and service
                        improvements.
                    </div>
                </div>
            </div>
        </div>
        <h2 class="guest-app-child-heading2">
            Operational Benefits for Your Hotel
        </h2>
        <div class="benefits-wrapper">
            <button (click)="bannerScrollLeft()" class="scroll-btn" *ngIf="!mobile">
                <iconify-icon icon="material-symbols:keyboard-arrow-left" class="navigate-btn"></iconify-icon>
            </button>
            <div #scrollableContainer class="benefits-container">
                <ng-container *ngFor="let benefit of benefits; let i = index">
                    <div class="benefit" fxLayout="column" fxLayoutAlign="center center">
                        <div class="benefit-number">{{ benefit.number }}</div>
                        <h3>{{ benefit.title }}</h3>
                        <p>{{ benefit.description }}</p>
                    </div>
                    <mat-divider *ngIf="i < benefits.length - 1 && !mobile"></mat-divider>
                </ng-container>
            </div>
            <button (click)="bannerScrollRight()" class="scroll-btn" *ngIf="!mobile">
                <iconify-icon icon="material-symbols:keyboard-arrow-right" class="navigate-btn"></iconify-icon>
            </button>
        </div>
    </div>

    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="guest-app-child-heading2">
            How CLIO - Feedback Management system Works
        </h3>
        <div class="benefits-wrapper1">
            <button (click)="bannerScrollLeft1()" class="scroll-btn" *ngIf="!mobile">
                <iconify-icon icon="material-symbols:keyboard-arrow-left" class="navigate-btn"></iconify-icon>
            </button>
            <div class="knowledge-row1" fxLayout="row" fxLayoutAlign="center center"
                #scrollableContainerFeedbackManagement>
                <mat-card class="knowledge-card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="knowledge-image">
                            <img class="card-images" src="assets/images/Feedback Capture.png">
                        </div>
                        <div class="knowledge-content">
                            <div class="knowledge-card-header">
                                Feedback Capture
                            </div>
                            <div class="knowledge-card-text">
                                Guests share feedback across multiple channels, such as apps, emails, or QR codes.
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="knowledge-card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="knowledge-image">
                            <img class="card-images" src="assets/images/AI-Driven Analysis.png">
                        </div>
                        <div class="knowledge-content">
                            <div class="knowledge-card-header">
                                AI-Driven Analysis
                            </div>
                            <div class="knowledge-card-text">
                                CLIO evaluates feedback, categorizing it into actionable insights focused on guest needs
                                and operational improvements.
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="knowledge-card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="knowledge-image">
                            <img class="card-images" src="assets/images/actionable tasks.png">
                        </div>
                        <div class="knowledge-content">
                            <div class="knowledge-card-header">
                                Task Automation
                            </div>
                            <div class="knowledge-card-text">
                                Identified issues are converted into actionable tasks, assigned to the right teams
                                automatically.
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="knowledge-card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="knowledge-image">
                            <img class="card-images" src="assets/images/Performance Tracking.png">
                        </div>
                        <div class="knowledge-content">
                            <div class="knowledge-card-header">
                                Performance Tracking
                            </div>
                            <div class="knowledge-card-text">
                                Dashboards provide real-time insights into staff and service performance, enabling
                                informed decision-making.
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="knowledge-card">
                    <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                        <div class="knowledge-image">
                            <img class="card-images" src="assets/images/Continuous Improvement.png">
                        </div>
                        <div class="knowledge-content">
                            <div class="knowledge-card-header">
                                Continuous Improvement
                            </div>
                            <div class="knowledge-card-text">
                                Data-driven recommendations guide long-term operational enhancements.
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <button (click)="bannerScrollRight1()" class="scroll-btn" *ngIf="!mobile">
                <iconify-icon icon="material-symbols:keyboard-arrow-right" class="navigate-btn"></iconify-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row" class="info-container3 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div fxFlex="48%">
            <div>
                <h2 class="header">Part of the CLIO Operations Suite</h2>
            </div>
            <div class="info-text">
                As a vital sub-product of the CLIO Suite, Feedback Management integrates seamlessly with tools like Task
                Management and Inventory Control, creating a cohesive ecosystem that optimizes every facet of hotel
                operations.
            </div>
        </div>
        <div class="contact-image" fxFlex="40%">
            <img src="assets/images/CLIO Operations Suite.png">
        </div>
    </div>
    <div fxLayout="column" class="info-container3 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Part of the CLIO Operations Suite</h2>
            </div>
            <div class="contact-image" style="text-align:center;">
                <img src="assets/mobile-images/CLIO Operations Suite.png">
            </div>
            <div class="info-text">
                As a vital sub-product of the CLIO Suite, Feedback Management integrates seamlessly with tools like Task
                Management and Inventory Control, creating a cohesive ecosystem that optimizes every facet of hotel
                operations.
            </div>
        </div>
    </div>

    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img src="assets/images/Elevate Operations with CLIO.png">
        </div>
        <div fxFlex="45%">
            <div>
                <h2 class="header">Elevate Operations with CLIO - Feedback Management</h2>
            </div>
            <div class="info-text">
                Turn feedback into an operational advantage. CLIO - Feedback Management streamlines workflows, enhances
                team performance, and ensures your operations are always aligned with guest expectations.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Elevate Operations with CLIO - Feedback Management</h2>
            </div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/Elevate Operations with CLIO.png">
            </div>
            <div class="info-text">
                Turn feedback into an operational advantage. CLIO - Feedback Management streamlines workflows, enhances
                team performance, and ensures your operations are always aligned with guest expectations.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>