import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { colorObj } from 'src/shared/color-object';
import { PopupComponent } from '../popup/popup.component';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare const Calendly: any;

@Component({
  selector: 'app-blog18',
  templateUrl: './blog18.component.html',
  styleUrls: ['./blog18.component.css']
})
export class Blog18Component {
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  disabled: boolean = false;
  mobile: boolean = false;
  showStickyOverview: boolean = true;
  activeSection: string = '';
  isPopupOpen: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;
  @ViewChild('faqSection') faqSectionRef!: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  faqs = [
    {
      question: 'Can small businesses use cloud based CRM solutions?',
      answer: 'Absolutely! Cloud based CRM solutions are designed to be scalable and can easily cater to the needs of small businesses, making them an ideal choice for hotels of all sizes.',
      open: false
    },
    {
      question: 'Is cloud based CRM secure?',
      answer: 'Yes, reputable cloud based CRM providers implement stringent security measures, including data encryption and regular security audits, to safeguard your information.',
      open: false
    },
    {
      question: 'How can cloud-based CRM improve customer relationships?',
      answer: 'By providing a centralized platform for managing customer interactions, cloud-based CRM enables personalized communication and better tracking of guest preferences, leading to enhanced relationships.',
      open: false
    },
    {
      question: 'How does integration with other tools work in cloud-based CRM?',
      answer: 'Cloud-based CRM systems typically offer APIs and integration options that allow them to work seamlessly with various hotel management tools, creating a fluid flow of information across your operations.',
      open: false
    },
    {
      question: 'Can I access cloud-based CRM on mobile devices?',
      answer: 'Yes, most cloud-based CRM systems are accessible on mobile devices, allowing staff to access critical information and manage customer relationships while on the go.',
      open: false
    },
    {
      question: 'How does cloud-based CRM support remote work?',
      answer: 'The cloud-based nature of these systems allows employees to access data from anywhere with an internet connection, making them ideal for remote work situations.',
      open: false
    },
    {
      question: 'What types of businesses benefit the most from cloud-based CRM?',
      answer: 'Any business that interacts with customers can benefit from cloud-based CRM, but it is particularly advantageous for service-oriented industries like hospitality.',
      open: false
    },
    {
      question: 'Are cloud-based CRM solutions customizable to my business needs?',
      answer: 'Yes, many cloud-based CRM solutions offer customization options, allowing you to tailor the system to fit your unique business processes and requirements.',
      open: false
    },
    {
      question: 'How does cloud-based CRM improve sales team efficiency?',
      answer: 'By automating routine tasks, providing real-time insights, and facilitating better communication, cloud-based CRM helps sales teams work more efficiently and focus on building relationships and closing deals.',
      open: false
    }
  ];

  articles = [
    {
      title: 'THE GUEST-CENTRIC REVOLUTION',
      text: 'Artificial Intelligence (AI) has transitioned from a futuristic concept to....',
      image: 'assets/images/guest-image.png',
      readTime: '3 Minutes Read',
      onClick: () => this.viewRevolution()
    },
    {
      title: 'Hotel Room Management System: A Guide to Efficiency',
      text: 'Managing hotel rooms, from housekeeping to guest requests, can be...',
      image: 'assets/images/Hotel Room Management-image.png',
      readTime: '3 Minutes Read',
      categoryId: '3',
      onClick: () => this.viewHotelRoomManagement()
    },
    {
      title: 'What Does Travel Sustainable Mean? Key Concepts Explained',
      text: 'Travel is a means by which individuals open themselves to new cultures...',
      image: 'assets/images/Travel Sustainable-image.png',
      readTime: '5 Minutes Read',
      categoryId: '6',
      onClick: () => this.viewTravelSustainable()
    }
  ]

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.checkScreenWidth();
  }

  ngAfterViewInit(): void {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        setTimeout(() => this.scrollToSection(fragment), 0);
      }
    });
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveSection();
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const faqSectionTop = this.faqSectionRef.nativeElement.offsetTop;
    const threshold2 = 530;
    if (!this.isPopupOpen) {
      this.showStickyOverview = scrollPosition < (faqSectionTop - threshold2);
    } else {
      this.showStickyOverview = false;
    }
  }

  private updateActiveSection() {
    const sections = [
      'Cloud-Based-CRM',
      'Cloud-CRM-Benefits',
      'Key-Features',
      'Choose-Right-Cloud-Based-CRM',
      'Why-Choose-Cloud-Based-CRM',
      'Conclusion'
    ];
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let currentActiveSection: string = '';
    for (const sectionId of sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          currentActiveSection = sectionId;
          break;
        }
      }
    }
    if (this.activeSection !== currentActiveSection) {
      this.activeSection = currentActiveSection;
    }
  }

  viewRevolution() {
    this.router.navigateByUrl('blog/guest-centric-revolution');
    window.scrollTo(0, 0);
  }

  viewTravelSustainable() {
    this.router.navigateByUrl('blog/what-does-travel-sustainable-mean');
    window.scrollTo(0, 0);
  }

  viewHotelRoomManagement() {
    this.router.navigateByUrl('blog/hotel-room-management-system');
    window.scrollTo(0, 0);
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  viewLinkedIn() {
    const linkedInUrl = environment.LINKEDIN_URL;
    const websiteUrl = `${environment.WEBSITE_URL}/blog/cloud-based-crm-benefits-for-hotel-business`;
    const fullUrl = `${linkedInUrl}${websiteUrl}`;
    window.open(fullUrl, "_blank");
  }

  backButton() {
    this.router.navigateByUrl('blog');
    window.scrollTo(0, 0);
  }

  submitContactForm() {
    if (this.contactUsForm.invalid) {
      return;
    }
    this.disabled = true;
    this.isPopupOpen = true;
    this.recaptchaV3Service.execute('contactFormSubmit').subscribe({
      next: (token) => {
        let obj = {
          "NAME": this.contactUsForm.value.name,
          "EMAIL": this.contactUsForm.value.email,
          "MESSAGE": this.contactUsForm.value.message,
          "TNC": true
        };

        this.hotelService.contactUs(obj, token).subscribe({
          next: (data) => {
            this.disabled = false;
            if (!!data?.object?.ID) {
              const dialogRef = this.dialog.open(PopupComponent, {
                width: '50%',
                data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' },
                disableClose: true
              });
              dialogRef.afterClosed().subscribe(() => {
                this.isPopupOpen = false;
              });
            } else {
              this.notify.showNotification(
                data.message,
                "top",
                (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
                data.status
              );
            }
            this.contactUsForm.reset();
          },
          error: (e) => {
            this.disabled = false;
            this.contactUsForm.reset();
            this.notify.showNotification(
              e.error.message,
              "top",
              (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
              e.error.status
            )
          }
        })
      },
      error: () => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          'reCAPTCHA verification failed. Please try again.',
          "top",
          "error",
          500
        );
      }
    });
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewCloudBasedCRM() {
    this.scrollToSection('Cloud-Based-CRM');
  }

  viewCloudCRMBenefits() {
    this.scrollToSection('Cloud-CRM-Benefits');
  }

  viewKeyFeatures() {
    this.scrollToSection('Key-Features');
  }

  viewChooseRightCloudBasedCRM() {
    this.scrollToSection('Choose-Right-Cloud-Based-CRM');
  }

  viewWhyChooseCloudBasedCRM() {
    this.scrollToSection('Why-Choose-Cloud-Based-CRM');
  }

  viewConclusion() {
    this.scrollToSection('Conclusion');
  }


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

}
