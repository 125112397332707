<app-header></app-header>
<div class="container">
    <div>
        <div class="title-img-container">
            <img class="title-img" src="../../../assets/images/blog-title.png">
        </div>
        <div fxLayout="column" class="blogHeader width100" fxLayoutGap="12px">
            <div class="blog-head">
                The Knowledge Hub
            </div>
            <div class="blog-description">
                We don't just inform, we empower. Bridging the Gap Between Knowledge and Action 
            </div>
            <div class="search-container">
                <input type="text" [(ngModel)]="searchQuery" (input)="filterArticles()" placeholder="Search"
                    class="search-input">
                <button class="search-button">
                    <iconify-icon icon="bitcoin-icons:search-outline"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="category-container" fxLayout="column" fxLayoutGap="1%">
            <span class="focus">Select a Focus</span>
            <div class="category-list">
                <button *ngFor="let category of categories" (click)="filterArticlesByCategory(category.id)"
                    [class.active]="selectedCategoryId === category.id">
                    {{ category.name }}
                </button>
            </div>
        </div>
        <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
            <mat-card *ngFor="let article of filteredArticles" class="card" (click)="article.onClick()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img [src]="article.image" alt="{{ article.title }}">
                    </div>
                    <div class="card-content">
                        <div class="card-header">{{ article.title }}</div>
                        <div class="card-text">{{ article.text }}</div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;">
                        <span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">({{ article.readTime }})</span>
                    </div>
                </div>
            </mat-card>
        </div>

        <!-- <input type="text" [(ngModel)]="searchQuery" placeholder="Search articles..." />
        <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="card" (click)="viewHicsa()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/HICSA-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            HICSA and GoFriday: A Perfect Correlation
                        </div>
                        <div class="card-text">
                            The recent HICSA (Hotel Investment Conference - South Asia) 2024 conference...
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewRevolution()" *ngFor="let article of filteredArticles">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img [src]="article.image" alt="{{ article.title }}">
                    </div>
                    <div class="card-content">
                        <div class="card-header">{{ article.title }}</div>
                        <div class="card-text">{{ article.text }}</div>
                      </div>
                      <div style="margin: 0 5% 5% 5%;">
                        <span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">({{ article.readTime }})</span>
                      </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewHotelCRM()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/CRM-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            What Is A Hotel CRM & How It Maximizes Hotel Success?
                        </div>
                        <div class="card-text">
                            Discover how hotel CRM systems can enhance guest experience...
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewFeaturesOfCRM()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/Features-CRM-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            9 Core Features of a CRM System for Hotels
                        </div>
                        <div class="card-text">
                            The modern hotel industry is driven by data: customer data! In the competitive world...
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>

                </div>
            </mat-card>
            <mat-card class="card" (click)="viewAutomatedHotel()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/Automated-Hotel-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            Exploring Automated Hotel
                        </div>
                        <div class="card-text">
                            Ever wondered how those fancy hotels seem to know exactly what you want before you even ask?
                            It's all thanks to a magical....
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewHotelProfitMargin()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/Hotel-Profit-Margin-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            What is a Hotel Profit Margin?
                        </div>
                        <div class="card-text">
                            Operating a hotel in this age is no piece of cake, is it? Everyone would agree that with
                            great rivalry and unending evolution
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewHospitalityManagement()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/Hospitality-Management-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            What Is Hospitality Management
                        </div>
                        <div class="card-text">
                            Hospitality management is both art and science of managing multiple factors of the
                            hospitality industry
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewHotelRevenue()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/Hotel-Revenue-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            13 Smart Strategies on How to Improve Hotel Revenue
                        </div>
                        <div class="card-text">
                            In recent years, the need to maximize hotel revenue has been hotly argued within the..
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewCancellationPolicy()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/cancellation-policy-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            Hotel cancellation policy: everything you need to know
                        </div>
                        <div class="card-text">
                            However simple it may sound, booking a hotel can be harder...
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewCoreFeaturesOfACrm()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/5-core-features-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            5 core features of a crm system for hotels
                        </div>
                        <div class="card-text">
                            Let’s consider a situation where you know your guests’ preferences....
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="knowledge-card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewTravelCheaper()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/Travel-Cheaper-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            how to travel cheaper: insider tips for affordable adventures
                        </div>
                        <div class="card-text">
                            Apart from being one of the best activities during a vacation, travel can...
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewHotelAndMotel()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/hotel-and-motel-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            what’s the difference between hotel and motel
                        </div>
                        <div class="card-text">
                            When planning your next trip, it is necessary to understand the difference...
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewTravelAgency()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/travel-agency-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            Step-by-step guide: how to start a travel agency
                        </div>
                        <div class="card-text">
                            Business Opportunities in Travel and Tourism for the Passionate Traveler has...
                        </div>
                    </div>
                    <div style="margin: 0 5% 5% 5%;"><span class="readmore-text">Read More</span>&nbsp;&nbsp;
                        <span class="card-text">(4 Minutes Read)</span>
                    </div>
                </div>
            </mat-card>
        </div> -->
        <div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
            <div class="contact-image" fxLayout="column" fxFlex="50%">
                <div>
                    <p class="single-line">We're always happy to hear from you.</p>
                </div>
                <div>
                    <img src="assets/images/contact-us.png">
                </div>
            </div>
            <div class="contact-form" fxLayout="column" fxFlex="50%">
                <div class="form-heading">
                    Contact Us
                </div>
                <div class="form">
                    <form [formGroup]="contactUsForm">
                        <div fxLayout="column" fxLayoutGap="5%">
                            <div>
                                <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                    maxlength="200" autocomplete="off">
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                    Required
                                </mat-error>
                            </div>
                            <div>
                                <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                                    placeholder="Email" autocomplete="off">
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                    Required
                                </mat-error>
                                <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                    Please provide a valid Email Id
                                </mat-error>
                            </div>
                            <div>
                                <textarea class="text-area" formControlName="message" placeholder="Write message"
                                    maxlength="1500" autocomplete="off"></textarea>
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                    Required
                                </mat-error>
                            </div>
                            <div fxLayout="row" fxLayoutGap="2%">
                                <input type="checkbox" class="check-box" formControlName="tnc" />
                                <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                        (click)="viewPrivacyPolicy()">"Privacy
                                        Policy"</a> and
                                    <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                        Conditions"</a></span>
                            </div>
                        </div>
                    </form>
                </div>
                <div style="text-align: center;">
                    <button
                        [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                        (click)="submitContactForm()"
                        [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                        <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                    </button>
                </div>
            </div>
        </div>
        <div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
            <div class="form-heading">
                Contact Us
            </div>
            <div>
                <p class="single-line">We're always happy to hear from you.</p>
            </div>
            <div style="text-align: center;">
                <img src="assets/images/contact-us.png">
            </div>
            <div class="contact-form" fxLayout="column">
                <div class="form">
                    <form [formGroup]="contactUsForm">
                        <div fxLayout="column" fxLayoutGap="5%">
                            <div>
                                <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                    maxlength="200" autocomplete="off">
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                    Required
                                </mat-error>
                            </div>
                            <div>
                                <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                                    autocomplete="off">
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                    Required
                                </mat-error>
                                <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                    Please provide a valid Email Id
                                </mat-error>
                            </div>
                            <div>
                                <textarea class="text-area" formControlName="message" placeholder="Write message"
                                    maxlength="1500" autocomplete="off"></textarea>
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                    Required
                                </mat-error>
                            </div>
                            <div fxLayout="row" fxLayoutGap="2%">
                                <input type="checkbox" class="check-box" formControlName="tnc" />
                                <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                        (click)="viewPrivacyPolicy()">"Privacy
                                        Policy"</a> and
                                    <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                        Conditions"</a></span>
                            </div>
                        </div>
                    </form>
                </div>
                <div style="text-align: center;">
                    <button
                        [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                        (click)="submitContactForm()"
                        [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                        <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>