import { Component, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare const Calendly: any;

@Component({
  selector: 'app-task-management-software',
  templateUrl: './task-management-software.component.html',
  styleUrls: ['./task-management-software.component.css']
})
export class TaskManagementSoftwareComponent {
  @ViewChild('scrollableContainer', { static: false }) scrollableContainer!: ElementRef;
  @ViewChild('scrollableContainerFeedbackManagement', { static: false }) scrollableContainerFeedbackManagement!: ElementRef;
  mobile: boolean = false;
  public calendlyUrl = environment.CALENDLY_URL;

  benefits = [
    {
      number: '01',
      title: 'Enhanced Efficiency',
      description: 'Eliminate manual coordination and reduce response times with automated task assignments and alerts.'
    },
    {
      number: '02',
      title: 'Improved Accountability',
      description: 'Track task progress and completion in real time, ensuring staff accountability at every step.'
    },
    {
      number: '03',
      title: 'Proactive Issue Management',
      description: 'Address operational bottlenecks and service gaps before they affect guest satisfaction.'
    },
    {
      number: '04',
      title: 'Consistency in Quality',
      description: 'Maintain uniform service standards with SLA and SOP compliance for all tasks.'
    },
    {
      number: '05',
      title: 'Data-Driven Optimization',
      description: 'Use performance insights to streamline operations and enhance team productivity.'
    }
  ];


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(private router: Router) { }
  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  openCalendlyPopup() {
    Calendly.initPopupWidget({ url: this.calendlyUrl });
    return false;
  }

  selectTrial() {
    this.router.navigateByUrl('free-trial');
    window.scrollTo(0, 0);
  }

  bannerScrollRight(): void {
    this.scrollableContainer.nativeElement.scrollTo({
      left: this.scrollableContainer.nativeElement.scrollLeft + 250,
      behavior: 'smooth'
    });
  }

  bannerScrollLeft(): void {
    this.scrollableContainer.nativeElement.scrollTo({
      left: this.scrollableContainer.nativeElement.scrollLeft - 250,
      behavior: 'smooth'
    });
  }

  bannerScrollRight1(): void {
    this.scrollableContainerFeedbackManagement.nativeElement.scrollTo({
      left: this.scrollableContainerFeedbackManagement.nativeElement.scrollLeft + 500,
      behavior: 'smooth'
    });
  }

  bannerScrollLeft1(): void {
    this.scrollableContainerFeedbackManagement.nativeElement.scrollTo({
      left: this.scrollableContainerFeedbackManagement.nativeElement.scrollLeft - 500,
      behavior: 'smooth'
    });
  }


}
