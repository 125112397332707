<!--<div class="container">
    <div fxLayout="column" class="featuresContainer width100" fxLayoutGap="10px">
        <div class="feature-head">
            Why Choose Us?
        </div>
        <div class="feature-desc" *ngIf="!mobile">
            Discover our carefully crafted features designed to make your experience better and boost your success in
            hospitality management. These innovations are set to change the way you manage things, making your journey
            smooth and impressive
        </div>
        <div class="feature-desc" *ngIf="mobile">
            To foster a future where hospitality knows no bounds, leveraging cutting-edge technology to enhance every
            interaction
        </div>
    </div>
    <div fxLayout="column" class="feature-Container width100">
        <div id="GuestApp" fxLayout="row" class="featureContainer width100" fxLayoutAlign="center center">
            <div #feature1 fxLayout="row" class="feature-images" *ngIf="!mobile" fxFlex="65%">
                <div [@vector1]="featureState1">
                    <img src="assets/images/Vector (1).png" class="vector-img">
                </div>
                <div class="circle" [@feature1]="featureState1">
                    <span class="circle-text">{{ guestText }}</span>
                </div>
                <div [@video1]="featureState1" (click)="toggleVideo()">
                    <video #videoPlay1 class="guest-image" controls [poster]="poster1" [muted]="isMuted">
                        <source src="assets/videos/guest-app.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div #feature1 fxLayout="row" class="feature-images" *ngIf="mobile" fxFlex="65%">
                <div [@vector1]="featureState1">
                    <img src="assets/mobile-images/Vector (1) - Mob.png">
                </div>
                <div class="circle" [@feature1]="featureState1">
                    <span class="circle-text">{{ guestText }}</span>
                </div>
                <div [@video1]="featureState1" (click)="toggleVideo()">
                    <video #videoPlay1 class="guest-image-mob" controls [poster]="poster1" [muted]="isMuted">
                        <source src="assets/videos/guest-app.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center start" class="feature-content-container">
                <div class="container-header1">
                    <span class="container-subheader"> Ensuring every stay is tailored to perfection </span>
                </div>
                <div fxLayout="row" fxLayoutGap="4%">
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center"
                            *ngFor="let Guest of guestApp">
                            <span class="content">✓</span>
                            <div>
                                <span class="features-content">{{ Guest }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 15px;">
                </div>

            </div>
        </div>

        <div fxLayout="row" class="featureContainer2 width100" fxLayoutAlign="center center" *ngIf="!mobile">
            <div fxLayout="column" fxLayoutAlign="center start" fxFlex="40%">
                <div class="container-header1">
                    <span class="container-subheader"> Your smart companion for achieving excellence </span>
                </div>
                <div fxLayout="row" fxLayoutGap="4%">
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" *ngFor="let clio of clio">
                            <span class="content">✓</span>
                            <div>
                                <span class="features-content">{{ clio }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 15px;">
                </div>
            </div>
            <div id="CLIO" #feature2 fxLayout="row" class="feature-images" fxFlex="60%">
                <div [@vector2]="featureState2">
                    <img src="assets/images/Vector (1).png" class="vector-img">
                </div>
                <div class="circle-clio" [@feature2]="featureState2">
                    <span class="circle-text2">{{ clioText }}</span>
                </div>
                <div [@video2]="featureState2" (click)="toggleVideo()">
                    <video #videoPlay2 class="clio-image" controls [poster]="poster2" [muted]="isMuted">
                        <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>

        <div id="CLIO" fxLayout="column" class="featureContainer2 width100" fxLayoutAlign="center center" *ngIf="mobile">
            <div #feature2 fxLayout="row" class="feature-images" fxFlex="40%">
                <div [@vector2]="featureState2" class="vector-middle">
                    <img src="assets/mobile-images/Vector (1) - Mob.png">
                </div>
                <div class="circle-clio-mob" [@feature2]="featureState2">
                    <span class="circle-text-clio">{{ clioText }}</span>
                </div>
                <div [@video2]="featureState2" (click)="toggleVideo()">
                    <video #videoPlay2 class="clio-image-mob" controls [poster]="poster2" [muted]="isMuted">
                        <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div fxLayout="column" fxFlex="60%" class="feature-content-container">
                <div class="container-header1">
                    <span class="container-subheader"> Your smart companion for achieving excellence </span>
                </div>
                <div fxLayout="row" fxLayoutGap="40%">
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start start" *ngFor="let clio of clio">
                            <span class="content">✓</span>
                            <div>
                                <span class="features-content">{{ clio }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 15px;">
                </div>
            </div>
        </div>

        <div id="TaskApp" fxLayout="row" class="featureContainer3 width100" fxLayoutAlign="center center">
            <div #feature3 fxLayout="row" class="feature-images" *ngIf="!mobile" fxFlex="65%">
                <div [@vector3]="featureState3">
                    <img src="assets/images/Vector (1).png" class="vector-img">
                </div>
                <div class="circle" [@feature3]="featureState3">
                    <span class="circle-text">{{ taskText }}</span>
                </div>
                <div [@video3]="featureState3" (click)="toggleVideo()">
                    <video #videoPlay3 class="task-image" controls [poster]="poster3" [muted]="isMuted">
                        <source src="assets/videos/task-app.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div #feature3 fxLayout="row" class="feature-images" *ngIf="mobile" fxFlex="65%">
                <div [@vector3]="featureState3">
                    <img src="assets/mobile-images/Vector (1) - Mob.png">
                </div>
                <div class="circle" [@feature3]="featureState3">
                    <span class="circle-text">{{ taskText }}</span>
                </div>
                <div [@video3]="featureState3" (click)="toggleVideo()">
                    <video #videoPlay3 class="guest-image-mob" controls [poster]="poster3" [muted]="isMuted">
                        <source src="assets/videos/task-app.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center start" class="feature-content-container">
                <div class="container-header1">
                    <span class="container-subheader"> Streamlining Technology with Talent </span>
                </div>
                <div fxLayout="row" fxLayoutGap="4%">
                    <div fxLayout="column" fxLayoutGap="20px">
                        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center"
                            *ngFor="let Task of taskApp">
                            <span class="content">✓</span>
                            <div>
                                <span class="features-content">{{ Task }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 15px;">
                    
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
        <div class="contact-image" fxLayout="column" fxFlex="50%">
            <div>
                <p class="single-line">We're always happy to hear from you.</p>
            </div>
            <div>
                <img src="assets/images/contact-us.png">
            </div>
        </div>
        <div class="contact-form" fxLayout="column" fxFlex="50%">
            <div class="form-heading">
                Contact Us
            </div>
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                                placeholder="Email" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
    <div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
        <div class="form-heading">
            Contact Us
        </div>
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div style="text-align: center;">
            <img src="assets/images/contact-us.png">
        </div>
        <div class="contact-form" fxLayout="column">
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                                autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
</div> -->

<div class="dialog-container">
    <div class="popup-container" *ngIf="!mobile">
        <!-- <div class="popup-content" fxLayout="row" fxLayoutGap="4%">
            <div class="features-sidebar" fxLayout="column" fxLayoutGap="5%">
                <div class="sidebar-header">Products</div>
                <div class="sidebar-text"
                    [ngClass]="currentRoute == '/features/guest-app' ? 'selected-btn' : 'guest-app' "
                    (click)="viewGuestApp()">Guest App</div>
                <div class="sidebar-text"
                    [ngClass]="currentRoute == '/features/task-app' ? 'selected-btn' : 'task-app' "
                    (click)="viewTaskApp()">Task App</div>
                <div class="sidebar-text" [ngClass]="currentRoute == '/features/clio' ? 'selected-btn' : 'clio' "
                    (click)="viewCLIO()">CLIO - Admin Panel</div>
            </div>
            <div class="blog-section">
                <span class="blog-header">Most popular blog</span>
                <div class="card-row" fxLayout="row" fxLayoutGap="3%">
                    <mat-card class="card" (click)="viewHotelCRM()">
                        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                            <div class="card-image">
                                <img src="assets/images/blog1-image.png">
                            </div>
                            <div class="card-content">
                                <div class="card-header">
                                    What Is A Hotel CRM & How It Maximizes Hotel Success?
                                </div>
                            </div>
                            <div class="readmore-text">Read More</div>
                        </div>
                    </mat-card>
                    <mat-card class="card" (click)="viewRevolution()">
                        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                            <div class="card-image">
                                <img src="assets/images/blog2-image.png">
                            </div>
                            <div class="card-content">
                                <div class="card-header">
                                    The Guest-Centric Revolution
                                </div>
                            </div>
                            <div class="readmore-text">Read More</div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div> -->

        <div class="popup-content" fxLayout="column" fxLayoutGap="2%">
            <h2 class="sidebar-header">Products</h2>
            <div class="features-content">
                <div class="features-column">
                    <div *ngFor="let section of menuData" [class.selected]="isMainSelected(section)"
                        (click)="selectMainSection(section)" class="features-item">
                        {{ section.title }}
                        <iconify-icon icon="ic:baseline-keyboard-arrow-right" width="24" height="24"
                            *ngIf="isMainSelected(section)"></iconify-icon>
                    </div>
                </div>
                <div class="features-column" *ngIf="selectedMainSection">
                    <div *ngFor="let item of selectedMainSection.items" [class.selected]="isSecondLevelSelected(item)"
                        (click)="item.children ? selectSecondLevel(item) : navigateAndClosePopup(item.path)"
                        class="features-item">
                        {{ item.title }}
                        <iconify-icon icon="ic:baseline-keyboard-arrow-right" width="24" height="24"
                            *ngIf="isSecondLevelSelected(item) && item.children"></iconify-icon>
                    </div>
                </div>
                <div class="features-column"
                    *ngIf="selectedMainSection?.title === 'All-In-One Platform' && selectedSecondLevel?.children">
                    <div *ngFor="let item of selectedSecondLevel?.children"
                        (click)="item.path ? navigateAndClosePopup(item.path) : null" class="features-item clickable">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>