<app-header></app-header>
<div fxLayout="column" class="blogContainer width100">
    <div fxLayout="row">
        <div class="back-arrow" (click)="backButton()">
            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
        </div>
        <h1 class="blog-header">
            Best Hotel CRM Software: Why Choose GoFriday?
        </h1>
    </div>
    <mat-divider class="material-devider"></mat-divider>
    <div fxLayout="row" class="sub-content" fxLayoutAlign="space-evenly">
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">Janhavi Srivastava</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">02 OCT 2024</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <iconify-icon icon="material-symbols-light:share-outline" class="share-icon"></iconify-icon>
            <a class="blog-desc" style="cursor: pointer;" (click)="viewLinkedIn()">LinkedIn</a>
        </div>
    </div>
</div>
<div class="infoContainer width100" fxLayout="column" fxLayoutAlign=" center">
    <div class="Hicsa-prgm-container" *ngIf="!mobile">
        <img src="assets/images/Best Hotel CRM Software.jpg" class="prgm-image">
    </div>
    <div class="Hicsa-prgm-container" *ngIf="mobile">
        <img src="assets/mobile-images/Best Hotel CRM Software.png">
    </div>
    <div class="sticky-overview" fxFlex="35" [ngClass]="{ 'sticky-overview-active': showStickyOverview }">
        <div class="overview-header">Overview</div>
        <ul class="overview-list">
            <li><span (click)="viewCRMSoftware()" [class.active]="activeSection === 'CRM-Software'">What Is CRM Software
                    for Hotel ?</span></li>
            <li><span (click)="viewWhyChooseCRMSoftware()"
                    [class.active]="activeSection === 'Why-Choose-CRM-Software'">Why Choose
                    our CRM Software for Hotels?</span></li>
            <li><span (click)="viewKeyFeatures()" [class.active]="activeSection === 'Key-Features'">Key Features of
                    our Hotel CRM Software </span></li>
            <li><span (click)="viewHotelsBenefits()" [class.active]="activeSection === 'Hotels-Benefits'">How CRM
                    Benefits Hotels of All Sizes</span></li>
            <li><span (click)="viewHowToGetStarted()" [class.active]="activeSection === 'How-To-Get-Started'">How to Get
                    Started with GoFriday Hotel <br> CRM Software</span></li>
            <li><span (click)="viewConclusion()" [class.active]="activeSection === 'Conclusion'">Conclusion</span></li>
        </ul>
        <div fxLayout="column" class="sticky-content" fxLayoutAlign=" center">
            <div>
                <img src="assets/images/Gofryday-logo.png">
            </div>
            <div>
                <p class="sticky-text">Delight your guests <br> with AI-powered <br> experiences</p>
            </div>
            <div>
                <button class="demo-btn" (click)="openCalendlyPopup()">
                    Book a Demo
                </button>
            </div>
        </div>
    </div>
    <div class="right-side-content" fxLayout="column">
        <div fxLayout="column" class="info-content-revolution" fxLayoutGap="2%" fxFlex="65%">
            <div class="info-text">In today’s ultra-competitive hospitality industry, delivering exceptional guest
                experiences isn’t just a bonus—it’s essential. With guests increasingly being offered personalised
                services,
                seamless operations become the call of the hour. The best CRM Software for hoteliers is undoubtedly a
                necessity for hoteliers seeking this goal. One such requisite CRM tool is GoFriday. The CRM solution
                from
                GoFriday allows modern hoteliers to manage their businesses with ease, offering a full-service platform
                that
                integrates all guest information, streamlines communication, and provides your delicious dishes best
                suited
                to each guest. Not only that, this guide seeks to show the reasons why GoFriday is the top choice of
                hotels
                that want to reduce operational complexities and improve guest experience as well as income level.
            </div>
        </div>
        <div id="CRM-Software" class="info-content-crm" fxLayout="column" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">What is CRM Software for Hotels?</h2>
            <div class="info-text">What is CRM software? CRM, or Customer Relationship Management, is a software
                designed with the sole purpose of managing all interactions with existing and potential clients. In the
                hotel business, the best CRM software for hotels assists with maintaining and protecting customer
                databases,
                managing booking and reservation systems, and greatly enhancing message communications between a hotel
                facility and its clients. However, the best CRM software for hotels such as GoFryDay does not just serve
                the purpose of storing information. It allows hoteliers to provide personalised services to guests at
                all stages starting from the moment a booking is made to post-stay communications. For example, guests
                can get personalized deals and offers even before their arrival: the type of bed they would like, the
                drinks they would prefer and if they have any particular requirements. This also makes it possible to
                have an exceptional level of guest satisfaction, treating every individual like a VIP. Such offers can
                only work with a powerful CRM system.
                 This centralized system makes sure that no detail is overlooked, whether it is an exceptional
                anniversary trip or a meal plan consideration. This not only results in a better experience for the
                guests, but it also enhances the productivity of the entire organization, as more time and resources
                will be dedicated to establishing tangible and intimate relationships with guests as opposed to a
                ridiculous amount of administrative work.
            </div>
            <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                <img src="assets/images/CRM Software Hotels.png">
            </div>
            <div *ngIf="mobile" style="display: flex;justify-content: center;">
                <img src="assets/mobile-images/CRM Software Hotels.png">
            </div>
        </div>
        <div id="Why-Choose-CRM-Software" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Why Choose our CRM Software for Hotels?</h2>
            <div class="info-text">In a world where the options available are numerous, picking the best CRM software
                for hotels can become a hassle – but here’s why GoFriday is different from other service providers
            </div>
            <div class="demosection">
                <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                    <div class="demo-text">
                        Delight Your Guests With AI-Powered Experiences
                    </div>
                    <div>
                        <button class="book-btn" (click)="openCalendlyPopup()">
                            Book a Demo
                        </button>
                    </div>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Enhance Guest Experience with Personalization
                </h3>
                <div class="info-text">Today’s travelers expect a personalized experience. GoFriday’s CRM helps you go
                    beyond the basics by creating detailed guest profiles that track everything from stay history to
                    specific preferences. This data allows staff to prepare in advance and cater to the requirements of
                    the guests such as providing a quiet room for a returning guest who values tranquillity or offering
                    their favourite drink upon arrival.  Every detail matters to the GoFriday, and each of your guests
                    is made to feel as if they are being attended to by in a personalized manner.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Streamlined Communication Across All Channels
                </h3>
                <div class="info-text">It can be hard to keep track of guest communication when there are a multitude of
                    channels active such as emails, SMS, WhatsApp, and so many other 3rd-party platforms all at the same
                    time. GoFriday simplifies everything by merging the various communication channels into one. As a
                    result, every message that a hotel guest sends out or calls for, gets addressed without unnecessary
                    delays. If it is confirming the booking made via your hotel’s website, sending out earlier emails or
                    requesting customer reviews, one is guaranteed that the response will always be polished and
                    courteous while attending to the queries will not take ages.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Automate Marketing Campaigns for Better Reach
                </h3>
                <div class="info-text">Marketing always comes off as an overwhelming task, almost a full time job. But,
                    with GoFriday, you can configure and implement automated can personalized marketing campaigns that
                    run in the background. Focus on specific guests according to their actions, interests, or previous
                    durations of stay – without needing to lift a finger. Whether it’s offering loyal guests special
                    deals or sending promotions during off-peak seasons, GoFriday’s CRM helps assure all your marketing
                    strategies remain on target and go to the most appropriate market.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Efficient Booking and Reservation Management
                </h3>
                <div class="info-text">A concern that must be dealt with in guest satisfaction is the booking procedure.
                    To facilitate the process, GoFriday's Customer Relationship Management System connects with the
                    reservation system to help manage bookings, changes, and even cancellations. This guarantees no
                    booking mistakes happen, double bookings are ruled out, and special requests are not missed. Your
                    guests will find it easy to check in early or get a room upgrade at the last minute
                </div>
            </div>
        </div>
        <div id="Key-Features" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Key Features of our Hotel CRM Software</h2>
            <div class="experience-content" fxLayoutAlign=" center">
                <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                    <div class="container-boxsub-head">GoFriday is not what you call an ordinary CRM. It comes with
                        multiple solutions that are focused on ensuring your hotel succeeds
                    </div>
                    <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                        <h3 class="container-sub-head-text">1. Centralized Guest Data and Profiles
                        </h3>
                        <div class="info-text">GoFriday enables you to store and access all your guests’ information in
                            one place. Their contact information, as well as their booking preferences and booking
                            history, are all easily available. This makes it simple to personalize interactions and
                            ensures no detail is forgotten.
                        </div>
                    </div>
                    <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                        <h3 class="container-sub-head-text">2. Email, SMS, and WhatsApp Campaign Automation
                        </h3>
                        <div class="info-text">There are many ways to communicate with guests and all of them have
                            different communication channels – email, SMS, WhatsApp – all of these can be automated.
                            Automatically initiate campaigns with instructions for arrival, offers for mid stays, or
                            thanks after they leave. It completes the journeys of each guest in your hotel, which is an
                            enhancement
                        </div>
                    </div>
                    <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                        <h3 class="container-sub-head-text">3. Loyalty Program Integration for Repeat Guests
                        </h3>
                        <div class="info-text">Integrate your hotel’s loyalty program into the CRM to increase guest
                            retention. Guests appreciate being able to check their points, redeem rewards, and earn
                            recognition for their loyalty. It is this integration that promotes long term relationships
                            with your guests
                        </div>
                    </div>
                    <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                        <h3 class="container-sub-head-text">4. Comprehensive Analytics and Reporting
                        </h3>
                        <div class="info-text">Data is only useful if you can understand it. Inbuilt analytics and
                            reporting features are included within GoFriday’s CRM which equip you to understand guest
                            activity, the success of campaigns, and operational efficiency. Through this data, you are
                            in a good position to make decisions which are productive to your business
                        </div>
                    </div>
                    <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                        <h3 class="container-sub-head-text">5. Seamless Integration with Property Management Systems
                            (PMS)
                        </h3>
                        <div class="info-text">No need to toss your entire system out. GoFriday works smoothly alongside
                            property management (PMS) systems that you already have in place. This enables all your data
                            to be transferred without a hitch and gives your department a single interface
                        </div>
                    </div>
                    <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                        <h3 class="container-sub-head-text">6. 24/7 Support and Onboarding Assistance
                        </h3>
                        <div class="info-text">Any change of customer relationship systems is always hectic, but
                            GoFriday works to make it a simple process. With 24/7 support and and personalized
                            onboarding making sure your team is fully trained and ready to use the system effectively
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="Hotels-Benefits" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">How CRM Benefits Hotels of All Sizes</h2>
            <div class="info-text">Regardless of your hotel’s size, there’s always room for improvement in operations or
                customer outreach and engagement. From small boutique hotels to large chains and even luxury resorts,
                GoFriday’s best CRM software for hotels will help all types of properties get considerable mileage.
            </div>
            <div class="demosection">
                <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                    <div class="demo-text">
                        Delight Your Guests With AI-Powered Experiences
                    </div>
                    <div>
                        <button class="book-btn" (click)="openCalendlyPopup()">
                            Book a Demo
                        </button>
                    </div>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">For Small Hotels: Simplifying Guest Management
                </h3>
                <div class="info-text">Owning a small hotel usually makes it a necessity for you to juggle several
                    functions including bookings, day to day works, and making sure each guest is adequately serviced.
                    With GoFryDay's best CRM software for hotels, you can ease the process of guest management using
                    automation. Instead of having to look through the history files of a guest, tracking down their
                    special requests, or searching the database for their other bookings, GoFryDay’s CRM takes that
                    burden away from you. This system helps you send out guest communications such as confirmations of
                    bookings, details of check-ins, and follow up after their departure, thus giving you the opportunity
                    to concentrate on the guests. Lastly, by bringing all information regarding the guests together, it
                    becomes easier to understand their needs and provide more services to them, making their stay more
                    comfortable. In the end, this translates to more satisfied guests, positive reviews and increased
                    repeat business with a reduction in the amount of work required.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">For Chain Hotels: Managing Multiple Properties with Ease
                </h3>
                <div class="info-text">Staying organized and Keeping up with the records of different properties for
                    hotel chains is the most challenging part. It can be daunting to manage guest data from different
                    locations. This is where GoFriday’s CRM software comes in handy. It ensures that guests’ profiles
                    and their information are stored in one place for easy access at all the properties that you
                    operate. GoFriday allows guests’ data to be updated from any location, which means that even when a
                    guest utilizes a different hotel in the same chain, their favorites, loyalty program, and even their
                    previously made bookings can be called up almost instantly. And while a customer is in New York or
                    Tokyo, they still can be assured of universal service standards.
                    Also, with the help of GoFriday’s CRM, it is possible to conduct marketing campaigns spanning many
                    hotels and properties, which lets you deliver an optimal offer to customers, promising all sorts of
                    bonuses and promotions, regardless of which of your hotels they last visited. This helps your
                    business deliver uniformity across service standards, thereby promoting the company’s brand at every
                    one of your hotels.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">For Luxury Resorts: Delivering a Personalized Guest Experience
                </h3>
                <div class="info-text">Guests who choose luxury resorts expect a level of service that goes above and
                    beyond. With Gofriday’s CRM, you have everything that is needed in order to meet that expectation.
                    The application serves to give customized services to guests at all levels, ensuring that each
                    guest’s experience is one of a kind. What would it be like to know that one of your guests has a
                    preferred type of pillow or likes to sip on the same cocktail every time they are lounging by the
                    pool? GoFryDay CRM stores and retrieves this information, enabling your staff to service individual
                    requirements, thus making it more luxurious. Furthermore, with the assistance of the CRM, which is
                    integrated with the concierge and booking services, every guest can receive automatic personalized
                    offers, for example, a special offer at the spa or an exclusive dining offer. It can be very
                    beneficial for resorts which work with premium products, this personalization sets the resorts apart
                    from the competitors and ensures lovely experiences for the guests making them return and leave
                    amazing feedbacks.
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/Luxury Resorts.png">
                </div>
                <div *ngIf="mobile" style="display: flex;justify-content: center;">
                    <img src="assets/mobile-images/Luxury Resorts.png">
                </div>
            </div>
        </div>

        <div id="How-To-Get-Started" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">How to Get Started with GoFriday Hotel CRM Software</h2>
            <div class="info-text">Getting started with GoFriday’s CRM software is simple. Here’s how:
            </div>
            <div class="guest-experience" fxLayout="column">
                <h3 class="container-sub-head">Request a Free Demo of CRM Software
                </h3>
                <div class="info-text">Want to see how GoFriday’s CRM can transform your hotel operations? Request a
                    free demo to explore the features firsthand.
                </div>
            </div>
            <div class="guest-experience" fxLayout="column">
                <h3 class="container-sub-head">Understanding Pricing and Packages
                </h3>
                <div class="info-text">We offer flexible pricing models that fit hotels of all sizes. Contact us to
                    understand which package best suits your property’s needs.
                </div>
            </div>
            <div class="guest-experience" fxLayout="column">
                <h3 class="container-sub-head">Quick Onboarding Process
                </h3>
                <div class="info-text">Our user-friendly onboarding ensures that your team is up and running with
                    minimal downtime. We provide training and 24/7 support to help you get the most out of the CRM.
                </div>
            </div>
            <div class="guest-experience" fxLayout="column">
                <h3 class="container-sub-head">Customizing the CRM to Fit Your Hotel's Needs
                </h3>
                <div class="info-text">Our CRM solution is highly customizable. Whether you need specific integrations
                    or unique features, we can tailor the system to match your hotel’s requirements.
                </div>
            </div>
        </div>

        <div id="Conclusion" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Conclusion</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div *ngIf="!mobile">
                    <img src="assets/images/conclusion-img.png">
                </div>
                <div *ngIf="mobile" style="place-content: center;display: flex;">
                    <img src="assets/mobile-images/conclusion-img.png">
                </div>
                <div class="info-text">Using the best CRM software for hotels is essential for delivering outstanding
                    guest experiences and improving operational efficiency. With GoFriday’s CRM solution, you’ll gain a
                    powerful tool to centralize guest data, automate communication, and personalize every stay, no
                    matter the size of your hotel. Ready to elevate your hotel’s guest experience? Request a free demo
                    today or contact our team for a personalized consultation.
                </div>
                <div fxLayout="row" fxLayoutGap="3%" class="social-media">
                    <div>
                        <img src="assets/images/whatsapp.png">
                    </div>
                    <div>
                        <img src="assets/images/linkedin.png">
                    </div>
                    <div>
                        <img src="assets/images/email.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div #faqSection class="similar-article width100">
    <div style="padding: 0% 10%;">
        <h2 class="container-head">Similar articles</h2>
    </div>
    <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="card" *ngFor="let article of articles" (click)="article.onClick()">
            <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div class="card-image">
                    <img [src]="article.image" alt="{{ article.title }}">
                </div>
                <div class="card-content">
                    <div class="card-header">{{ article.title }}</div>
                    <div class="card-text">{{ article.text }}</div>
                </div>
                <div style="margin: 0 5% 5% 5%;">
                    <span class="readmore-text">Read More</span>&nbsp;&nbsp;
                    <span class="card-text">({{ article.readTime }})</span>
                </div>
            </div>
        </mat-card>
    </div>
</div>

<div class="faq-container" id="faq-section">
    <h2 class="faq-header">Frequently Asked Questions</h2>
    <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
            {{ faq.question }}
            <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
            <p [innerHTML]="faq.answer"></p>
        </div>
    </div>
</div>
<div class="learn-more-contents" fxLayout="column" fxLayoutGap="2%">
    <div class="learn-more-head">
        Not sure how to get started?
    </div>
    <div class="learn-text">
        Join thousands of hoteliers from around the world already leveraging the power of AI, with GoFriday.
    </div>
    <div>
        <button class="book-btn" (click)="openCalendlyPopup()">
            Book a Demo
        </button>
    </div>
</div>
<div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div>
            <img src="assets/images/contact-us.png">
        </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
        <div class="form-heading">
            Contact Us
        </div>
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                            placeholder="Email" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
        Contact Us
    </div>
    <div>
        <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
        <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                            autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<app-footer></app-footer>