<app-header></app-header>
<div fxLayout="column" class="blogContainer width100">
    <div fxLayout="row">
        <div class="back-arrow" (click)="backButton()">
            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
        </div>
        <h1 class="blog-header">
            9 Core Features of a CRM System for Hotels
        </h1>
    </div>
    <mat-divider class="material-devider"></mat-divider>
    <div fxLayout="row" class="sub-content" fxLayoutAlign="space-evenly">
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">Janhavi Srivastava</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">15 Aug 2024</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <iconify-icon icon="material-symbols-light:share-outline" class="share-icon"></iconify-icon>
            <a class="blog-desc" style="cursor: pointer;" (click)="viewLinkedIn()">LinkedIn</a>
        </div>
    </div>
</div>
<div class="infoContainer width100" fxLayout="column" fxLayoutAlign=" center">
    <div class="Hicsa-prgm-container" *ngIf="!mobile">
        <img src="assets/images/Features of CRM.jpg" class="prgm-image">
    </div>
    <div class="Hicsa-prgm-container" *ngIf="mobile">
        <img src="assets/mobile-images/Features of CRM.png">
    </div>
    <div class="sticky-overview" fxFlex="35" [ngClass]="{ 'sticky-overview-active': showStickyOverview }">
        <div class="overview-header">Overview</div>
        <ul class="overview-list">
            <li><span (click)="viewHotelCRM()" [class.active]="activeSection === 'Hotel-CRM'">What Is A Hotel
                    CRM?</span></li>
            <li><span (click)="viewHotelCrmWork()" [class.active]="activeSection === 'Why-CRM-Essential'">Why CRM is
                    Essential for Modern Hotels</span></li>
            <li><span (click)="viewHotelCrmFeatures()" [class.active]="activeSection === 'Hotel-CRM-Features'">9 Core
                    Features of a CRM System</span></li>
            <li><span (click)="viewHotelCrmBenefits()"
                    [class.active]="activeSection === 'Hotel-CRM-Importance'">Importance of CRM in the Hospitality
                    <br> Industry</span></li>
            <li><span (click)="viewHotelCrmChoose()" [class.active]="activeSection === 'How-To-Choose-CRM'">How To
                    Choose a CRM System?</span>
            </li>
            <li><span (click)="viewConclusion()" [class.active]="activeSection === 'Conclusion'">Conclusion</span></li>
        </ul>
        <div fxLayout="column" class="sticky-content" fxLayoutAlign=" center">
            <div>
                <img src="assets/images/Gofryday-logo.png">
            </div>
            <div>
                <p class="sticky-text">Delight your guests <br> with AI-powered <br> experiences</p>
            </div>
            <div>
                <button class="demo-btn" (click)="openCalendlyPopup()">
                    Book a Demo
                </button>
            </div>
        </div>
    </div>
    <div class="right-side-content" fxLayout="column">
        <div fxLayout="column" class="info-content-revolution" fxLayoutGap="2%" fxFlex="65%">
            <div class="info-text">The modern hotel industry is driven by data: customer data! In the competitive world
                of travel, hotels can no longer afford to make decisions purely based on instincts. Organizations need
                to understand guest preferences. Hoteliers today need to know their guests more than ever to deliver a
                high level of personalized service.<br>
                As a result, customer relationship management (CRM) is now a fast-growing area of focus for the hotel
                industry. Successfully implemented CRM within a hotel operation can increase guest repeats, referrals,
                and the lifetime value to the hotel. The goal of business growth is pursued via improving sales and
                marketing effectiveness, streamlining customer services, managing customer lifecycle effectively, and
                carefully listening to customers' needs and wants so that the resulting offerings can separate and
                distinguish the hotel from its competitors to create extra worth.
            </div>
        </div>
        <div id="Hotel-CRM" class="info-content-crm" fxLayout="column" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">What Is A Hotel CRM?</h2>
            <div class="info-text">CRM (Customer Relationship Management) software is a tool designed to help businesses
                manage their interactions with customers and potential customers. In the context of hotels, this
                involves tracking guest information, managing bookings, handling inquiries, and providing excellent
                customer service. One of the essential features of a CRM system is its ability to centralize and
                streamline this information, enhancing overall efficiency.
            </div>
        </div>
        <div id="Why-CRM-Essential" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Why CRM is Essential for Modern Hotels</h2>
            <div class="info-text">CRM software is also used as one of the major tools for establishments such as modern
                hotels. This is because by identifying the individual preferences of the guests, CRM makes it easier for
                hotels to customize services to suit the guests’ needs, and therefore can be said to increase customer
                satisfaction.
                Features of a CRM system such as scheduling of bookings and how the participants conduct their
                communications influence the simplicity of the task thus improving its efficiency. Besides, the CRM
                equips the hotels with information on marketing prospects, segment markets, and evaluates marketing
                campaigns. Through real-time data and analytics, several decisions such as price fixing, promotional
                activities, as well as services to be proffered can be made effectively.
                Lastly, CRM creates good customer relations and customer loyalty through customers being offered the
                best services and experiences.
            </div>
        </div>
        <div id="Hotel-CRM-Features" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">9 Core Features of a CRM System</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Contact Management
                </h3>
                <div class="info-text">Contact management is the backbone of any CRM system, streamlining customer
                    information such as contact details, communication history, preferences, and purchase records.
                    By centralizing this data, businesses gain a holistic view of each customer, allowing for
                    personalized interactions, improved service quality, and more effective relationship management.
                    This centralized approach also reduces errors and ensures all departments access the most accurate
                    and current information, driving higher customer satisfaction and boosting revenue potential.
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/contact-management.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/contact-management.png">
                </div>
                <div class="demosection">
                    <div class="book-contents" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Delight your guests with AI-powered experiences
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Sales Automation
                </h3>
                <div class="info-text">Sales automation in a CRM streamlines and accelerates the sales process by
                    automating tasks like lead scoring, email campaigns, and follow-up reminders. This core feature
                    enables sales teams to concentrate on relationship-building and closing deals, while the CRM
                    efficiently tracks activities, forecasts sales, and manages pipelines. The result? Increased
                    productivity and improved sales performance.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Customer Support & Service
                </h3>
                <div class="info-text">Customer support features of a CRM system centralize interactions, complaints,
                    and resolutions, enabling support teams to respond quickly and effectively. This streamlined
                    approach not only resolves issues promptly but also enhances the customer experience, building trust
                    and fostering long-term loyalty.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Lead Management
                </h3>
                <div class="info-text">Tracking potential customers from first contact to conversion, CRM’s Lead
                    Management systems capture, score, and nurture leads with personalized follow-ups. This strategic
                    approach ensures sales teams target the most promising opportunities, driving higher conversion
                    rates and building long-term customer loyalty.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">5. Marketing Automation
                </h3>
                <div class="info-text">With CRM-driven marketing automation, hotels can craft personalized marketing
                    campaigns based on customer data and behavior. Automated emails, social media posts, and targeted
                    promotions reach specific segments, boosting engagement, conversion rates, and overall marketing
                    ROI.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">6. Analytics & Reporting
                </h3>
                <div class="info-text">By analyzing customer behavior, sales, and marketing performance, CRM systems
                    provide crucial Analytics & Reports that help businesses identify trends, optimize strategies, and
                    make informed decisions that enhance customer experiences and fuel business growth.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">7. Workflow Automation
                </h3>
                <div class="info-text">By automating routine tasks like data entry, assignments, and approvals,
                    businesses can streamline workflows, reduce manual effort, and minimize errors. Workflow automation
                    enhances productivity, allowing teams to focus on strategic activities that drive growth.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">8. Mobile Accessibility
                </h3>
                <div class="info-text">Mobile CRM empowers teams to access customer data, manage tasks, and communicate
                    with clients directly from their smartphones or tablets. This on-the-go flexibility enhances
                    responsiveness and collaboration, ensuring that critical customer information is always available
                    for timely actions and better interactions, no matter where team members are.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">9. Integration with Other Tools
                </h3>
                <div class="info-text">Integration with other tools and third-party applications is a key feature of
                    modern hospitality CRM systems. By connecting with email marketing platforms, accounting software,
                    and customer support tools, CRMs provide a unified view of customer data across various systems.
                    This seamless integration improves overall efficiency, enhances data accuracy, and enables a more
                    cohesive approach to managing customer relationships and business operations.
                </div>
                <div class="demosection">
                    <div class="book-contents1" fxLayout="column" fxLayoutGap="3%">
                        <div class="demo-text">
                            Learn How GoFriday Helps Hotels Deliver Better Service In Real-Time.
                        </div>
                        <div>
                            <button class="book-btn" (click)="openCalendlyPopup()">
                                Book a Demo
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="Hotel-CRM-Importance" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Importance of CRM in the Hospitality Industry</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">1. Enhancing Guest Experience
                </h3>
                <div class="info-text">Guest satisfaction is the top priority in the hospitality sector. With a good
                    CRM, hotels can personalize every interaction of their customers from booking to feedback after
                    their stay. By keeping track of the guests’ requests, previous activity, and preferences, hotels can
                    create a special service that satisfies their clients, turning them into regular guests.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">2. Improving Customer Retention
                </h3>
                <div class="info-text">Customer retention is often less expensive than getting new clients, and CRM is
                    quite effective when it comes to this. By keeping a continuous record of everything a guest says,
                    every interaction or favour they have availed from them, hotels are able to deal with non complaints
                    and provide for needs before they arise hence making the guests as well as all hotel staff feel very
                    important. This personalized service not only enhances satisfaction but also promotes the
                    guest-hotel relationship which increases retention of the guests.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">3. Streamlining Operations
                </h3>
                <div class="info-text">Time is of the essence as everyone is busy in the hospitality industry. A CRM
                    system eliminates departmentalization where all the information related to the guest is maintained
                    in any of the departments i.e. the front office to the housekeeping departments. This makes the work
                    more efficient, minimizing the chances of operational blunders. Manual work in the form of sending
                    welcome emails before the guests arrive or after they check out when the members are not busy
                    offering their services eliminates routine tasks, thus saving the employees’ working hours and
                    improving the experience of the guests.
                </div>
                <div *ngIf="!mobile" style="display: flex;justify-content: center;">
                    <img src="assets/images/Streamlining Operations.png">
                </div>
                <div *ngIf="mobile">
                    <img src="assets/mobile-images/Streamlining Operations.png">
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">4. Data-Driven Decision Making
                </h3>
                <div class="info-text">Anyone looking for success in the hospitality management field will tell you that
                    it is crucial to make informed decisions. A CRM system offers insight into the guest's behaviour,
                    preferences, and tendencies…. With the analysis of this information, hotels can make informed
                    decisions on everything that ranges from pricing strategies to advertising initiatives. This
                    approach allows for actions to be made in accordance to guests and the market thereby generating
                    better returns on investment.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">5. Enhancing Marketing Strategies
                </h3>
                <div class="info-text">Great marketing is achieving the right message to the right audience at the right
                    moment. With the use of CRM systems, hotels are enabled to profile their guests and choose the most
                    effective marketing strategy for each group. It could be pushing special deals to existing customers
                    or sending them discounts on their birthdays, CRM works wonders in marketing efforts ensuring that
                    more people are able to lock in their business and revenues grow over time.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">6. Building Stronger Guest Relationships
                </h3>
                <div class="info-text">However, the main sustenance of the hospitality industry is the existence of a
                    hotel and its guests. Customer databases are used for marketing, and a CRM system enables each
                    employee to track all activities of the customer’s journey from inquiry through to post-stay. By
                    appreciating and managing every touch point with a guest, hotels can improve that interaction during
                    their next stay; hence encouraging repeat visitors to become loyal customers. Such relationships
                    would increase customer satisfaction, retention, and last but one cooperative gains, etc.
                </div>
            </div>
        </div>
        <div id="How-To-Choose-CRM" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">How To Choose a CRM System?</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">Business Size and Needs
                </h3>
                <div class="info-text">When selecting a CRM system, it’s crucial to consider the size and specific needs
                    of your business. A small boutique hotel may require a simpler, more affordable solution with basic
                    features like guest data management and reservation tracking. On the other hand, a large hotel chain
                    might need an advanced system capable of handling complex operations, integrating with multiple
                    platforms, and offering sophisticated data analytics. Understanding your business’s unique
                    requirements will help you choose a CRM that aligns with your goals and scales with your growth.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">Budget and ROI
                </h3>
                <div class="info-text">Investing in a CRM system should be viewed through the lens of both cost and
                    potential return on investment (ROI). While it might be tempting to go for the most feature-rich
                    option, it’s important to balance your budget against the expected benefits. Consider how the CRM
                    will impact guest satisfaction, operational efficiency, and revenue generation. Look for solutions
                    that offer the best value—those that fit within your budget while providing the tools necessary to
                    boost your bottom line. Remember, a well-chosen CRM can quickly pay for itself through increased
                    customer retention and enhanced marketing effectiveness.
                </div>
            </div>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <h3 class="container-sub-head">Comparison of Leading CRM Solutions
                </h3>
                <div class="info-text">With countless CRM systems on the market, it’s essential to compare leading
                    solutions
                    to find the one that best meets your needs. Start by evaluating popular options like Salesforce,
                    HubSpot, and Zoho CRM, each known for its robust features and industry adaptability. Compare factors
                    such as ease of use, customization options, integration capabilities, and customer support. Look for
                    user reviews and case studies within the hospitality industry to see how each system performs in
                    real-world scenarios. By carefully comparing your options, you can make an informed decision that
                    will
                    serve your business well in the long term.
                </div>
                <div *ngIf="!mobile">
                    <img src="assets/images/conclusion-img.png">
                </div>
                <div *ngIf="mobile" style="place-content: center;display: flex;">
                    <img src="assets/mobile-images/conclusion-img.png">
                </div>
            </div>
        </div>

        <div id="Conclusion" fxLayout="column" class="info-content1" fxLayoutGap="2%" fxFlex="65%">
            <h2 class="container-head">Conclusion</h2>
            <div class="guest-experience" fxLayoutGap="2%" fxLayout="column">
                <div class="info-text">In the fast-evolving hospitality industry, where guest satisfaction and
                    operational efficiency are paramount, a well-implemented CRM system is no longer just an option—it's
                    a necessity. From enhancing guest experiences to streamlining operations, the right CRM solution
                    empowers hotels to make data-driven decisions, improve customer retention, and craft personalized
                    marketing strategies that resonate with guests. By carefully considering your business size, budget,
                    and specific needs, and comparing leading CRM solutions, you can select a system that not only meets
                    your current demands but also scales with your growth. Investing in a CRM system is an investment in
                    your hotel's future, enabling you to build stronger guest relationships, increase loyalty, and
                    ultimately drive long-term success in a competitive market.
                </div>
                <div fxLayout="row" fxLayoutGap="3%" class="social-media">
                    <div>
                        <img src="assets/images/whatsapp.png">
                    </div>
                    <div>
                        <img src="assets/images/linkedin.png">
                    </div>
                    <div>
                        <img src="assets/images/email.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div #faqSection class="similar-article width100">
    <div style="padding: 0% 10%;">
        <h2 class="container-head">Similar articles</h2>
    </div>
    <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
        <mat-card class="card" *ngFor="let article of articles" (click)="article.onClick()">
            <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                <div class="card-image">
                    <img [src]="article.image" alt="{{ article.title }}">
                </div>
                <div class="card-content">
                    <div class="card-header">{{ article.title }}</div>
                    <div class="card-text">{{ article.text }}</div>
                </div>
                <div style="margin: 0 5% 5% 5%;">
                    <span class="readmore-text">Read More</span>&nbsp;&nbsp;
                    <span class="card-text">({{ article.readTime }})</span>
                </div>
            </div>
        </mat-card>
    </div>
</div>


<div class="faq-container" id="faq-section">
    <h2 class="faq-header">Frequently Asked Questions</h2>
    <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
            {{ faq.question }}
            <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
            <p [innerHTML]="faq.answer"></p>
        </div>
    </div>
</div>
<div class="learn-more-contents" fxLayout="column" fxLayoutGap="2%">
    <div class="learn-more-head">
        Not sure how to get started?
    </div>
    <div class="learn-text">
        Join thousands of hoteliers from around the world already leveraging the power of AI, with GoFriday.
    </div>
    <div>
        <button class="book-btn" (click)="openCalendlyPopup()">
            Book a Demo
        </button>
    </div>
</div>
<div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div>
            <img src="assets/images/contact-us.png">
        </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
        <div class="form-heading">
            Contact Us
        </div>
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                            placeholder="Email" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
        Contact Us
    </div>
    <div>
        <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
        <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                            autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div>
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button
                [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
    </div>
</div>
<app-footer></app-footer>