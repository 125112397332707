<app-header></app-header>
<div class="container">
    <div class="guest-app-container width100" fxLayout="row">
        <div class="guest-app-child" fxLayout="column" fxLayoutGap="3%">
            <div fxLayout="row">
                <button class="online-button">
                    <ul class="custom-list">
                        <li>E-Concierge Management
                        </li>
                    </ul>
                </button>
            </div>
            <h2 class="guest-app-child-heading">Gofriday’s Iceberg – E-Concierge Management
            </h2>
            <div *ngIf="mobile" style="align-items: center;">
                <img class="image" src="/assets/images/E-Concierge Management.png" />
            </div>
            <div class="guest-app-child-text">Enhance guest service with Iceberg E-Concierge Management. This advanced
                module enables your staff to deliver seamless, personalized experiences on demand, boosting guest
                satisfaction and operational efficiency.
            </div>
            <div class="buttons">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
                <button class="demo-link" (click)="selectTrial()">
                    Talk to Us
                    <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="image" src="/assets/images/E-Concierge Management.png" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="guest-app-child-heading2">
            24/7 Guest Assistance
        </h2>
        <div class="guest-app-child-text2">
            Offer round-the-clock assistance with Iceberg’s E-Concierge Management, ensuring that your guests can access
            everything they need at any time.
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Task Assignments.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Instant Service Requests
                    </h3>
                    <div class="why-choose-text">Guests can make requests directly through their mobile device or
                        in-room tablet.
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Multilingual Guest Support.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Multilingual Support
                    </h3>
                    <div class="why-choose-text">Cater to a global audience with language options that make
                        communication effortless.</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Multi-Channel Integration.png" alt="" />
                    <h3 class="why-choose-content-head">
                        Customizable Service Options
                    </h3>
                    <div class="why-choose-text">Align services to your property’s unique offerings and individual guest
                        preferences
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="background: #F9F9F9;width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Benefit-service.png">
                    </div>
                    <div fxLayout="column" fxFlex="35%" fxLayoutGap="3%">
                        <h2 class="features-heading">
                            Benefit
                        </h2>
                        <div class="features-sub-text">
                            Provide guests with the convenience of 24/7 service, improve guest satisfaction, and
                            increase the efficiency of your staff.
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <h2 class="features-heading">
                        Benefit
                    </h2>
                    <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Benefit-service.png">
                        </div>
                        <div class="features-sub-text" style="margin-top: 20%;">
                            Provide guests with the convenience of 24/7 service, improve guest satisfaction, and
                            increase the efficiency of your staff.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <div class="dynamic-pricing-section">
            <h2 class="guest-app-child-heading2">
                Personalized Guest Experience
            </h2>
            <div class="guest-app-child-text2" style="padding-top: 8px;">
                Iceberg’s E-Concierge module allows you to create a personalized and memorable guest journey. Use guest
                preferences and past interactions to make every stay unique:
            </div>
            <div class="card-container">
                <mat-card *ngFor="let feature of features"
                    [ngClass]="{'feature-card': !feature.imageOnly, 'image-only-card': feature.imageOnly}"
                    (mouseenter)="onHover(feature)" (mouseleave)="onLeave(feature)"
                    [class.hidden-mobile]="feature.imageOnly && mobile">
                    <!-- Image-only card -->
                    <div *ngIf="feature.imageOnly" class="image-only-content">
                        <img [src]="feature.image" alt="Feature Image" />
                    </div>

                    <!-- Regular card with hover effects -->
                    <div *ngIf="!feature.imageOnly" class="card-content">
                        <div class="default-content">
                            <span>{{ feature.title }}</span>
                        </div>

                        <div class="hover-content" [ngClass]="{ 'show-hover-content': feature.isHovered }">
                            <div class="hover-image">
                                <img [src]="feature.image" alt="Feature Icon" />
                            </div>
                            <p>{{ feature.description }}</p>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <h3 class="guest-app-child-heading2">
            Analytics & Reporting for Improved Service
        </h3>
        <div class="guest-app-child-text2" style="padding-top: 8px;">
            Use Iceberg’s analytics tools to understand guest preferences, service trends, and staff performance. Access
            detailed reports that allow you to refine your approach and ensure the highest level of service.
        </div>
        <div class="knowledge-row" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Supplier Management.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Service Metrics
                        </div>
                        <div class="knowledge-card-text">
                            Analyze response times, completion rates, and guest feedback.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/Multi-Channel Integration.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Guest Insights
                        </div>
                        <div class="knowledge-card-text">
                            Learn what services are most requested and adjust offerings accordingly.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img class="card-images" src="assets/images/actionable tasks.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Staff Performance Reports
                        </div>
                        <div class="knowledge-card-text">
                            Evaluate the effectiveness and responsiveness of your team.
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <div class="features-section1 width100" fxLayout="column" *ngIf="!mobile">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="26%" fxLayoutGap="4%">
                    <div class="features-heading">
                        Benefit
                    </div>
                    <div class="features-sub-text">
                        Drive continuous improvement, optimize resource allocation, and tailor services to meet guest
                        expectations.
                    </div>
                </div>
                <div class="features-image-container-right">
                    <img class="features-images2" src="assets/images/benefit-image6.png" alt="Guest App">
                </div>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
            <div fxLayout="column" fxLayoutAlign="space-between center">
                <h2 class="features-heading">
                    Benefit
                </h2>
                <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/benefit-image6.png">
                    </div>
                    <div class="features-sub-text" style="margin-top: 20%;">
                        Drive continuous improvement, optimize resource allocation, and tailor services to meet guest
                        expectations.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #FFFFFF;">
        <h2 class="guest-app-child-heading2">
            Streamlined Service Coordination
        </h2>
        <div class="guest-app-child-text2">
            Coordinate and manage all guest service requests seamlessly with Iceberg’s comprehensive dashboard. Empower
            your team to efficiently track, assign, and fulfill requests, ensuring guest needs are met promptly:
        </div>
        <div class="knowledge-row1" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Centralized Dashboard.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Centralized Dashboard
                        </div>
                        <div class="knowledge-card-text">
                            Monitor all requests in real-time for better coordination and response times.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Task Assignments Image.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Task Assignments
                        </div>
                        <div class="knowledge-card-text">
                            Assign tasks to relevant departments or staff members with clear instructions.
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="knowledge-card1">
                <div class="card-content-div1" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="knowledge-image">
                        <img src="assets/images/Status Updates.png">
                    </div>
                    <div class="knowledge-content">
                        <div class="knowledge-card-header">
                            Status Updates
                        </div>
                        <div class="knowledge-card-text">
                            Keep guests informed on the progress of their requests, ensuring transparency.
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center">
        <div style="width: 100%;">
            <div class="features-section2" fxLayout="column" *ngIf="!mobile">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/images/Benefit-menu-changes5.png">
                    </div>
                    <div fxLayout="column" fxFlex="35%" fxLayoutGap="3%">
                        <h2 class="features-heading">
                            Benefit
                        </h2>
                        <div class="features-sub-text">
                            Increase team productivity, reduce service delays, and provide a smoother guest experience.
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
                <div fxLayout="column" fxLayoutAlign="space-between center">
                    <h2 class="features-heading">
                        Benefit
                    </h2>
                    <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                        <div class="features-image-container-left">
                            <img class="features-images" src="assets/mobile-images/Benefit-menu-changes5.png">
                        </div>
                        <div class="features-sub-text" style="margin-top: 20%;">
                            Increase team productivity, reduce service delays, and provide a smoother guest experience.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="info-container5 width100" fxLayout="column" fxLayoutAlign="center center">
        <h3 class="guest-app-child-heading2">
            Enhanced Guest Interaction
        </h3>
        <div class="guest-app-child-text2">
            Facilitate meaningful interactions that go beyond standard service with Iceberg’s E-Concierge Management.
            Engage with your guests in real-time to create an atmosphere of attentiveness and care.
        </div>
        <div class="benefits-row" fxLayout="row" fxLayoutAlign="center center">
            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/In-App Messaging.png" alt="Streamlined Operations">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">In-App Messaging</div>
                    <div class="knowledge-card-text">
                        Communicate directly with guests through in-app chat and messaging systems.
                    </div>
                </mat-card>
            </div>

            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Interactive Guides.png" alt="Personalized Service">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Interactive Guides</div>
                    <div class="knowledge-card-text">
                        Provide information on hotel amenities, local attractions, and activities.
                    </div>
                </mat-card>
            </div>

            <div class="benefits-container1">
                <div class="benefits-image">
                    <img src="assets/images/Feedback Collection Image.png" alt="Staff Productivity">
                </div>
                <mat-card class="benefits-card">
                    <div class="knowledge-card-header">Feedback Collection</div>
                    <div class="knowledge-card-text">
                        Gather instant feedback post-service to identify areas for improvement.
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="info-container3 width100" fxLayout="column" fxLayoutAlign="center center" style="background: #F9F9F9;">
        <div class="features-section1 width100" fxLayout="column" *ngIf="!mobile">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxFlex="26%" fxLayoutGap="4%">
                    <div class="features-heading">
                        Benefit
                    </div>
                    <div class="features-sub-text">
                        Foster deeper guest engagement, build rapport, and improve overall guest satisfaction.
                    </div>
                </div>
                <div class="features-image-container-right">
                    <img class="features-images2" src="assets/images/benefit-image8.png" alt="Guest App">
                </div>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="mobile">
            <div fxLayout="column" fxLayoutAlign="space-between center">
                <h2 class="features-heading">
                    Benefit
                </h2>
                <div fxLayout="column" style="padding: 12% 5% 7% 5%;">
                    <div class="features-image-container-left">
                        <img class="features-images" src="assets/mobile-images/benefit-image8.png">
                    </div>
                    <div class="features-sub-text" style="margin-top: 20%;">
                        Foster deeper guest engagement, build rapport, and improve overall guest satisfaction.
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div fxLayout="row" class="info-container1 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div fxFlex="48%">
            <div>
                <h2 class="header">Why Iceberg – E-Concierge Management?</h2>
            </div>
            <div class="info-text">
                Iceberg E-Concierge Management is the ideal solution for hotels and resorts looking to elevate guest
                services and enhance operational efficiency. With its robust features and user-friendly interface, your
                property can provide a modern, guest-centric experience that sets you apart.
            </div>
        </div>
        <div class="contact-image" fxFlex="40%">
            <img src="assets/images/E-Concierge Management1.png">
        </div>
    </div>
    <div fxLayout="column" class="info-container1 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div>
                <h2 class="header">Why Iceberg – E-Concierge Management?</h2>
            </div>
            <div class="contact-image" style="text-align:center;">
                <img src="assets/mobile-images/E-Concierge Management1.png">
            </div>
            <div class="info-text">
                Iceberg E-Concierge Management is the ideal solution for hotels and resorts looking to elevate guest
                services and enhance operational efficiency. With its robust features and user-friendly interface, your
                property can provide a modern, guest-centric experience that sets you apart.
            </div>
        </div>
    </div>


    <div fxLayout="row" class="info-container4 width100" fxLayoutAlign="space-between center" *ngIf="!mobile">
        <div class="contact-image" fxFlex="50%">
            <img src="assets/images/service model with Iceberg.png">
        </div>
        <div fxFlex="45%">
            <div class="info-text">
                Upgrade your service model with Iceberg. Deliver timely, personalized assistance and ensure that your
                guests leave with an unforgettable impression.
            </div>
            <div style="margin-top: 3%;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="info-container4 width100" fxLayoutAlign="center center" *ngIf="mobile">
        <div>
            <div class="contact-image" *ngIf="mobile" style="text-align: center;">
                <img src="assets/mobile-images/service model with Iceberg.png">
            </div>
            <div class="info-text">
                Upgrade your service model with Iceberg. Deliver timely, personalized assistance and ensure that your
                guests leave with an unforgettable impression.
            </div>
            <div style="margin-top: 3%;text-align: center;">
                <button class="book-btn" (click)="openCalendlyPopup()">
                    Book A Demo Today
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>